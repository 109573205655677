import { classNames } from "../utilities"
import { Tag } from "@tryghost/content-api"
import Link from "next/link"
import React from "react"

interface Props {
  tags: Tag[]
  selectedTag?: string
}

export function BlogTags({ tags, selectedTag }: Props) {
  return (
    <ul className="flex flex-wrap gap-x-3 gap-y-6">
      <li>
        <Link
          href="/blog"
          className={classNames(
            !selectedTag ? "text-white bg-primary" : "text-gray-500",
            "transition-all text-gray-500 border border-primary px-3 py-2 font-medium text-sm rounded-md hover:bg-primaryHover hover:text-white cursor-pointer"
          )}
        >
          All
        </Link>
      </li>
      {tags.map((tag) => (
        <li key={tag.id}>
          <Link
            href={`/blog/tags/${tag.slug}`}
            className={classNames(
              selectedTag === tag.slug
                ? "text-white bg-primary"
                : "text-gray-500",
              "transition-all border border-primary px-3 py-2 font-medium text-sm rounded-md hover:bg-primaryHover hover:text-white cursor-pointer"
            )}
          >
            {tag.name}
          </Link>
        </li>
      ))}
    </ul>
  )
}
