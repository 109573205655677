/* eslint-disable @next/next/no-html-link-for-pages */
import React from 'react';

import { ArrowRightIcon } from '@heroicons/react/outline';

import { StandingGirlSvg } from './svg';

export function WhyIsInsuranceImportant({
  children
}: {
  children: React.ReactNode;
}) {
  return (
    <div className="bg-gray-50">
      <div className="max-w-7xl mx-auto py-8 lg:py-20 px-4 sm:px-6 lg:pg-8">
        <div className="grid md:grid-cols-2 gap-20 items-center text-center md:text-left">
          <div className="sm:flex justify-center mx-auto hidden">
            <StandingGirlSvg />
          </div>
          <div>
            {children}
            <a href="/quote" className="btn-primary mt-4 inline-flex">
              See if you&lsquo;re covered{' '}
              <ArrowRightIcon className="h-4 w-4 inline" />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}
