import { FadeIn, InsuranceTabs } from './';

interface Props {
  bgColor?: string;
  title?: React.ReactNode;
  description?: React.ReactNode;
}

export function InsuranceSection({
  bgColor = 'bg-white',
  title,
  description
}: Props) {
  return (
    <div className={bgColor}>
      <div className="max-w-7xl mx-auto py-8 lg:py-20 px-4 sm:px-6 lg:pg-8 text-center">
        <FadeIn>
          {title || (
            <h2 className="max-w-4xl mx-auto mt-2 text-5xl font-extrabold text-alt">
              What other kinds of{' '}
              <span className="text-primary">business insurance</span> do I
              need?
            </h2>
          )}
        </FadeIn>
        {description}
        <div className="mt-12">
          <InsuranceTabs />
        </div>
      </div>
    </div>
  );
}
