import React, { useMemo, useState } from 'react';

import { ExternalLinkIcon, SearchIcon } from '@heroicons/react/outline';

import { classNames } from '../utilities';

interface Partner {
  name: string;
  image: string;
  description: string;
  tags: string[];
  url?: string;
}

const tabs = [
  { name: 'All Partners', id: 'all' },
  { name: 'E-Commerce', id: 'e-commerce' },
  { name: 'Freelance', id: 'freelance' },
  { name: 'Small Business', id: 'small-business' }
];

const partners: Partner[] = [
  {
    name: 'Getida',
    image: '/img/getida.png',
    description:
      'GETIDA is a technology company that empowers the ecommerce industry by helping Amazon sellers recover the maximum FBA reimbursements with little hassle.',
    tags: ['all', 'e-commerce'],
    url: 'https://getida.com/'
  },
  {
    name: 'Capforge',
    image: '/img/capforge.svg',
    description:
      'Capforge offers full service professional small business bookkeeping for a broad range of business types. Founded by Matt Remuzzi in 2000, Capforge offers Bookkeeping, taxes and payroll services to e-commerce merchants and start up businesses.',
    tags: ['all', 'e-commerce'],
    url: 'https://capforge.com/'
  },
  {
    name: 'ConnectBooks',
    image: '/img/connectbooks.png',
    description:
      'Coonectbooks offers automated accounting services for e-commerce merchants, allowing sellers to have superior accounting analytics at an affordable rate.',
    tags: ['all', 'e-commerce'],
    url: 'https://www.connectbooks.com/'
  },
  {
    name: 'Ease',
    image: '/img/ease.png',
    description:
      'Itsease provides a concierge approach to remote talent management. Their cloud based solution helps businesses manage their independent contractors and freelance workforce all in one place in addition to providing access to top talent in various field including software engineers, developers, designers, copywriters and digital strategists.',
    tags: ['all', 'freelance'],
    url: 'https://www.itsease.com/'
  },
  {
    name: 'Insured Nomads',
    image: '/img/insured-nomads.svg',
    description:
      'Insured Nomads is deploying the preeminent insurtech and traveltech platform by offering instantaneous health and travel insurance for expats, remote workers and freelancers.',
    tags: ['all', 'freelance'],
    url: 'https://www.insurednomads.com/'
  },
  {
    name: 'Refund Master',
    image: '/img/refundmaster.png',
    description:
      'Refund Master has one simple goal, get businesses the refunds they deserve. The Company specializes in identifying and maximizing merchants’ eligible refunds as well as claiming liabilities from many different companies under many different categories',
    tags: ['all', 'e-commerce'],
    url: 'https://www.refundmaster.com/'
  },
  {
    name: 'Riverbend Consulting',
    image: '/img/riverbend.png',
    description:
      'Riverbend Consulting offers a broad range of services helping their customers with account support, ASIN appeals reimbursements, virtual assistance and much more. Their seasoned staff are veteran amazon sellers and experienced ecommerce professionals that can advise your business on how to best resolve your amazon related disputes.',
    tags: ['all', 'e-commerce'],
    url: 'https://riverbendconsulting.com/'
  },
  {
    name: 'ShipHero',
    image: '/img/shiphero.png',
    description:
      'Shiphero provides ecommerce fulfillment solutions, making it easy to ship ecommerce products direct-to-consumers through their warehouse management software and full-service fulfillment solution.',
    tags: ['all', 'e-commerce'],
    url: 'https://shiphero.com/'
  },
  {
    name: 'Opulent Supply',
    image: '/img/opulent.svg',
    description:
      'Opulent Supply offers a broad range of services to online businesses and resellers. From item preparation to fulfillment services, Opulent Supply provides customized solutions for all types of e-commerce businesses and online retailers.',
    tags: ['all', 'e-commerce']
  },
  {
    name: 'Sutton Funding',
    image: '/img/sutton.png',
    description:
      'Sutton Funding is a financial resource for small businesses that provides efficent capital to their customers so that they can expand their business, purchase inventory or reconstruct a new facility.',
    tags: ['all', 'small-business'],
    url: 'https://www.suttonfunding.com/'
  }
];

export function PartnersTab() {
  const [selectedTab, setSelectedTab] = useState('all');
  const [query, setQuery] = useState('');

  const filteredPartners: Partner[] = useMemo(
    () =>
      partners
        .filter(partner => partner.tags.includes(selectedTab))
        .filter(
          partner =>
            partner.name.toLowerCase().includes(query.toLowerCase()) ||
            partner.tags.some(tag => tag.includes(query.toLowerCase()))
        ),

    [selectedTab, query]
  );

  return (
    <div>
      <div className="flex flex-col items-center justify-center lg:justify-between lg:flex-row flex-wrap gap-4">
        <div className="flex-1 w-full max-w-md">
          <label
            htmlFor="search"
            className="block text-sm font-medium text-gray-700 sr-only"
          >
            Search
          </label>
          <div className="mt-1 relative rounded-md shadow-sm">
            <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
              <SearchIcon
                className="h-5 w-5 text-gray-400"
                aria-hidden="true"
              />
            </div>
            <input
              type="search"
              className="focus:ring-primary focus:border-primary block w-full min-w-[250px] pl-10 sm:text-sm border-gray-300 rounded-md"
              placeholder="Search for a partner"
              onChange={event => setQuery(event.target.value)}
            />
          </div>
        </div>
        <nav
          className="flex flex-wrap space-x-2 justify-center"
          aria-label="Tabs"
        >
          {tabs.map(tab => (
            <a
              key={tab.name}
              role="button"
              className={classNames(
                tab.id === selectedTab
                  ? 'bg-primary text-white'
                  : 'text-gray-500 hover:bg-primary hover:text-white transition duration-150 ease-in-out',
                'px-3 py-2 rounded-md'
              )}
              aria-current={tab.id === selectedTab ? 'page' : undefined}
              onClick={() => setSelectedTab(tab.id)}
            >
              {tab.name}
            </a>
          ))}
        </nav>
      </div>
      <div className="mt-6 grid sm:grid-cols-2 gap-4 md:grid-cols-3 lg:mt-8">
        {filteredPartners.length ? (
          filteredPartners.map(partner => (
            <div
              key={partner.name}
              className="p-8 bg-white rounded-lg border border-gray-200"
            >
              <img
                src={partner.image}
                className="mx-auto max-h-16"
                alt={partner.name}
              />
              <p className="mt-4 text-gray-500 text-sm">
                {partner.description}
              </p>
              <div className="mt-2">
                {partner.url && (
                  <a
                    href={partner.url}
                    target="_blank"
                    rel="noreferrer"
                    className="text-primary group hover:text-primaryHover group inline-flex items-center space-x-1"
                  >
                    <span>Visit partner</span>
                    <ExternalLinkIcon className="text-primary group-hover:text-primaryHover h-5 w-4" />
                  </a>
                )}
              </div>
            </div>
          ))
        ) : (
          <p className="col-span-2 md:col-span-3 text-gray-500 text-center text-lg">
            No matching partners
          </p>
        )}
      </div>
    </div>
  );
}
