import { CRUD_SERVICE_URL, classNames } from "../utilities"
import {
  ArrowRightIcon,
  CheckCircleIcon,
  ExclamationCircleIcon,
  XCircleIcon,
} from "@heroicons/react/outline"
import * as Sentry from "@sentry/nextjs"
import { useEffect, useState } from "react"
import { useForm } from "react-hook-form"

interface FormData {
  firstName: string
  lastName: string
  email: string
  company: string
  phone: string
  message: string
}

interface Props {
  page: string
}

export function ContactUs({ page }: Props) {
  const [showSuccess, setShowSuccess] = useState(false)
  const [error, setError] = useState(false)
  const {
    register,
    formState: { errors, isSubmitting },
    handleSubmit,
    reset,
  } = useForm<FormData>()

  const onSubmit = async ({
    firstName,
    lastName,
    email,
    company,
    phone,
    message,
  }: FormData) => {
    try {
      setShowSuccess(false)

      await fetch(
        `${CRUD_SERVICE_URL}/forms/${
          page === "team" ? "submitTeamForm" : "submitPartnerForm"
        }`,
        {
          method: "POST",
          headers: {
            "Content-type": "application/json",
          },
          body: JSON.stringify({
            firstName,
            lastName,
            email,
            company,
            phoneNumber: phone,
            message,
          }),
        }
      )

      setShowSuccess(true)
    } catch (err) {
      const _error = err as Error
      setError(true)
      Sentry.captureException(err)
      console.log(_error)
    }
  }

  useEffect(() => {
    reset()
  }, [showSuccess, reset])

  return (
    <div className="space-y-6">
      {showSuccess && (
        <div className="rounded-md bg-green-50 p-4">
          <div className="flex">
            <div className="flex-shrink-0">
              <CheckCircleIcon
                className="h-5 w-5 text-green-400"
                aria-hidden="true"
              />
            </div>
            <div className="ml-3">
              <p className="text-sm font-medium text-green-800">
                Thank you for contacting us. We will reply to your email as soon
                as possible.
              </p>
            </div>
          </div>
        </div>
      )}

      {error && (
        <div className="rounded-md bg-red-50 p-4">
          <div className="flex">
            <div className="flex-shrink-0">
              <XCircleIcon
                className="h-5 w-5 text-red-400"
                aria-hidden="true"
              />
            </div>
            <div className="ml-3">
              <p className="text-sm font-medium text-red-800">
                There was an error with your submission. Please try again.
              </p>
            </div>
          </div>
        </div>
      )}

      <form
        className="grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-8"
        onSubmit={handleSubmit(onSubmit)}
      >
        <div className="col-span-2 sm:cols-span-1">
          <label
            htmlFor="firstName"
            className="block text-sm font-medium text-gray-700"
          >
            First name
          </label>
          <div className="mt-1 relative rounded-md shadow-sm">
            <input
              id="firstName"
              type="text"
              placeholder="John"
              className={classNames(
                errors.firstName
                  ? "focus:ring-red-500 focus:border-red-500"
                  : "focus:ring-primary focus:border-primary",
                "py-3 px-4 block w-full shadow-sm border-gray-300 rounded-md placeholder-gray-400"
              )}
              {...register("firstName", { required: true })}
            />
            {errors.firstName && (
              <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                <ExclamationCircleIcon
                  className="h-5 w-5 text-red-500"
                  aria-hidden="true"
                />
              </div>
            )}
          </div>
          {errors.firstName && (
            <p className="mt-2 text-sm text-red-600">First name is required</p>
          )}
        </div>
        <div className="col-span-1 sm:col-span-2">
          <label
            htmlFor="lastName"
            className="block text-sm font-medium text-gray-700"
          >
            Last name
          </label>
          <div className="mt-1 relative rounded-md shadow-sm">
            <input
              id="lastName"
              type="text"
              placeholder="Smith"
              className={classNames(
                errors.lastName
                  ? "focus:ring-red-500 focus:border-red-500"
                  : "focus:ring-primary focus:border-primary",
                "py-3 px-4 block w-full shadow-sm border-gray-300 rounded-md placeholder-gray-400"
              )}
              {...register("lastName", { required: true })}
            />
            {errors.lastName && (
              <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                <ExclamationCircleIcon
                  className="h-5 w-5 text-red-500"
                  aria-hidden="true"
                />
              </div>
            )}
          </div>
          {errors.lastName && (
            <p className="mt-2 text-sm text-red-git600">
              Last name is required
            </p>
          )}
        </div>
        {page === "partner" && (
          <div className="col-span-2">
            <label
              htmlFor="company"
              className="block text-sm font-medium text-gray-700"
            >
              Company
            </label>
            <div className="mt-1 relative rounded-md shadow-sm">
              <input
                id="company"
                type="text"
                placeholder="Acme Inc"
                className={classNames(
                  errors.company
                    ? "focus:ring-red-500 focus:border-red-500"
                    : "focus:ring-primary focus:border-primary",
                  "py-3 px-4 block w-full shadow-sm border-gray-300 rounded-md placeholder-gray-400"
                )}
                {...register("company", { required: true })}
              />
              {errors.company && (
                <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                  <ExclamationCircleIcon
                    className="h-5 w-5 text-red-500"
                    aria-hidden="true"
                  />
                </div>
              )}
            </div>
            {errors.company && (
              <p className="mt-2 text-sm text-red-600">Company is required</p>
            )}
          </div>
        )}
        <div className="col-span-2">
          <label
            htmlFor="email"
            className="block text-sm font-medium text-gray-700"
          >
            Email
          </label>
          <div className="mt-1 relative rounded-md shadow-sm">
            <input
              id="email"
              type="email"
              placeholder="you@email.com"
              className={classNames(
                errors.email
                  ? "focus:ring-red-500 focus:border-red-500"
                  : "focus:ring-primary focus:border-primary",
                "py-3 px-4 block w-full shadow-sm border-gray-300 rounded-md placeholder-gray-400"
              )}
              {...register("email", { required: true })}
            />
            {errors.email && (
              <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                <ExclamationCircleIcon
                  className="h-5 w-5 text-red-500"
                  aria-hidden="true"
                />
              </div>
            )}
          </div>
          {errors.email && (
            <p className="mt-2 text-sm text-red-600">Email is required</p>
          )}
        </div>
        <div className="col-span-2">
          <div className="flex justify-between">
            <label
              htmlFor="phone"
              className="block text-sm font-medium text-gray-700"
            >
              Phone
            </label>
            <span className="text-sm text-gray-500">Optional</span>
          </div>
          <div className="mt-1 relative rounded-md shadow-sm">
            <input
              id="phone"
              type="text"
              placeholder="555-987-6543"
              className="placeholder-gray-400 py-3 px-4 block w-full shadow-sm focus:ring-primary focus:border-primary border-gray-300 rounded-md"
              {...register("phone")}
            />
          </div>
        </div>
        <div className="col-span-2">
          <label
            htmlFor="message"
            className="block text-sm font-medium text-gray-700"
          >
            Message
          </label>
          <div className="mt-1">
            <textarea
              id="message"
              rows={4}
              placeholder="Your message"
              className={classNames(
                errors.message
                  ? "focus:ring-red-500 focus:border-red-500"
                  : "focus:ring-primary focus:border-primary",
                "py-3 px-4 block w-full shadow-sm border border-gray-300 rounded-md placeholder-gray-400"
              )}
              {...register("message", { required: true })}
            />
            {errors.message && (
              <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                <ExclamationCircleIcon
                  className="h-5 w-5 text-red-500"
                  aria-hidden="true"
                />
              </div>
            )}
            {errors.message && (
              <p className="mt-2 text-sm text-red-600">Message is required</p>
            )}
          </div>
        </div>
        <div className="sm:col-span-2 space-x-4">
          <button type="submit" className="btn-primary w-full">
            {isSubmitting ? (
              <>
                <span>Submitting</span>
                <svg
                  className="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <circle
                    className="opacity-25"
                    cx="12"
                    cy="12"
                    r="10"
                    stroke="currentColor"
                    strokeWidth="4"
                  ></circle>
                  <path
                    className="opacity-75"
                    fill="currentColor"
                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                  ></path>
                </svg>
              </>
            ) : (
              <>
                <span>Let's talk</span>
                <ArrowRightIcon className="h-4 w-4 inline" />
              </>
            )}
          </button>
        </div>
      </form>
    </div>
  )
}
