import { Dropdown } from "."
import { classNames } from "../utilities"
import React from "react"
import "react-tabs/style/react-tabs.css"

interface ContentItem {
  title: string
  description: string
}

const content: { [key: string]: ContentItem[] } = {
  "Management Liability": [
    {
      title: "Directors & Officers (D&O)",
      description:
        "Protection of personal assets of founders, executives, and board members resulting from lawsuits and legal claims against them.",
    },
    {
      title: "Employment Practices Liability (EPL)",
      description:
        "Protection from allegations of wrongdoing by and between managers and employees (wrongful termination, discrimination, harassment claims).",
    },
    {
      title: "Fiduciary",
      description:
        "Protection against claims related to managing or administering employee benefits plans.",
    },
    {
      title: "Crime",
      description:
        "Protection and coverage for employee theft, forgery and fraud.",
    },
    {
      title: "Cap Table Disputes",
      description:
        "Protection against shareholder lawsuits, such as those related to ownership or equity distributions, M&A, or changes in corporate governance.",
    },
  ],
  "General Liability": [
    {
      title: "Bodily Injury",
      description:
        "Someone other than you (or an employee) is injured and your business is responsible for medical, legal and damages.",
    },
    {
      title: "Third-Party Property Damage",
      description: "Customer property is damaged by you (or an employee).",
    },
    {
      title: "Advertising and Reputational Injury",
      description:
        "Your business is accused of copyright infringement, malicious prosecution, slander, libel, wrongful eviction and violating a person's privacy.",
    },
    {
      title: "Product Liability",
      description:
        "Products developed or sold by your business harm people or property, and your business is responsible for legal and medical expenses.",
    },
  ],
  "Professional Liability": [
    {
      title: "Mistakes or Oversights",
      description:
        "A mistake or oversight causes a client to lose money and your client decides to take legal action.",
    },
    {
      title: "Alleged or Actual Negligence",
      description:
        "Your business is accused of negligence, such as failing to meet industry standards, and your client decides to take legal action.",
    },
    {
      title: "Undelivered Services",
      description:
        "Your business promises to provide a service and fails to deliver, and your client decides to take legal action.",
    },
    {
      title: "Missed Deadlines",
      description:
        "Your business misses a crucial deadline or provides late work, and your client decides to take legal action.",
    },
  ],
  "Business Owner's Policy": [
    {
      title: "Property Damage",
      description:
        "Your business equipment or a customer’s property is damaged.",
    },
    {
      title: "Inventory Replacement",
      description:
        "Your inventory is damaged due to fire, water, or other specified perils.",
    },
    {
      title: "Bodily Injury",
      description:
        "Someone other than you (or an employee) is injured and your business is responsible for medical, legal and damages.",
    },
    {
      title: "Business Income Interruption",
      description:
        "Your business has to close unexpectedly, which leads to lost income and accumulated expenses.",
    },
    {
      title: "Advertising and Reputational Injury",
      description:
        "Your business is accused of copyright infringement, malicious prosecution, slander, libel, wrongful eviction and violating a person's privacy.",
    },
  ],
  "Workers' Compensation": [
    {
      title: "Medical Expenses",
      description:
        "Your employee has a work-related injury or illness, and your business is responsible for medical expenses.",
    },
    {
      title: "Lost Wages",
      description:
        "Your employee suffers a work-related injury, and your business is responsible to cover lost income due to time-off.",
    },
    {
      title: "Survivor Benefits",
      description:
        "An employee death occurs due to a work-related injury, and your business is responsible to cover funeral costs and death benefits.",
    },
    {
      title: "Permanent Injury",
      description:
        "Your employee is temporarily or permanently disabled, and your business is responsible to cover ongoing medical expenses and lost wages.",
    },
    {
      title: "Employers Liability",
      description:
        "Your employee files a lawsuit against your business related to work-related injuries or occupations diseases.",
    },
  ],
  Cyber: [
    {
      title: "Cyber Extortion",
      description:
        "Your business or client receives a network-based ransom demand.",
    },
    {
      title: "Cyber Crime",
      description:
        "Your business or client experiences funds transfer fraud or a phishing outbreak.",
    },
    {
      title: "Business Interruption",
      description:
        "Your business or client experiences virtual perils such as viruses, tech failures, and programming errors which results in lost business income.",
    },
    {
      title: "Data Recovery",
      description:
        "Your business is responsible for the costs to replace, restore, or repair damaged or destroyed data and software.",
    },
    {
      title: "Breach Costs",
      description:
        "Your business is responsible for a data breach and the associated costs.",
    },
  ],
}

const tabs: string[] = [
  "General Liability",
  "Business Owner's Policy",
  "Workers' Compensation",
  "Cyber",
  "Professional Liability",
  "Management Liability",
]

const icons: { [key: string]: string } = {
  "Directors & Officers (D&O)":
    "https://img.icons8.com/ios-glyphs/90/null/business-group.png",
  "Employment Practices Liability (EPL)":
    "https://img.icons8.com/external-edtim-outline-edtim/100/null/external-employees-hr-edtim-outline-edtim-3.png",
  Fiduciary:
    "https://img.icons8.com/external-sbts2018-solid-sbts2018/58/null/external-benefit-basic-ui-elements-2.5-sbts2018-solid-sbts2018.png",
  Crime: "https://img.icons8.com/ios-glyphs/90/null/bank-robbery.png",
  "Cap Table Disputes":
    "https://img.icons8.com/ios-glyphs/90/null/opposite-opinion.png",
  "Bodily Injury":
    // 'https://coverdash.s3.amazonaws.com/icons/icons8-leg-trauma-50.png',
    "https://img.icons8.com/ios-filled/100/null/wheelchair.png",

  "Third-Party Property Damage":
    // 'https://coverdash.s3.amazonaws.com/icons/icons8-property-50.png',
    "https://img.icons8.com/ios-filled/100/null/property.png",

  "Advertising and Reputational Injury":
    // 'https://coverdash.s3.amazonaws.com/icons/icons8-exhibitor-50.png',
    "https://img.icons8.com/ios-filled/100/null/exhibitor.png",

  "Product Liability":
    // 'https://coverdash.s3.amazonaws.com/icons/icons8-product-50.png',
    "https://img.icons8.com/glyph-neue/64/null/product.png",

  "Mistakes or Oversights":
    // 'https://coverdash.s3.amazonaws.com/icons/icons8-error-50.png',
    "https://img.icons8.com/ios-filled/100/null/error.png",

  "Alleged or Actual Negligence":
    // 'https://coverdash.s3.amazonaws.com/icons/icons8-trust-50.png',
    "https://img.icons8.com/ios-filled/100/null/trust.png",

  "Undelivered Services":
    // 'https://coverdash.s3.amazonaws.com/icons/icons8-fail-50.png',
    "https://img.icons8.com/ios-filled/100/null/fail.png",

  "Missed Deadlines":
    // 'https://coverdash.s3.amazonaws.com/icons/icons8-deadline-50.png',
    "https://img.icons8.com/ios-filled/100/null/deadline.png",

  "Property Damage":
    // 'https://coverdash.s3.amazonaws.com/icons/icons8-property-50.png',
    "https://img.icons8.com/ios-filled/100/null/property.png",

  "Inventory Replacement":
    // 'https://coverdash.s3.amazonaws.com/icons/icons8-warehouse-50.png',
    "https://img.icons8.com/glyph-neue/64/null/warehouse-1.png",

  "Business Income Interruption":
    // 'https://coverdash.s3.amazonaws.com/icons/icons8-general-ledger-50.png',
    "https://img.icons8.com/material-sharp/96/null/ledger.png",

  "Medical Expenses":
    // 'https://coverdash.s3.amazonaws.com/icons/icons8-caduceus-50.png',
    "https://img.icons8.com/ios-filled/100/null/caduceus.png",

  "Lost Wages":
    // 'https://coverdash.s3.amazonaws.com/icons/icons8-tax-50.png',
    "https://img.icons8.com/ios-filled/100/null/tax.png",

  "Survivor Benefits":
    // 'https://coverdash.s3.amazonaws.com/icons/icons8-receive-cash-50.png',
    "https://img.icons8.com/ios-filled/100/null/receive-cash.png",

  "Permanent Injury":
    // 'https://coverdash.s3.amazonaws.com/icons/icons8-leg-trauma-50.png',
    "https://img.icons8.com/ios-filled/100/null/wheelchair.png",

  "Employers Liability":
    // 'https://coverdash.s3.amazonaws.com/icons/icons8-employee-50.png',
    "https://img.icons8.com/ios-filled/100/null/user.png",

  "Breach Costs":
    // 'https://coverdash.s3.amazonaws.com/icons/icons8-hacker-50.png',
    "https://img.icons8.com/ios-filled/100/null/hacker.png",

  "Cyber Extortion":
    // 'https://coverdash.s3.amazonaws.com/icons/icons8-cyber-security-50.png',
    "https://img.icons8.com/ios-filled/100/null/cyber-security.png",

  "Cyber Crime":
    // 'https://coverdash.s3.amazonaws.com/icons/icons8-virus-free-50.png',
    "https://img.icons8.com/ios-filled/100/null/virus-free.png",

  "Business Interruption":
    // 'https://coverdash.s3.amazonaws.com/icons/icons8-trigger-50.png',
    "https://img.icons8.com/ios-filled/100/null/trigger-mode.png",

  "Data Recovery":
    // 'https://coverdash.s3.amazonaws.com/icons/icons8-data-recovery-50.png'
    "https://img.icons8.com/ios-filled/100/null/data-recovery.png",
}

//Function Expression
export function InsuranceTabs({ cardBgColor = "bg-gray-50" }) {
  const [currentTab, setCurrentTab] = React.useState("General Liability")
  return (
    <div>
      {/* TABS */}
      <div>
        <div className="lg:hidden">
          <label htmlFor="tabs" className="sr-only">
            Select a tab
          </label>
          <Dropdown options={tabs} onChange={setCurrentTab} />
        </div>
        <div className="hidden lg:block">
          <nav className="flex space-x-4 justify-center" aria-label="Tabs">
            {tabs.map((tab) => (
              <a
                key={tab}
                role="button"
                className={classNames(
                  tab === currentTab
                    ? "bg-primary text-white"
                    : "text-gray-500 hover:bg-primary hover:text-white transition duration-150 ease-in-out",
                  "px-3 py-2 font-medium rounded-md"
                )}
                aria-current={tab === currentTab ? "page" : undefined}
                onClick={() => setCurrentTab(tab)}
              >
                {tab}
              </a>
            ))}
          </nav>
        </div>
      </div>

      {/* CONTENT */}
      <div className="mt-12">
        <div className="grid grid-cols-1 md:grid-cols-2 lg--grid-cols-3 gap-8">
          {content[currentTab].map((item, index) => (
            <div key={index} className="pt-6">
              <div
                className={`flow-root rounded-lg ${cardBgColor} shadow-md px-6 pb-8 h-full`}
              >
                <div className="-mt-6">
                  <div>
                    <span className="inline-flex items-center justify-center rounded-md bg-primary p-2 shadow-lg">
                      {/* eslint-disable-next-line @next/next/no-img-element */}
                      <img
                        src={icons[item.title]}
                        alt="icon"
                        style={{ filter: "invert(100%)" }}
                        className="h-8 w-8 text-white"
                        aria-hidden="true"
                      />
                    </span>
                  </div>
                  <h3 className="mt-8 text-lg font-medium tracking-tight text-gray-900">
                    {item.title}
                  </h3>
                  <p className="mt-5 text-base text-gray-500">
                    {item.description}
                  </p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}
