import { useEffect } from 'react';

import 'prism-theme-night-owl';
import Prism from 'prismjs';

const code = `import CoverdashQuotes from '@coverdash/quotes';

function App() {
  return (
    <CoverdashQuotes license="xxxxxxx-xxx-xxxx-xxxxxxx" />;
  );
}
`;

export function WidgetExample() {
  useEffect(() => {
    Prism.highlightAll();
  }, []);

  return (
    <div className="">
      <div className="flex overflow-hidden bg-slate-100 shadow-xl sm:max-h-[none] pointer-events-none max-w-3xl rounded-xl">
        <div className="relative flex w-full flex-col">
          <div className="flex-none border-b border-slate-200">
            <div className="flex h-8 items-center space-x-1.5 px-3">
              <div className="h-2.5 w-2.5 rounded-full bg-rose-300"></div>
              <div className="h-2.5 w-2.5 rounded-full bg-yellow-300"></div>
              <div className="h-2.5 w-2.5 rounded-full bg-green-300"></div>
            </div>
          </div>
          <div className="relative flex min-h-0 flex-auto flex-col">
            <div className="bg-white text-center p-20">
              <p className="text-xl text-alt font-extrabold">
                Get a quote in just a few quick steps.
              </p>
              <button className="animate-pulse hover:animate-none btn-primary mt-12 mb-8 px-8 py-4 ">
                Get Started
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="Code prose max-w-xl -mt-24 relative">
        <pre className="overflow-auto scrollbar-hide !m-0">
          <code className="language-javascript">{code}</code>
        </pre>
      </div>
    </div>
  );
}
