/* eslint-disable @next/next/no-html-link-for-pages */

/* eslint-disable @next/next/no-img-element */
import EnterEmailDialog from "./EnterEmailDialog"
import { FadeIn } from "./FadeIn"
import PressLogos from "./PressLogos"
import ProductVideo from "./ProductVideo"
import { ArrowRightIcon, ExternalLinkIcon } from "@heroicons/react/outline"
import React from "react"
import TextLoop from "react-text-loop"

// import ReactTextTransition, { presets } from "react-text-transition"

export function Hero() {
  const [openSubscribeDialog, setOpenSubscribeDialog] = React.useState(false)

  const businessTypes = [
    "small businesses.",
    "startups.",
    "ecommerce merchants.",
    "freelancers.",
  ]
  // const [businessTypeIndex, setBusinessTypeIndex] = useState(0)
  // useEffect(() => {
  //   const interval = setInterval(() => {
  //     setBusinessTypeIndex(getRandomInt(0, businessTypes.length - 1))
  //   }, 1000)

  //   return () => {
  //     clearInterval(interval)
  //   }
  // }, [businessTypes.length])
  return (
    <div className="bg-white cdHeading">
      <EnterEmailDialog
        open={openSubscribeDialog}
        setOpen={setOpenSubscribeDialog}
        title="Subscribe to our newsletter"
      />
      <div className="lg:h-screen lg:flex lg:flex-col lg:justify-center lg:max-h-[60rem]">
        <div className="pt-20 xl:pt-24 lg:pt-32 justify-center h-full flex flex-col">
          <div className="pt-6 sm:pt-8 mx-auto max-w-7xl w-full grid grid-cols-1 lg:grid-cols-2 gap-x-20 gap-y-10">
            {/* HERO TEXT & CTA */}
            <div className="flex flex-col justify-center px-8 xl:px-0">
              <div className="mb-8 relative w-fit mx-auto lg:mx-0 shadow text-xs sm:text-sm px-4 py-1.5 rounded-full border border-sky-300 text-sky-900 bg-sky-100 font-medium">
                <a
                  href="https://www.coverdash.com/blog/coverdash-leading-embedded-business-insurance-agency-for-startups-and-smbs-announces-13-5m-in-series-a-funding"
                  target="_blank"
                  rel="noreferrer"
                  className=""
                >
                  Read about our <span className="font-semibold">Series A</span>{" "}
                  funding announcement{" "}
                  <ExternalLinkIcon className="ml-1 w-3 h-3 sm:h-4 sm:w-4 inline -mt-1" />
                </a>
              </div>
              <FadeIn delay={0.3}>
                <h1 className="text-center lg:text-left text-3xl md:text-4xl xl:text-5xl tracking-tight font-bold text-alt mx-auto lg:mx-0 max-w-[18rem] sm:max-w-xs lg:max-w-xs xl:max-w-md">
                  Business insurance in a dash for
                  {/* {" "} */}
                  {/* <span className="text-primary w-12">
              <ReactTextTransition
                springConfig={presets.gentle}
                style={{ margin: "0 4px", display: "block !important" }}
                // inline
                direction="up"
              >
                {businessTypes[businessTypeIndex]}
              </ReactTextTransition>
            </span> */}
                </h1>
                <h1 className="text-center lg:text-left text-3xl md:text-4xl xl:text-5xl sm:mt-1 mb-4 tracking-tight font-bold text-primary">
                  {/* <ReactTextTransition
              springConfig={presets.gentle}
              style={{ margin: "0 4px", display: "block !important" }}
              // inline
              direction="up"
            >
              {businessTypes[businessTypeIndex]}
            </ReactTextTransition> */}
                  <TextLoop>
                    {businessTypes.map((businessType) => (
                      <span key={businessType}>{businessType}</span>
                    ))}
                  </TextLoop>
                </h1>
              </FadeIn>
              <FadeIn delay={0.5}>
                <div className="block lg:hidden">
                  <ProductVideo />
                </div>
              </FadeIn>
              <FadeIn delay={0.5}>
                {/* <p className="mt-3 max-w-md mx-auto text-lg text-gray-500 sm:text-xl md:mt-5 md:max-w-3xl">
              Tailored solutions. Instant coverage. Big savings.
            </p> */}
                <p className="mt-4 lg:mt-0 text-center lg:text-left max-w-md mx-auto lg:mx-0 tracking-tight text-base text-alt font-medium sm:text-lg">
                  Tailored risk management for your growing business. From quote
                  to coverage in clicks, not weeks.
                </p>
              </FadeIn>
              <div className="mt-4 justify-center">
                {/* <div delay={0.55}>
              <div className="rounded-md shadow mr-4">
                <a
                  role="button"
                  className="w-full md:w-auto btn-secondary"
                  onClick={() => {
                    setOpenSubscribeDialog(true);
                  }}
                >
                  <span>Subscribe</span>
                </a>
              </div>
            </div> */}
                <FadeIn delay={0.55}>
                  <div className="rounded-md mt-4 sm:mt-0 text-center lg:text-left">
                    <a href="/quote" className="w-full md:w-auto btn-primary">
                      <span>Get a quote</span>
                      <ArrowRightIcon className="h-4 w-4 inline" />
                    </a>
                  </div>
                </FadeIn>
              </div>
            </div>
            {/* HERO PRODUCT VIDEO */}
            <div className="px-8 lg:px-0 hidden lg:flex lg:flex-col lg:justify-center ">
              <ProductVideo />
            </div>
          </div>
        </div>
        {/* TRUSTED BY */}
        <div className="flex flex-col justify-end">
          <PressLogos />
        </div>
      </div>
      {/* <div className="max-w-7xl w-full pt-20 lg:pt-32">
        <div className="px-8 lg:w-3/4 lg:px-0"> */}
      {/* <FadeIn>
            <div className="hidden md:inline-flex w-full max-w-[280px] mx-auto h-12 relative">
              <Image
                src="/coverdash_logo_new_light_transparent_shield.png"
                alt="Coverdash logo"
                width={842.5}
                height={143.5}
              />
            </div>
          </FadeIn> */}
      {/* <div delay={0.3}>
            <h1 className="mt-4 text-2xl tracking-tight font-extrabold text-alt md:text-3xl">
              Business insurance in a{" "}
              <span className="text-primary">dash.</span>
            </h1>
          </div> */}

      {/* Desktop buttons */}
      {/* <div className="mt-10 justify-center hidden sm:flex"> */}

      {/* Mobile buttons */}
      {/* <div className="mt-10 justify-center sm:hidden">
            <div delay={0.55}>
              <div className="rounded-md shadow">
                <a href="/quote" className="w-full md:w-auto btn-primary">
                  <span>Get a quote</span>
                  <ArrowRightIcon className="h-4 w-4 inline" />
                </a>
              </div>
            </div>
            <div delay={0.55}>
              <div className="rounded-md shadow mt-4 sm:mt-0">
                <a
                  role="button"
                  className="w-full md:w-auto btn-secondary"
                  onClick={() => {
                    setOpenSubscribeDialog(true);
                  }}
                >
                  <span>Subscribe</span>
                </a>
              </div>
            </div>
          </div> */}
      {/* </div>
      </div> */}
      {/* <div className="mt-16">
        <img
          src="/img/hero.jpg"
          alt="Illustration of people in office space"
          className="object-cover w-full h-full hidden lg:block"
        />
        <div className="h-full lg:hidden">
          <Image
            height={756}
            width={1170}
            src="/img/hero-mobile.png"
            alt="Illustration of people in office space"
          />
        </div>
      </div> */}
    </div>
  )
}
