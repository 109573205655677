import React from 'react';

import { useRouter } from 'next/router';

export function BlogError() {
  const router = useRouter();

  return (
    <div className="my-64 text-center max-w-5xl mx-auto h-full flex flex-col justify-center">
      <h1 className="text-5xl font-semibold">
        <span className="block">Oops, there was an error.</span>
        <span className="block">Please try again</span>
      </h1>
      <div className="mt-10 flex items-center justify-center space-x-3 sm:pl-6">
        <button className="btn-primary" onClick={() => router.reload()}>
          Refresh
        </button>
        <button className="btn-secondary" onClick={() => router.push('/blog')}>
          Back to blog
        </button>
      </div>
    </div>
  );
}
