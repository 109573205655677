import { classNames } from "../utilities"
import {
  ArrowNarrowLeftIcon,
  ArrowNarrowRightIcon,
} from "@heroicons/react/outline"
import { Pagination as IPagination } from "@tryghost/content-api"
import Link from "next/link"

export function Pagination({ pagination }: { pagination: IPagination }) {
  const prevLink =
    pagination.prev === 1 ? "/blog" : `/blog/page/${pagination.prev}`
  console.log(pagination)
  return (
    <nav className="border-t border-gray-200 px-4 flex items-center justify-between sm:px-0">
      <div className="-mt-px w-0 flex-1 flex">
        {pagination.prev && (
          <Link
            href={prevLink}
            className="border-t-2 border-transparent pt-4 pl-1 inline-flex items-center text-sm font-medium text-gray-500 hover:text-gray-70 hover:border-gray-300"
          >
            <ArrowNarrowLeftIcon
              className="mr-3 h-5 w-5 text-gray-400 hover:text-gray-700"
              aria-hidden="true"
            />
            Previous
          </Link>
        )}
      </div>
      <div className="hidden md:-mt-px md:flex">
        {[...Array(pagination.pages)].map((_, index) => {
          const link = index === 0 ? "/blog" : `/blog/page/${index + 1}`
          return (
            <Link
              key={index}
              href={link}
              className={classNames(
                index + 1 === pagination.page
                  ? "border-primary text-primary"
                  : "",
                "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300 border-t-2 pt-4 px-4 inline-flex items-center text-sm font-medium"
              )}
            >
              {index + 1}
            </Link>
          )
        })}
      </div>
      <div className="-mt-px w-0 flex-1 flex justify-end">
        {pagination.next && (
          <Link
            href={`/blog/page/${pagination.next}`}
            className="border-t-2 border-transparent pt-4 pl-1 inline-flex items-center text-sm font-medium text-gray-500 hover:text-gray-700 hover:border-gray-300"
          >
            Next
            <ArrowNarrowRightIcon
              className="ml-3 h-5 w-5 text-gray-400 hover:text-gray-700"
              aria-hidden="true"
            />
          </Link>
        )}
      </div>
    </nav>
  )
}
