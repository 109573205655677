/* eslint-disable @next/next/no-html-link-for-pages */
import { FadeIn } from "./"
import Subscribe from "./CustomerIOSubscribe"
import { ArrowCircleRightIcon } from "@heroicons/react/outline"

export function BottomGraphic() {
  return (
    <div className="border-y border-slate-200">
      <FadeIn>
        <div className="container mx-auto px-8 pt-20 py-10">
          <div className="max-w-3xl w-full flex flex-col px-8 mx-auto rounded-3xl relative border-solid border-[12px] border-primary lg:py-6 items-center justify-items-center lg:flex-col lg:pr-40 pl-4 pb-0">
            <div className="text-5xl font-extrabold text-alt text-center mt-4">
              Business insurance
            </div>
            <div className="text-5xl font-extrabold text-center mb-6">
              in a <span className="text-primary">dash.</span>
            </div>
            <a
              href="/quote"
              className="bg-primary hover:bg-primaryHover text-white text-sm px-3 py-2 font-semibold rounded-lg cursor-pointer inline-block mx-auto sm:mb-0"
            >
              Get a quote <ArrowCircleRightIcon className="h-5 w-5 inline" />
            </a>
            <img
              src="/img/last.svg"
              className="lg:absolute right-0 bottom-0 mt-8 sm:mt-20 homeLastImg"
              alt="Illustration of woman pointing"
              aria-hidden="true"
            />
          </div>
        </div>
      </FadeIn>
      <FadeIn>
        <div className="w-full max-w-md mx-auto mb-10 px-6">
          <Subscribe />
        </div>
      </FadeIn>
    </div>
  )
}
