/* eslint-disable @next/next/no-html-link-for-pages */
import Image from 'next/image';

import { ArrowRightIcon } from '@heroicons/react/outline';

import { FadeIn } from './FadeIn';

export function WhoNeedsInsuranceSection({
  title,
  description
}: {
  title: string;
  description: string;
}) {
  return (
    <div className="max-w-7xl mx-auto py-8 lg:py-20 px-4 sm:px-6 lg:pg-8">
      <div className="text-center">
        <FadeIn>
          <h2 className="text-5xl text-alt font-extrabold my-7">
            Who needs <span className="text-primary">{title}</span> insurance?
          </h2>
        </FadeIn>
        <p
          className="max-w-2xl mt-5 mx-auto text-xl text-gray-500"
          dangerouslySetInnerHTML={{ __html: description }}
        />
        <a href="/quote" className="w-auto inline-flex btn-primary mt-4">
          See if you're covered <ArrowRightIcon className="h-4 w-4 inline" />
        </a>
      </div>
      <div className="mt-12 space-y-10 lg:space-y-0 grid lg:grid-cols-4 lg:gap-8">
        <div className="grid grid-flow-row sm:grid-flow-col auto-cols-fr lg:grid-flow-row text-center sm:text-left lg:text-center">
          <div className="relative w-full h-48">
            <Image
              src="/img/insure3.svg"
              aria-hidden="true"
              alt="Small Business Owner icon"
              layout="fill"
            />
          </div>
          <div>
            <p className="mt-4 lg:-mt-1 text-gray-700 text-2xl">
              Small Business Owner
            </p>
            <p className="mt-2 text-base text-gray-500">
              Managing your own business means you have enough on your plate.
            </p>
          </div>
        </div>

        <div className="grid grid-flow-row sm:grid-flow-col auto-cols-fr lg:grid-flow-row text-center sm:text-left lg:text-center">
          <div className="relative w-full h-48">
            <Image
              src="/img/insure2.svg"
              aria-hidden="true"
              alt="E-commerce icon"
              layout="fill"
            />
          </div>
          <div>
            <p className="mt-4 text-gray-700 text-2xl">E-Commerce Merchant</p>
            <p className="mt-2 text-base text-gray-500">
              Selling online is hard enough without having to think about what
              else can go wrong.
            </p>
          </div>
        </div>

        <div className="grid grid-flow-row sm:grid-flow-col auto-cols-fr lg:grid-flow-row text-center">
          <div className="relative w-40 sm:w-48 rounded-lg mx-auto h-40 sm:h-48">
            <Image
              src="/img/startup-owner.jpg"
              aria-hidden="true"
              alt="Startup Owner icon"
              layout="fill"
              className="rounded-xl"
            />
          </div>
          <div>
            <p className="mt-4 text-gray-700 text-2xl">Startup</p>
            <p className="mt-2 text-base text-gray-500">
              Don't let unnecessary business risks make your founder journey
              bumpier than it needs to be.
            </p>
          </div>
        </div>
        <div className="grid grid-flow-row sm:grid-flow-col auto-cols-fr lg:grid-flow-row text-center sm:text-left lg:text-center">
          <div className="relative w-full h-48">
            <Image
              src="/img/insure1.svg"
              aria-hidden="true"
              alt="Freelance icon"
              layout="fill"
            />
          </div>
          <div>
            <p className="mt-4 text-gray-700 text-2xl">Freelancer</p>
            <p className="mt-2 text-base text-gray-500">
              Freelancing is all about keeping things simple and focusing on
              what you do best.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
