import { FadeIn } from '.';

interface Faq {
  question: React.ReactNode;
  answer: string;
}

interface Props {
  title: string;
  faqs: Faq[];
}

export function FaqSection({ title, faqs }: Props) {
  return (
    <div className="bg-gray-50">
      <div className="max-w-7xl mx-auto py-8 lg:py-20 px-4 sm:px-6 lg:pg-8">
        <div className="max-w-5xl mx-auto text-center">
          <FadeIn>
            <h2 className="text-5xl text-alt font-extrabold my-7">
              {title} <span className="text-primary">FAQs</span>
            </h2>
          </FadeIn>
        </div>
        {faqs.length > 0 && (
          <div className="mt-20">
            <dl className="space-y-10 lg:space-y-0 lg:grid lg:grid-cols-2 lg:gap-x-8 lg:gap-y-10">
              {faqs.map((faq, index) => (
                <div key={index}>
                  <dt className="font-semibold text-alt">{faq.question}</dt>
                  <dd
                    className="mt-3 text-gray-500"
                    dangerouslySetInnerHTML={{ __html: faq.answer }}
                  />
                </div>
              ))}
            </dl>
          </div>
        )}
      </div>
    </div>
  );
}
