import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/outline';
import { chunk } from 'lodash';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';

import MediaQuery from '../hooks/useMediaQuery';

interface Item {
  title: string;
  icon: string;
}

const Type = ({ title, iconPath }: { title: string; iconPath: string }) => {
  const iconUrl = 'https://img.icons8.com/ios-filled/100/null'; //'https://coverdash.s3.amazonaws.com/icons';
  const imageSrc = `${iconUrl}/${iconPath}.png`;

  return (
    <div className="py-6">
      <div className="flow-root rounded-lg bg-gray-50 p-6 h-full flex-col items-center relative shadow">
        <span className="inline-flex w-12 items-center justify-center rounded-md bg-primary p-3 shadow-lg absolute -top-4 -ml-6">
          <img
            src={imageSrc}
            style={{ filter: 'invert(100%)' }}
            className="h-6 w-6 text-white"
            aria-hidden="true"
            alt={`${title} icon`}
          />
        </span>
        <h3 className="mt-4 text-md font-medium tracking-tight text-gray-900 h-full flex flex-col justify-center">
          {title}
        </h3>
      </div>
    </div>
  );
};

export function InsuranceCarousel({ items }: { items: Item[] }) {
  const isSmall = MediaQuery(768);
  const groups = chunk(items, isSmall ? 4 : 8);

  return (
    <Carousel
      className="relative"
      showStatus={false}
      showIndicators={false}
      showArrows={true}
      renderArrowPrev={(onClickHandler, hasPrev, label) =>
        hasPrev && (
          <button
            type="button"
            onClick={onClickHandler}
            title={label}
            className="rounded-full p-2 bg-transparent absolute z-10 left-0"
            style={{ top: 'calc(50% - 2rem)', zIndex: 1 }}
          >
            <ChevronLeftIcon className="w-6 h-6 text-primary" />
          </button>
        )
      }
      renderArrowNext={(onClickHandler, hasNext, label) =>
        hasNext && (
          <button
            type="button"
            onClick={onClickHandler}
            title={label}
            className="rounded-full p-2 bg-transparent absolute z-10 right-0"
            style={{ top: 'calc(50% - 2rem)', zIndex: 1 }}
          >
            <ChevronRightIcon className="w-6 h-6 text-primary" />
          </button>
        )
      }
    >
      {groups.map((group, idx) => (
        <div
          key={idx}
          className="grid grid-cols-2 lg:grid-cols-4 gap-4 px-4 lg:px-10"
        >
          {group.map((type, index) => (
            <Type key={index} iconPath={type.icon} title={type.title} />
          ))}
        </div>
      ))}
    </Carousel>
  );
}
