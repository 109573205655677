/* eslint-disable @next/next/no-html-link-for-pages */
import React from 'react';

import Image from 'next/image';
import Link from 'next/link';

const ConditionalLinkWrapper = ({
  link,
  children
}: {
  link?: string;
  children: JSX.Element;
}) => {
  if (!link) {
    return children;
  }

  return <Link href={link}>{children}</Link>;
};

export function Product({
  title,
  image,
  link
}: {
  title: string;
  image: string;
  link?: string;
}) {
  return (
    <ConditionalLinkWrapper>
      <div className="cursor-pointer md:max-w-xs">
        <p className="text-center text-lg text-gray-700 my-4">{title}</p>
        <Link href={link as string}>
          <div className="shadow-lg rounded-2xl bg-white transform transition duration-500 hover:scale-105 overflow-hidden text-center">
            <Image
              src={image}
              alt=""
              width="410"
              height="237"
              className="p-2"
            />
          </div>
        </Link>
        <div className="flex space-y-0 justify-center mb-4">
          <a href="/quote">
            <div className="btn-primary px-5 py-2 inline-block mx-auto mt-4">
              Get started
            </div>
          </a>
        </div>
      </div>
    </ConditionalLinkWrapper>
  );
}
