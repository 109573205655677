/* eslint-disable @next/next/no-html-link-for-pages */
import { DASHBOARD_URL, classNames } from "../utilities"
import { IntercomChat } from "./IntercomChat"
import { Disclosure, Popover, Transition } from "@headlessui/react"
import {
  ArrowRightIcon,
  ChevronDownIcon,
  MenuIcon,
  XIcon,
} from "@heroicons/react/outline"
import { PhoneIcon } from "@heroicons/react/solid"
import Image from "next/image"
import Link from "next/link"
import { useRouter } from "next/router"
import React, { Fragment, useEffect, useState } from "react"

interface NavItem {
  name: string
  href?: string
  current?: boolean
}

interface Nav extends NavItem {
  children?: NavItem[]
}

const navigation: Nav[] = [
  { name: "Home", href: "/" },
  {
    name: "Who We Insure",
    children: [
      { name: "Small Business", href: "/small-business", current: false },
      { name: "Startup", href: "/startup", current: false },
      { name: "E-Commerce", href: "/e-commerce", current: false },
      { name: "Freelancer", href: "/freelancer", current: false },
    ],
  },
  {
    name: "Coverage",
    children: [
      {
        name: "General Liability",
        href: "/general-liability",
      },
      {
        name: "Business Owners Policy",
        href: "/business-policy",
      },
      {
        name: "Workers Compensation",
        href: "/workers-compensation",
      },
      {
        name: "Cyber",
        href: "/cyber",
      },
      {
        name: "Professional Liability",
        href: "/professional-liability",
      },
      {
        name: "Management Liability",
        href: "/management-liability",
      },
    ],
  },
  { name: "Partner", href: "/partner", current: false },
  { name: "Blog", href: "/blog", current: false },
  {
    name: "About Us",
    current: false,
    children: [
      { name: "Our Team", href: "/about" },
      { name: "Join Us", href: "https://careers.coverdash.com/" },
    ],
  },
]

function getParentActiveClass(item: Nav, pathname: string) {
  if (item.children) {
    return item.children.some((child) => child.href === pathname)
  }

  return item.href === pathname
}

export function Header() {
  const [isNavbarTop, setIsNavbarTop] = useState(true)
  const router = useRouter()

  useEffect(() => {
    const navbarConvertYOffset = 10

    window.addEventListener("scroll", () => {
      scrollY >= navbarConvertYOffset
        ? setIsNavbarTop(false)
        : setIsNavbarTop(true)
    })
  }, [])

  return (
    <div className="fixed inset-x-0 top-0 z-10">
      <IntercomChat />
      <Popover
        as="nav"
        className={classNames(
          isNavbarTop ? "bg-transparent" : "bg-white shadow",
          "transition-all duration-300"
        )}
      >
        {({ open }) => (
          <>
            <div className="px-2 xl:px-6 lg:px-8">
              <div className="relative flex items-center justify-between h-16">
                <div className="xl:hidden">
                  <div className="absolute inset-y-0 left-0 flex items-center">
                    {/* Mobile menu button*/}
                    <Popover.Button className="inline-flex items-center justify-center p-2 rounded-lg hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-gray-200">
                      <span className="sr-only">Open main menu</span>
                      {open ? (
                        <XIcon className="block h-6 w-6" aria-hidden="true" />
                      ) : (
                        <MenuIcon
                          className="block h-6 w-6"
                          aria-hidden="true"
                        />
                      )}
                    </Popover.Button>
                  </div>
                </div>
                <div className="flex-1 flex items-center justify-start  xl:items-stretch xl:justify-start ml-12">
                  <div className="flex-shrink-0 flex items-center">
                    <Link href="/" passHref>
                      <div className="flex items-center">
                        {/* {router.route === '/' ? (
                          <>
                            <Transition
                              show={!isNavbarTop}
                              enter="transition-opacity duration-150"
                              enterFrom="opacity-0"
                              enterTo="opacity-100"
                              leave="transition-opacity duration-150"
                              leaveFrom="opacity-100"
                              leaveTo="opacity-0"
                              className="md:inline-flex hidden"
                            >
                              <Image
                                src="/coverdash_logo_new_light_transparent_shield.png"
                                alt="Coverdash logo"
                                width={140.6859}
                                height={24}
                                className="cursor-pointer"
                              />
                            </Transition>
                            <div className="md:hidden flex items-center">
                              <Image
                                src="/coverdash_logo_new_light_transparent_shield.png"
                                alt="Coverdash logo"
                                width={140.6859}
                                height={24}
                                className="cursor-pointer"
                              />
                            </div>
                          </>
                        ) : ( */}
                        <Image
                          src="/coverdash_logo_new_light_transparent_shield.png"
                          alt="Coverdash logo"
                          width={140.6859}
                          height={24}
                          className="cursor-pointer"
                        />
                        {/* )} */}
                      </div>
                    </Link>
                  </div>
                  <div id="nav-items" className="hidden xl:block ml-6">
                    <div className="flex space-x-2">
                      {navigation.map((item) => (
                        <div
                          key={item.name}
                          className={item.children && "group"}
                        >
                          <a
                            key={item.name}
                            href={item.href}
                            className={classNames(
                              getParentActiveClass(item, router.pathname)
                                ? "text-primary"
                                : "text-gray-500 hover:text-primary hover:bg-green-50 font-light active:bg-green-50 active:shadow-lg active:shadow-green-50",
                              "px-3 py-2 rounded-lg border border-transparent"
                            )}
                            aria-current={item.current ? "page" : undefined}
                          >
                            {item.name}
                            {item.children && (
                              <ChevronDownIcon className="h-4 w-4 inline ml-1" />
                            )}
                          </a>
                          {item.children && (
                            <div className="hidden group-hover:block absolute mt-2">
                              <div className="border shadow-lg bg-white rounded-xl px-3 py-2 mt-1">
                                {item.children.map((item) => (
                                  <a
                                    key={item.name}
                                    href={item.href}
                                    className={classNames(
                                      item.href === router.pathname
                                        ? "text-primary"
                                        : "text-gray-500 hover:text-primary hover:bg-green-50 font-light active:bg-green-50 active:shadow-lg active:shadow-green-50",
                                      "px-3 py-2 rounded-lg my-1 pr-20 text-sm block"
                                    )}
                                    aria-current={
                                      item.current ? "page" : undefined
                                    }
                                  >
                                    {item.name}
                                  </a>
                                ))}
                              </div>
                            </div>
                          )}
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
                <div className="absolute inset-y-0 right-0 flex items-center pr-2 xl:static xl:inset-auto xl:ml-6 xl:pr-0">
                  <div className="flex items-center space-x-4">
                    <div className="hidden sm:block mt-1 relative w-fit mx-auto lg:mx-0 text-xs px-4 py-1.5 rounded-full border border-green-300 text-green-900 bg-green-100 font-medium">
                      <a href="tel:1-888-880-3274">
                        <PhoneIcon className="mr-1 w-3 h-3 inline -mt-1" /> 1
                        (888) 880-DASH
                      </a>
                    </div>
                    <Link href={`${DASHBOARD_URL}/login`} passHref>
                      <div className="hidden sm:inline-block btn-secondary px-3 py-2">
                        Login
                      </div>
                    </Link>
                    <a href="/quote">
                      <div className="btn-primary px-3 py-2 text-sm">
                        <span>Get a quote</span>
                        <ArrowRightIcon className="h-4 w-4 inline" />
                      </div>
                    </a>
                  </div>
                </div>
              </div>
            </div>

            <Transition
              as={Fragment}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0"
              enterTo="transform opacity-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100"
              leaveTo="transform opacity-0"
            >
              <Popover.Panel className="xl:hidden -mt-2 absolute w-full">
                <div className="overflow-y-auto border shadow-lg bg-white rounded-xl ml-2 lg:ml-8 mr-2 sm:w-72 lg:w-96">
                  <div className="p-3 block sm:hidden border-b">
                    <div className="sm:hidden mt-1 relative w-fit mx-auto lg:mx-0 text-xs px-4 py-1.5 rounded-full border border-green-300 text-green-900 bg-green-100 font-medium">
                      <a href="tel:1-888-880-3274">
                        <PhoneIcon className="mr-1 w-3 h-3 inline -mt-1" /> 1
                        (888) 880-DASH
                      </a>
                    </div>
                  </div>
                  <div className="px-3 sm:pt-4 pb-4">
                    {navigation.map((item) => (
                      <div key={item.name}>
                        {item.children ? (
                          <Disclosure key={item.name}>
                            {({ open }) => (
                              <>
                                <Disclosure.Button
                                  as="a"
                                  className={classNames(
                                    getParentActiveClass(item, router.pathname)
                                      ? "text-primary"
                                      : "text-gray-500 hover:text-primary hover:bg-green-50 font-light active:bg-green-100 active:shadow-lg active:shadow-green-50",
                                    "text-sm w-full flex items-center justify-between space-x-2 px-3 py-2 rounded-lg border border-transparent  cursor-pointer"
                                  )}
                                >
                                  <span>{item.name}</span>
                                  <ChevronDownIcon
                                    className={`${
                                      open ? "rotate-180 transform" : ""
                                    } h-5 w-5 text-primary`}
                                  />
                                </Disclosure.Button>
                                <Disclosure.Panel className="pl-2 text-sm text-gray-500">
                                  {item.children &&
                                    item.children.map((item) => (
                                      <div key={item.name}>
                                        <Popover.Button
                                          key={item.name}
                                          as="a"
                                          href={item.href}
                                          className={classNames(
                                            getParentActiveClass(
                                              item,
                                              router.pathname
                                            )
                                              ? "text-primary"
                                              : "text-gray-500 hover:text-primary hover:bg-green-50 font-light active:bg-green-100 active:shadow-lg active:shadow-green-50",
                                            "block px-3 py-2 rounded-lg border border-transparent text-sm"
                                          )}
                                          aria-current={
                                            item.current ? "page" : undefined
                                          }
                                        >
                                          {item.name}
                                        </Popover.Button>
                                      </div>
                                    ))}
                                </Disclosure.Panel>
                              </>
                            )}
                          </Disclosure>
                        ) : (
                          <Popover.Button
                            key={item.name}
                            as="a"
                            href={item.href}
                            className={classNames(
                              getParentActiveClass(item, router.pathname)
                                ? "text-primary"
                                : "text-gray-500 hover:text-primary hover:bg-green-50 font-light active:bg-green-100 active:shadow-lg active:shadow-green-50",
                              "block px-3 py-2 rounded-lg border border-transparent text-sm"
                            )}
                            aria-current={item.current ? "page" : undefined}
                          >
                            {item.name}
                          </Popover.Button>
                        )}
                      </div>
                    ))}
                  </div>
                  <div className="sm:hidden items-center justify-between px-3 py-3 mb-5 border-t border-gray-100">
                    <Link
                      href={DASHBOARD_URL}
                      className="btn-primary px-3 py-2 w-full text-sm"
                    >
                      Login
                    </Link>
                  </div>
                </div>
              </Popover.Panel>
            </Transition>
          </>
        )}
      </Popover>
    </div>
  )
}
