import Image from "next/image"
import Link from "next/link"
import { SVGAttributes } from "react"

const navigation = {
  who: [
    { name: "Small Business", href: "/small-business" },
    { name: "Startup", href: "/startup" },
    { name: "E-Commerce", href: "/e-commerce" },
    { name: "Freelancer", href: "/freelancer" },
  ],
  coverage: [
    { name: "General Liability", href: "/general-liability" },
    { name: `Business Owner's Policy`, href: "/business-policy" },
    { name: "Workers Compensation", href: "/workers-compensation" },
    { name: "Cyber", href: "/cyber" },
    { name: "Professional Liability", href: "/professional-liability" },
    { name: "Management Liability", href: "/management-liability" },
  ],
  about: [
    { name: "Partners", href: "/partner" },
    { name: "About Us", href: "/about" },
    { name: "Blog", href: "/blog" },
    {
      name: "Help center",
      href: "http://support.coverdash.com",
      external: true,
    },
  ],
  legal: [
    {
      name: "File a claim",
      href: `${process.env.NEXT_PUBLIC_DASHBOARD_URL}/claims`,
    },
    { name: "Privacy", href: "/privacy" },
    { name: "Terms", href: "/terms" },
    { name: "Compensation Disclosure", href: "/compensation-disclosure" },
    { name: "Licenses", href: "/licenses" },
  ],
}

const social = [
  {
    name: "Twitter",
    href: "https://twitter.com/coverdash_inc",
    icon: (props: SVGAttributes<unknown>) => (
      <svg fill="currentColor" viewBox="0 0 24 24" {...props}>
        <path d="M8.29 20.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0022 5.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.072 4.072 0 012.8 9.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 012 18.407a11.616 11.616 0 006.29 1.84" />
      </svg>
    ),
  },
  {
    name: "Facebook",
    href: "https://facebook.com/Coverdash",
    icon: (props: SVGAttributes<unknown>) => (
      <svg fill="currentColor" viewBox="0 0 24 24" {...props}>
        <path
          fillRule="evenodd"
          d="M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z"
          clipRule="evenodd"
        />
      </svg>
    ),
  },
  {
    name: "Instagram",
    href: "https://www.instagram.com/coverdash_inc/",
    icon: (props: SVGAttributes<unknown>) => (
      <svg fill="currentColor" viewBox="0 0 24 24" {...props}>
        <path d="M12,9.52A2.48,2.48,0,1,0,14.48,12,2.48,2.48,0,0,0,12,9.52Zm9.93-2.45a6.53,6.53,0,0,0-.42-2.26,4,4,0,0,0-2.32-2.32,6.53,6.53,0,0,0-2.26-.42C15.64,2,15.26,2,12,2s-3.64,0-4.93.07a6.53,6.53,0,0,0-2.26.42A4,4,0,0,0,2.49,4.81a6.53,6.53,0,0,0-.42,2.26C2,8.36,2,8.74,2,12s0,3.64.07,4.93a6.86,6.86,0,0,0,.42,2.27,3.94,3.94,0,0,0,.91,1.4,3.89,3.89,0,0,0,1.41.91,6.53,6.53,0,0,0,2.26.42C8.36,22,8.74,22,12,22s3.64,0,4.93-.07a6.53,6.53,0,0,0,2.26-.42,3.89,3.89,0,0,0,1.41-.91,3.94,3.94,0,0,0,.91-1.4,6.6,6.6,0,0,0,.42-2.27C22,15.64,22,15.26,22,12S22,8.36,21.93,7.07Zm-2.54,8A5.73,5.73,0,0,1,19,16.87,3.86,3.86,0,0,1,16.87,19a5.73,5.73,0,0,1-1.81.35c-.79,0-1,0-3.06,0s-2.27,0-3.06,0A5.73,5.73,0,0,1,7.13,19a3.51,3.51,0,0,1-1.31-.86A3.51,3.51,0,0,1,5,16.87a5.49,5.49,0,0,1-.34-1.81c0-.79,0-1,0-3.06s0-2.27,0-3.06A5.49,5.49,0,0,1,5,7.13a3.51,3.51,0,0,1,.86-1.31A3.59,3.59,0,0,1,7.13,5a5.73,5.73,0,0,1,1.81-.35h0c.79,0,1,0,3.06,0s2.27,0,3.06,0A5.73,5.73,0,0,1,16.87,5a3.51,3.51,0,0,1,1.31.86A3.51,3.51,0,0,1,19,7.13a5.73,5.73,0,0,1,.35,1.81c0,.79,0,1,0,3.06S19.42,14.27,19.39,15.06Zm-1.6-7.44a2.38,2.38,0,0,0-1.41-1.41A4,4,0,0,0,15,6c-.78,0-1,0-3,0s-2.22,0-3,0a4,4,0,0,0-1.38.26A2.38,2.38,0,0,0,6.21,7.62,4.27,4.27,0,0,0,6,9c0,.78,0,1,0,3s0,2.22,0,3a4.27,4.27,0,0,0,.26,1.38,2.38,2.38,0,0,0,1.41,1.41A4.27,4.27,0,0,0,9,18.05H9c.78,0,1,0,3,0s2.22,0,3,0a4,4,0,0,0,1.38-.26,2.38,2.38,0,0,0,1.41-1.41A4,4,0,0,0,18.05,15c0-.78,0-1,0-3s0-2.22,0-3A3.78,3.78,0,0,0,17.79,7.62ZM12,15.82A3.81,3.81,0,0,1,8.19,12h0A3.82,3.82,0,1,1,12,15.82Zm4-6.89a.9.9,0,0,1,0-1.79h0a.9.9,0,0,1,0,1.79Z" />
      </svg>
    ),
  },
  {
    name: "LinkedIn",
    href: "https://linkedin.com/company/coverdash",
    icon: (props: SVGAttributes<unknown>) => (
      <svg viewBox="0 0 24 25" fill="currentColor" {...props}>
        <path d="M5.58654 8.03564H.768505c-.213832 0-.387096.17335-.387096.3871V23.901c0 .2139.173264.3871.387096.3871H5.58654c.21384 0 .3871-.1732.3871-.3871V8.42274c0-.21375-.17326-.3871-.3871-.3871ZM3.1793.341003C1.42622.341003 0 1.76567 0 3.51682c0 1.75192 1.42622 3.17714 3.1793 3.17714 1.75169 0 3.17675-1.42529 3.17675-3.17714C6.35613 1.76567 4.93099.341003 3.1793.341003ZM17.8416 7.65094c-1.9351 0-3.3656.83187-4.2332 1.77708V8.42273c0-.21375-.1733-.38709-.3871-.38709H8.60719c-.21383 0-.3871.17334-.3871.38709V23.901c0 .2139.17327.3871.3871.3871h4.80751c.2138 0 .3871-.1732.3871-.3871v-7.6581c0-2.5807.701-3.586 2.4999-3.586 1.9591 0 2.1148 1.6117 2.1148 3.7187v7.5255c0 .2138.1733.3871.3871.3871h4.8093c.2139 0 .3871-.1733.3871-.3871V15.411c0-3.8373-.7317-7.76006-6.1584-7.76006Z" />
      </svg>
    ),
  },
]

const date = new Date()
const year = date.getFullYear()

export function Footer() {
  return (
    <footer className="bg-white" aria-labelledby="footer-heading">
      <h2 id="footer-heading" className="sr-only">
        Footer
      </h2>
      <div className="max-w-7xl mx-auto py-8 px-4 sm:px-6 lg:py-10 lg:px-8">
        <div className="pb-8 xl:grid xl:grid-cols-4 xl:gap-8">
          <div className="grid grid-cols-2 gap-8 xl:col-span-4">
            <div className="md:grid md:grid-cols-2 md:gap-8">
              <div>
                <h3 className="text-base font-medium text-gray-900">
                  Who We Insure
                </h3>
                <ul role="list" className="mt-2 space-y-2">
                  {navigation.who.map((item) => (
                    <li key={item.name}>
                      <Link
                        className="text-sm text-gray-500 hover:text-gray-900"
                        href={item.href}
                      >
                        {item.name}
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>
              <div className="mt-12 md:mt-0">
                <h3 className="text-base font-medium text-gray-900">
                  What We Cover
                </h3>
                <ul role="list" className="mt-2 space-y-2">
                  {navigation.coverage.map((item) => (
                    <li key={item.name}>
                      <Link
                        className="text-sm text-gray-500 hover:text-gray-900"
                        href={item.href}
                      >
                        {item.name}
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
            <div className="md:grid md:grid-cols-2 md:gap-8">
              <div>
                <h3 className="text-base font-medium text-gray-900">About</h3>
                <ul role="list" className="mt-2 space-y-2">
                  {navigation.about.map((item) => (
                    <li key={item.name}>
                      {item.external ? (
                        <a
                          className="text-sm text-gray-500 hover:text-gray-900"
                          href={item.href}
                        >
                          {item.name}
                        </a>
                      ) : (
                        <Link
                          className="text-sm text-gray-500 hover:text-gray-900"
                          href={item.href}
                        >
                          {item.name}
                        </Link>
                      )}
                    </li>
                  ))}
                </ul>
              </div>
              <div className="mt-12 md:mt-0">
                <h3 className="text-base font-medium text-gray-900">Legal</h3>
                <ul role="list" className="mt-2 space-y-2">
                  {navigation.legal.map((item) => (
                    <li key={item.name}>
                      <Link
                        className="text-sm text-gray-500 hover:text-gray-900"
                        href={item.href}
                      >
                        {item.name}
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>
              <div className="absolute right-4">
                <Image
                  // className="absolute right-0"
                  src="/img/socforserviceorganizationslogosos.png"
                  alt="SOC2 Compliant"
                  width={60}
                  height={60}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="mt-6 border-t border-gray-200 pt-4 md:flex md:items-center md:justify-between">
          <div className="flex">
            <div>
              <Image
                src="/coverdash_logo_new_light_transparent_shield.png"
                alt="Coverdash logo"
                width={140.6859}
                height={24}
              />
              <p className="pt-1 text-sm text-gray-400">
                &copy; {year} Coverdash, Inc. All rights reserved.
              </p>
            </div>
          </div>

          <div className="flex space-x-4 md:order-2">
            {social.map((item) => (
              <a
                key={item.name}
                href={item.href}
                target="_blank"
                rel="noopener noreferrer"
                className="text-gray-400 hover:text-gray-500"
              >
                <span className="sr-only">{item.name}</span>
                <item.icon className="h-6 w-6" aria-hidden="true" />
              </a>
            ))}
          </div>
        </div>
      </div>
    </footer>
  )
}
