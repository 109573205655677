/* eslint-disable @typescript-eslint/ban-ts-comment */

/* eslint-disable @next/next/no-img-element */

/* eslint-disable jsx-a11y/alt-text */
import { classNames } from "../utilities"
import React from "react"

const ProductVideo = () => {
  return (
    <div
      className="relative w-full mx-auto max-w-2xl"
      dangerouslySetInnerHTML={{
        __html: `
     <video
       loop
       muted
       autoplay
       playsinline
       src="/vid/hero-video-3-compressed.mp4"
       class="border border-gray-200 rounded-lg shadow"
     />,
   `,
      }}
    ></div>
  )
  // React.useEffect(() => {
  //   const video = document.getElementById("dash-loader")
  //   // @ts-ignore
  //   video.playbackRate = 1.15
  // }, [])
  return (
    <div
      className={classNames(
        "wistia_responsive_padding",
        "relative w-full mx-auto"
      )}
    >
      <div className="wistia_responsive_wrapper">
        <iframe
          src="https://fast.wistia.net/embed/iframe/whpueqlj3t?seo=true&videoFoam=true"
          title="hero-video-1"
          allow="autoplay; fullscreen"
          // allowTransparency
          frameBorder={0}
          scrolling="no"
          className="wistia_embed border border-gray-200 rounded-lg shadow"
          name="wistia_embed"
          allowFullScreen
          width="100%"
          height="100%"
        />
      </div>
    </div>
  )
  return (
    // <>
    // <Script src="https://fast.wistia.com/embed/medias/9br5u58m8c.jsonp" async></Script>
    // <Script src="https://fast.wistia.com/assets/external/E-v1.js" async></Script>
    // <div className="wistia_responsive_padding" style={{padding: '100.0% 0 0 0', position: 'relative'}}><div className="wistia_responsive_wrapper" style={{height: '100%', left: 0, position: 'absolute', top: 0, width: '100%'}}><div className="wistia_embed wistia_async_9br5u58m8c seo=true videoFoam=true" style={{height: '100%', position: 'relative', width: '100%'}}><div className="wistia_swatch" style={{height: '100%', left: 0, opacity: 0, overflow: 'hidden', position: 'absolute', top: 0, transition: 'opacity 200ms', width: '100%'}}><img src="https://fast.wistia.com/embed/medias/9br5u58m8c/swatch" style={{filter: 'blur(5px)', height: '100%', objectFit: 'contain', width: '100%'}} alt="" aria-hidden="true"
    // onLoad={() => { this.parentNode.style.opacity = 1; }} /></div></div></div></div>

    // </>
    <div
      className={classNames("w-full max-w-md mx-auto")}
      dangerouslySetInnerHTML={{
        __html: `<script src="https://fast.wistia.com/embed/medias/9br5u58m8c.jsonp" async></script><script src="https://fast.wistia.com/assets/external/E-v1.js" async></script><div class="wistia_responsive_padding" style="padding:100.0% 0 0 0;position:relative;"><div class="wistia_responsive_wrapper" style="height:100%;left:0;position:absolute;top:0;width:100%;"><div class="wistia_embed wistia_async_9br5u58m8c seo=true videoFoam=true" style="height:100%;position:relative;width:100%"><div class="wistia_swatch" style="height:100%;left:0;opacity:0;overflow:hidden;position:absolute;top:0;transition:opacity 200ms;width:100%;"><img src="https://fast.wistia.com/embed/medias/9br5u58m8c/swatch" style="filter:blur(5px);height:100%;object-fit:contain;width:100%;" alt="" aria-hidden="true" onload="this.parentNode.style.opacity=1;" /></div></div></div></div>`,
      }}
    ></div>
  )
  return (
    <div className={classNames("w-full max-w-lg mx-auto")}>
      <video
        id="dash-loader"
        className={classNames("mx-auto")}
        // className={classNames("sm:w-[400px] w-[200px] mx-auto")}
        // width="400"
        controls
        controlsList="nodownload nofullscreen noremoteplayback"
        autoPlay
        loop
        muted
      >
        {/* <source src="/loader/dash-loader.webm" type="video/webm"></source> */}
        <source src="/vid/hero-video-1.mp4" type="video/mp4"></source>
        Your browser does not support the video tag.
      </video>
      {/* <img
        className={classNames("sm:w-[400px] w-[200px] mx-auto sm:hidden")}
        src="/loader/dash-loader.gif"
      ></img> */}
      {/* <img
        className={classNames("sm:w-[400px] w-[200px] mx-auto sm:hidden")}
        src="/vid/hero-video-1.mp4"
      ></img> */}
    </div>
  )
}

export default ProductVideo
