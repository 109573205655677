export function StandingGirlSvg() {
  return (
    <svg
      className="w-40"
      viewBox="0 0 225 832"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_2079_14077)">
        <path
          d="M152.938 83.29C151.956 58.5542 143.659 27.2478 123.358 12.3431C110.787 3.12747 82.3548 6.23816 82.3548 6.23816C1.24706 10.0475 4.17346 157.198 61.5375 172.618C66.2596 172.801 112.151 194.393 122.892 195.042C117.521 188.039 129.36 166.43 138.987 149.014C147.201 134.175 154.002 109.739 152.938 83.3066V83.29Z"
          fill="#DDDDDD"
        />
        <path
          d="M51.1787 53.647C51.1787 53.647 -17.3256 87.0827 70.2834 219.029L77.6992 204.074C77.6992 204.074 48.2024 135.922 55.7678 107.626C61.155 87.4985 52.8913 75.6214 51.1621 53.647H51.1787Z"
          fill="#DDDDDD"
        />
        <path
          d="M100.013 216.417C101.875 216.201 125.935 212.907 126.6 211.809C163.828 150.477 160.403 91.1415 146.154 71.7122C98.3502 6.63736 98.9987 23.0225 98.9987 23.0225C93.6946 56.242 99.83 77.6674 113.398 94.6847C132.17 118.223 110.422 187.323 99.9963 216.417H100.013Z"
          fill="#DDDDDD"
        />
        <path
          d="M45.1263 89.1785C48.3353 102.486 49.9814 112.7 49.9814 112.7C44.5776 113.315 42.865 107.66 40.1714 102.802C36.0478 95.3832 33.3542 87.1823 41.7509 85.4689C43.1643 85.1861 45.1263 89.1785 45.1263 89.1785Z"
          fill="#DDDDDD"
        />
        <path
          d="M46.3568 108.525C46.3568 108.525 43.3639 105.83 43.5135 99.8581C43.8627 85.7518 34.9505 85.8184 38.0265 95.0673C38.0265 95.0673 38.7249 90.4595 39.9885 91.6738C41.5515 93.1543 38.808 94.3852 41.0859 101.388C42.2665 105.031 46.3401 108.541 46.3401 108.541L46.3568 108.525Z"
          fill="#DDDDDD"
        />
        <path
          d="M87.4095 22.5899C61.4377 20.9431 46.2238 47.0928 44.744 76.6194C43.9126 93.1875 46.3901 107.41 55.9008 118.456C59.7584 122.93 64.0981 124.81 69.3024 128.087C72.5946 130.166 77.9153 134.841 81.4237 137.003C84.6327 138.966 88.0247 140.463 91.9986 140.164C104.037 139.266 110.821 128.836 113.863 125.01C124.588 111.552 135.18 105.813 128.944 72.4441C124.039 46.2278 104.17 21.2758 87.4095 22.5899Z"
          fill="#DDDDDD"
        />
        <path
          d="M94.4428 90.2763C93.9938 76.7856 92.1316 65.4241 106.015 61.0991C118.985 57.0568 123.973 64.8419 125.419 66.6717C129.144 71.3959 130.408 84.4042 130.408 84.4042C130.408 84.4042 118.502 97.7452 94.4428 90.2929V90.2763Z"
          fill="#DDDDDD"
        />
        <path
          d="M103.405 97.7118C105.733 105.197 111.153 111.219 117.804 113.165C126.483 115.727 131.222 103.667 131.073 89.4444C130.989 81.6261 119.816 77.0848 113.298 79.2972C106.78 81.5096 101.094 90.2262 103.405 97.7118Z"
          fill="#DDDDDD"
        />
        <path
          d="M83.5686 92.8212C80.7586 82.4079 81.6564 65.923 62.7346 66.6382C56.8319 66.8545 52.2927 69.1334 49.5492 71.8948C43.1477 78.3324 48.9506 92.7048 48.9506 92.7048C48.9506 92.7048 61.5042 105.181 83.5686 92.8212Z"
          fill="#DDDDDD"
        />
        <path
          d="M74.54 101.122C73.7418 107.842 70.6159 120.019 64.2643 119.72C56.0671 119.354 49.4993 115.345 47.3876 99.4253C46.4898 92.6716 55.5849 86.7164 61.87 87.6812C68.1717 88.646 75.3547 94.4016 74.54 101.122Z"
          fill="#DDDDDD"
        />
        <path
          d="M121.928 85.5187C119.716 86.3837 118.319 88.1304 118.785 89.4113C119.251 90.6921 121.395 91.0415 123.607 90.1764C125.802 89.3114 127.215 87.5648 126.749 86.2839C126.284 85.0031 124.139 84.6537 121.944 85.5187H121.928Z"
          fill="#DDDDDD"
        />
        <path
          d="M53.9388 93.5698C51.611 93.3702 49.6656 94.2851 49.5659 95.6491C49.4827 97.0132 51.2951 98.2608 53.6229 98.477C55.9507 98.6766 57.9127 97.7617 57.9959 96.4143C58.079 95.0669 56.2666 93.786 53.9388 93.5864V93.5698Z"
          fill="#DDDDDD"
        />
        <path
          d="M69.9675 112.716C68.8202 112.467 68.0886 111.652 68.4544 110.754C68.7703 109.938 73.8416 112.716 75.9366 115.095C73.2929 113.581 71.1314 112.966 69.9675 112.716Z"
          fill="#DDDDDD"
        />
        <path
          d="M111.436 107.593C112.467 107.011 112.916 106.013 112.3 105.264C111.752 104.582 107.745 108.724 106.448 111.635C108.526 109.389 110.405 108.175 111.436 107.593Z"
          fill="#DDDDDD"
        />
        <path
          d="M80.9248 120.302C83.5353 124.56 95.8062 129.484 103.089 117.64C103.089 117.64 91.5995 124.444 80.9248 120.302Z"
          fill="#DDDDDD"
        />
        <path
          d="M89.8038 111.502C78.913 112.617 72.3784 111.702 69.7513 111.469V111.502C69.8345 111.602 69.8844 111.652 69.9675 111.752C71.3974 112.201 73.0103 112.417 75.1885 113.631C76.6849 114.463 78.7966 121 92.2979 119.304C99.6804 118.372 103.854 115.328 107.628 109.772C108.809 108.042 110.056 107.393 111.053 106.645C111.053 106.512 111.12 106.462 111.137 106.329C108.277 107.51 102.557 110.204 89.8038 111.519V111.502Z"
          fill="#DDDDDD"
        />
        <path
          d="M102.125 111.286C94.7254 113.864 84.3832 114.846 80.559 114.596C78.281 113.881 78.8131 116.625 78.8131 116.625C84.1338 120.95 87.8417 119.603 91.6826 119.254C95.4736 118.921 100.927 116.742 103.937 113.382C104.153 112.45 103.172 110.936 102.141 111.286H102.125Z"
          fill="#DDDDDD"
        />
        <path
          d="M105.167 108.092C105.167 108.092 102.823 109.589 97.0865 110.87C91.2836 112.167 88.7729 112.101 83.4854 112.45C81.0578 112.616 79.5281 112.433 76.4188 112.051C76.4188 112.051 77.0673 112.683 77.5162 113.448C78.0982 114.463 80.8084 117.79 91.6826 116.409C99.8633 115.361 103.837 111.951 104.22 110.105C104.452 109.007 105.151 108.441 105.184 108.075L105.167 108.092Z"
          fill="#DDDDDD"
        />
        <path
          d="M92.9629 118.705C76.319 120.601 81.3238 113.132 69.9508 111.735C70.0339 111.735 72.6444 112.783 75.9532 115.827C79.5946 119.17 83.0365 124.71 92.3477 123.562C100.744 122.514 104.685 115.86 106.98 112.184C109.607 107.975 111.02 106.661 111.087 106.628C102.341 110.138 106.132 116.775 92.9629 118.705Z"
          fill="#DDDDDD"
        />
        <path
          d="M82.9534 118.306C80.2431 117.291 77.6326 114.762 77.1172 114.463C77.8155 115.212 82.4379 123.612 91.7824 122.331C102.59 120.851 103.704 113.348 105.384 110.97C104.585 112.034 102.59 114.896 99.8633 116.193C98.0842 117.058 95.0247 118.173 91.5496 118.738C89.3382 119.087 85.5472 119.071 82.9534 118.306Z"
          fill="#DDDDDD"
        />
        <path
          d="M99.6637 117.324C99.8466 117.69 98.9487 119.071 96.8204 119.936C94.0769 121.067 91.7325 120.551 91.5496 120.185C91.3667 119.819 95.0912 119.121 96.1553 118.622C97.2029 118.123 99.4808 116.958 99.6803 117.307L99.6637 117.324Z"
          fill="#DDDDDD"
        />
        <path
          d="M70.2168 112.367C70.9152 112.583 71.3641 112.034 74.0744 112.683C74.0744 112.683 69.3689 110.887 69.0862 111.053C68.8867 111.169 69.186 112.051 70.2168 112.384V112.367Z"
          fill="#DDDDDD"
        />
        <path
          d="M111.186 107.277C110.571 107.659 109.79 107.277 107.628 108.558C107.628 108.558 111.569 105.513 111.951 105.713C112.151 105.813 112.101 106.711 111.186 107.277Z"
          fill="#DDDDDD"
        />
        <path
          d="M76.6682 112.301L77.5162 113.515C77.5162 113.515 82.521 115.511 92.1981 114.363C99.1815 113.532 104.17 110.255 104.17 110.255L104.569 108.907L76.6682 112.301Z"
          fill="#DDDDDD"
        />
        <path
          d="M96.9036 109.007C93.6447 109.123 93.3454 109.705 91.0176 109.988C88.7563 110.271 86.8608 109.822 84.084 110.387C80.4759 111.136 76.0531 111.934 70.6326 111.435C70.6326 111.435 72.7442 112.417 82.704 113.182C85.6636 113.398 88.3073 113.665 91.2836 113.249C94.8585 112.849 97.735 111.768 100.445 110.986C105.034 109.656 110.305 106.678 110.322 106.578C107.911 107.576 100.495 108.874 96.9036 109.007Z"
          fill="#DDDDDD"
        />
        <path
          d="M80.3596 111.868C80.4094 111.519 85.1981 110.537 85.9962 110.437C86.7943 110.354 89.5045 110.504 90.4523 110.404C91.4 110.288 94.3098 109.789 95.0747 109.506C95.8561 109.24 101.792 109.073 101.792 109.506C101.792 109.922 93.3621 111.935 92.3644 112.151C91.3668 112.35 80.1933 112.916 80.3429 111.885L80.3596 111.868Z"
          fill="#DDDDDD"
        />
        <path
          d="M92.8632 128.819C89.6708 128.952 87.0769 130.016 87.0769 131.214C87.0769 132.412 89.6375 133.277 92.8133 133.144C95.9891 133.011 98.583 131.946 98.5996 130.748C98.5996 129.551 96.039 128.686 92.8632 128.819Z"
          fill="#DDDDDD"
        />
        <path
          d="M48.4518 90.1763C48.4518 90.1763 46.1406 113.016 63.7322 123.645C71.996 128.636 74.5732 131.896 79.0459 135.389C79.0459 135.389 67.9722 127.654 61.421 124.044C58.2452 122.298 42.5657 113.764 48.4518 90.1763Z"
          fill="#DDDDDD"
        />
        <path
          d="M80.8251 136.62C80.8251 136.62 92.3145 149.462 107.046 133.011C107.046 133.011 95.1411 145.47 80.8251 136.62Z"
          fill="#DDDDDD"
        />
        <path
          d="M131.621 88.3966C131.621 88.3966 128.928 66.705 123.291 52.6154C116.956 36.8125 108.892 24.8688 95.324 23.4382C95.324 23.4382 58.3117 17.0338 49.9149 47.8745C41.0526 80.4452 45.5586 96.2149 47.2047 103.418C47.2047 103.418 48.0194 85.1362 51.1952 79.1477C51.1952 79.1477 61.421 67.6033 68.471 53.1976C82.5709 24.3365 100.229 26.5988 107.03 34.5169C118.037 47.3422 124.372 66.2725 131.655 88.3966H131.621Z"
          fill="#DDDDDD"
        />
        <path
          d="M129.609 79.4636C129.493 93.1539 130.208 103.467 130.208 103.467C135.612 102.852 136.011 96.9466 137.524 91.6069C139.852 83.4393 140.8 74.8724 133.417 75.255C131.97 75.3382 129.626 79.4636 129.626 79.4636H129.609Z"
          fill="#DDDDDD"
        />
        <path
          d="M132.785 98.5771C132.785 98.5771 135.08 95.2835 133.583 89.4946C130.058 75.8375 138.754 73.8746 137.84 83.5726C137.84 83.5726 136.111 79.2476 135.146 80.7115C133.966 82.508 136.909 83.0902 136.277 90.4261C135.944 94.2355 132.769 98.5771 132.769 98.5771H132.785Z"
          fill="#DDDDDD"
        />
        <path
          d="M109.973 59.8514C109.973 59.8514 114.911 56.9902 123.607 61.1655C128.662 63.5942 127.215 77.2014 127.215 77.2014C127.215 77.2014 126.201 60.234 109.973 59.8514Z"
          fill="#DDDDDD"
        />
        <path
          d="M125.469 58.2544C125.469 58.2544 129.327 79.5468 130.989 84.5372L130.94 76.7688C130.94 76.7688 126.982 60.7995 125.469 58.271V58.2544Z"
          fill="#DDDDDD"
        />
        <path
          d="M44.6275 71.9282C44.6275 71.9282 45.8247 88.1637 45.0765 94.385L42.5159 83.6557L44.6275 71.9449V71.9282Z"
          fill="#DDDDDD"
        />
        <path
          d="M21.9978 49.638C38.4754 0.266232 78.9462 -6.87004 96.2551 6.47097C97.6684 11.2119 100.262 18.265 92.6803 25.867C72.2288 13.208 67.041 53.3475 51.7107 65.0084C40.4208 73.6085 36.3139 92.8216 21.2163 116.809C12.7863 87.5983 18.9384 58.8203 21.9812 49.6712L21.9978 49.638Z"
          fill="#DDDDDD"
        />
        <path
          d="M19.7698 97.6453C19.8696 86.8328 20.1855 68.0356 41.5349 37.3113C51.8438 22.6229 66.1931 11.2448 79.1956 11.461C85.514 11.461 90.0699 14.2889 92.4309 16.5013C94.8751 18.7138 96.3882 20.3939 96.4547 20.4271C96.1388 20.2441 89.2717 15.0708 79.9605 17.882C70.7989 20.4438 61.1551 31.406 51.7939 44.431C33.0883 70.2646 19.3209 97.113 19.7698 97.6453Z"
          fill="#DDDDDD"
        />
        <path
          d="M143.826 48.8894C143.826 49.0558 135.828 37.6444 122.593 26.5657C116.091 21.1594 109.374 17.6329 104.186 17.7826C98.9654 17.8325 96.5544 20.2612 96.4048 20.4275C96.6209 20.178 98.1506 16.9342 103.954 15.6534C109.74 14.3226 117.904 17.3002 124.605 22.8562C138.189 33.9182 143.859 49.0059 143.826 48.9061V48.8894Z"
          fill="#DDDDDD"
        />
        <path
          d="M95.5402 5.70561C95.5402 5.70561 109.757 2.11252 126.434 13.7402C138.189 21.941 146.819 39.0748 149.496 57.8719C149.496 57.8719 142.446 31.2398 126.084 17.4497C116.973 9.7811 101.609 5.6058 95.6233 7.16946C95.6233 7.16946 78.2479 -0.565667 57.5802 8.18417C40.1881 15.5533 19.0881 36.33 16.9764 85.1029C16.9764 85.1029 14.3826 9.81437 71.5803 0.449049C86.1125 -1.92971 95.5402 5.70561 95.5402 5.70561Z"
          fill="#DDDDDD"
        />
        <path
          d="M130.956 85.4853C130.956 85.4853 128.512 79.6299 124.322 78.1494C135.08 91.0746 127.265 107.576 120.681 115.511C113.398 124.277 110.538 129.118 109.391 130.582C110.438 129.351 120.182 117.973 123.008 115.278C125.635 112.783 135.396 99.8244 130.956 85.4853Z"
          fill="#DDDDDD"
        />
        <path
          d="M101.592 61.9142C98.4666 63.8438 96.6043 68.4516 99.3644 67.1873C102.125 65.9231 112.234 60.6832 114.545 60.3671C118.12 59.8681 123.64 61.149 125.137 61.8643C125.137 61.8643 118.153 57.6224 114.146 57.6723C110.156 57.7222 103.272 60.8828 101.592 61.9142Z"
          fill="#DDDDDD"
        />
        <path
          d="M68.9033 65.6403C67.0078 65.108 58.927 63.6275 55.0695 64.6589C51.2119 65.7069 45.6584 71.7785 45.6584 71.7785C46.9055 70.6973 52.1597 67.6698 55.4353 67.387C57.7465 67.1874 68.9034 69.4164 71.8963 69.8988C74.8892 70.3646 72.445 66.655 68.9033 65.6403Z"
          fill="#DDDDDD"
        />
        <path
          d="M111.619 67.9692C98.4832 69.4996 96.9867 77.6506 97.3858 83.4894C98.4 83.5892 99.4808 83.6724 101.493 84.0383C104.22 84.554 107.462 85.4856 111.918 84.9366C115.426 84.5041 123.075 81.7927 122.293 74.2239C121.678 68.2354 114.794 67.8694 111.602 67.9859L111.619 67.9692Z"
          fill="#DDDDDD"
        />
        <path
          d="M97.9346 82.9567C98.9488 80.2619 100.545 75.0885 106.182 73.2919C111.818 71.4788 117.122 73.3252 121.628 77.0015C118.469 80.894 116.59 83.0066 112.467 84.1045C108.21 85.2356 100.545 83.0232 97.9346 82.9733V82.9567Z"
          fill="#DDDDDD"
        />
        <path
          d="M99.0319 74.7393C99.0319 74.7393 100.678 71.4124 104.785 69.4495C108.892 67.4866 113.248 67.653 116.657 68.4182C116.657 68.4182 109.59 67.9191 105.151 70.248C100.711 72.5768 99.0485 74.7393 99.0485 74.7393H99.0319Z"
          fill="#DDDDDD"
        />
        <path
          d="M59.8415 74.0073C72.9771 72.4769 76.3025 80.0623 77.2502 85.8346C76.2859 86.1673 75.255 86.4833 73.3761 87.3151C70.8321 88.4296 67.8891 90.1097 63.433 90.5755C59.9247 90.9581 51.8604 90.0764 50.8628 82.5409C50.0813 76.5691 56.699 74.6394 59.8415 74.0073Z"
          fill="#DDDDDD"
        />
        <path
          d="M60.573 78.8149C57.231 79.8463 54.2381 81.8258 52.6418 84.4208L53.7392 85.9845C53.7392 85.9845 55.3355 84.321 57.8961 82.8904C58.2286 81.2602 59.193 79.813 60.573 78.8149Z"
          fill="#DDDDDD"
        />
        <path
          d="M64.8795 80.8276C67.5731 80.7611 69.851 81.3599 71.6468 82.175C71.1979 80.5947 70.0672 79.3139 68.6539 78.4821C68.0387 78.2659 67.4068 78.1162 66.7584 78.0663C64.7299 77.9165 62.6016 78.1993 60.573 78.8148C59.193 79.8129 58.1455 81.2435 57.8628 82.9069C59.7417 81.8423 62.1859 80.8942 64.8795 80.8276Z"
          fill="#DDDDDD"
        />
        <path
          d="M73.6422 77.7002C73.6422 77.7002 71.2811 74.839 66.825 73.8742C62.3689 72.9094 58.1622 74.0738 55.0363 75.5876C55.0363 75.5876 61.8035 73.475 66.6587 74.7226C71.5139 75.9536 73.6255 77.7002 73.6255 77.7002H73.6422Z"
          fill="#DDDDDD"
        />
        <path
          d="M61.9864 84.4705C61.6705 81.6925 63.1503 79.0975 65.5779 77.7335C60.174 77.5172 55.6847 80.2952 52.2262 84.537C56.1003 87.7143 58.478 90.2427 62.718 89.8934C63.7323 89.8102 64.4971 89.7104 65.3451 89.5275C63.5161 88.4795 62.2358 86.633 61.9864 84.4705Z"
          fill="#DDDDDD"
        />
        <path
          d="M76.6018 85.4519C75.3215 83.5721 71.281 78.3322 66.6088 77.8332C66.2596 77.7999 65.9104 77.7666 65.5613 77.75C63.1503 79.114 61.6539 81.7091 61.9698 84.487C62.2192 86.6496 63.5161 88.496 65.3285 89.544C69.9675 88.6956 75.1219 85.9509 75.6873 85.7346C76.2526 85.5184 76.5851 85.4685 76.5851 85.4685L76.6018 85.4519Z"
          fill="#DDDDDD"
        />
        <path
          d="M52.725 84.2876C52.725 84.2876 57.9459 79.7963 64.7465 77.8999C63.1503 79.0643 63.6823 80.8276 62.8011 82.0253C58.7773 83.04 55.801 85.5685 54.6704 87.2652L52.725 84.271V84.2876Z"
          fill="#DDDDDD"
        />
        <path
          d="M62.1194 82.2252C62.1194 82.2252 62.4353 80.4952 63.699 79.1977C63.699 79.1977 68.5209 77.7504 71.9627 80.1625C75.4212 82.5745 76.6017 85.4523 76.6017 85.4523C76.6017 85.4523 70.5993 79.7799 62.1194 82.2252Z"
          fill="#DDDDDD"
        />
        <path
          d="M64.7465 82.5078C64.8961 84.8366 67.041 86.5833 69.5351 86.4169C72.0292 86.234 73.9247 84.2045 73.7751 81.8757C73.6088 79.5468 71.4805 77.8002 68.9864 77.9665C66.4923 78.1329 64.5968 80.1789 64.7631 82.5078H64.7465Z"
          fill="#DDDDDD"
        />
        <path
          d="M69.5517 82.4414C69.3356 83.0902 68.6539 83.4062 68.0387 83.19C67.4234 82.9571 67.1075 82.2584 67.3237 81.6097C67.5565 80.9609 68.2216 80.6449 68.8368 80.8611C69.452 81.094 69.7513 81.7927 69.5351 82.4248L69.5517 82.4414Z"
          fill="#DDDDDD"
        />
        <path
          d="M97.9346 82.9737C97.9346 82.9737 104.186 76.0204 110.272 76.719L112.051 73.2258C112.051 73.2258 98.9655 72.7434 97.9346 82.957V82.9737Z"
          fill="#DDDDDD"
        />
        <path
          d="M110.854 84.371C112.018 84.2879 113.681 83.9053 115.127 83.1068C117.638 81.7261 119.966 79.6468 120.647 78.8982C120.331 78.1829 119.6 73.7747 119.567 73.6084C118.054 72.1445 115.842 71.3461 113.481 71.6122C109.457 72.078 106.547 75.5214 106.996 79.2975C107.262 81.6263 108.825 83.3563 110.871 84.371H110.854Z"
          fill="#DDDDDD"
        />
        <path
          d="M107.129 76.9686C107.129 76.9686 107.462 75.172 108.842 73.8912C108.842 73.8912 117.638 72.3608 120.847 76.586C120.847 76.586 120.548 78.0166 119.65 78.9814C119.65 78.9814 113.863 75.2386 107.113 76.9686H107.129Z"
          fill="#DDDDDD"
        />
        <path
          d="M118.802 76.2863C119.184 78.5986 117.505 80.7943 115.044 81.1936C112.583 81.5928 110.272 80.0458 109.889 77.7502C109.507 75.438 111.186 73.2422 113.664 72.843C116.125 72.4271 118.436 73.9741 118.818 76.2863H118.802Z"
          fill="#DDDDDD"
        />
        <path
          d="M114.578 77.2511C114.362 77.8832 113.697 78.1993 113.099 77.983C112.5 77.7501 112.184 77.0681 112.4 76.436C112.616 75.8039 113.282 75.4878 113.88 75.7041C114.479 75.9203 114.795 76.619 114.562 77.2511H114.578Z"
          fill="#DDDDDD"
        />
        <path
          d="M111.918 80.7612C111.619 81.5763 110.704 82.0088 109.873 81.7094C109.042 81.41 108.609 80.5117 108.908 79.68C109.208 78.8649 110.122 78.4324 110.954 78.7318C111.785 79.0312 112.217 79.9461 111.918 80.7612Z"
          fill="#DDDDDD"
        />
        <path
          d="M66.9912 85.9345C66.6919 86.7662 65.7442 87.1987 64.8962 86.8993C64.0482 86.5832 63.6159 85.6683 63.9152 84.8366C64.2311 84.0049 65.1622 83.5724 66.0102 83.8718C66.8582 84.1712 67.2905 85.1028 66.9912 85.9345Z"
          fill="#DDDDDD"
        />
        <path
          d="M51.4946 85.4357C51.3948 85.2028 51.345 85.0364 51.3283 84.9699C47.82 84.1881 47.8532 79.4971 47.8033 79.3307C47.8865 79.364 51.7107 80.7281 55.5683 78.183C66.7085 72.1113 73.692 77.8004 76.6184 85.4523C76.6184 85.4523 71.0649 78.7485 64.8297 79.1311C60.5232 79.3973 56.3165 81.094 53.0576 84.5707C55.8011 87.8976 58.079 88.6961 62.7346 89.8938C59.459 90.1599 56.117 88.9456 53.9887 87.7479C50.6965 85.9014 49.4162 86.8164 49.3996 86.7997C49.7487 86.3506 50.048 85.5022 51.5112 85.4357H51.4946Z"
          fill="#DDDDDD"
        />
        <path
          d="M76.6017 85.452C76.6017 85.452 71.1147 78.9811 65.3451 79.1142C59.5754 79.2306 55.3022 82.3247 53.0409 84.5704C53.0409 84.5704 55.3853 78.532 65.5778 78.3823C73.1931 78.2658 76.6017 85.452 76.6017 85.452Z"
          fill="#DDDDDD"
        />
        <path
          d="M122.377 77.1682C122.41 76.9187 122.427 76.7357 122.427 76.6692C125.669 75.1055 124.555 70.5476 124.571 70.3646C124.505 70.4145 121.096 72.627 116.757 71.0467C104.519 67.7031 99.032 74.8394 97.9346 82.9571C97.9346 82.9571 101.792 75.1554 107.944 74.0908C112.201 73.3589 116.69 74.0409 120.664 76.6858C118.769 80.5617 116.673 81.8758 112.417 84.1215C115.676 83.6391 118.685 81.6596 120.481 79.9961C123.258 77.451 124.721 78.0499 124.721 78.0166C124.272 77.6506 123.807 76.9021 122.36 77.1682H122.377Z"
          fill="#DDDDDD"
        />
        <path
          d="M97.9346 82.9568C97.9346 82.9568 101.792 75.4046 107.429 74.1903C113.082 72.9759 117.937 75.0054 120.664 76.6688C120.664 76.6688 116.989 71.3291 107.03 73.5249C99.5973 75.1717 97.9346 82.9401 97.9346 82.9401V82.9568Z"
          fill="#DDDDDD"
        />
        <path
          d="M81.224 102.786C81.224 102.786 88.7728 106.661 90.3025 106.279C91.8488 105.896 97.8513 100.889 97.8513 100.889L81.2074 102.786H81.224Z"
          fill="#DDDDDD"
        />
        <path
          d="M88.7728 94.8841C84.3666 95.5162 83.2526 96.7804 83.7181 97.9116C84.8321 100.69 86.7775 101.006 89.5377 100.69C92.4474 100.34 94.6256 99.442 94.7254 96.298C94.7586 95.3665 93.1957 94.2519 88.7894 94.8674L88.7728 94.8841Z"
          fill="#DDDDDD"
        />
        <path
          d="M92.6138 102.253C92.6138 102.253 96.8205 98.2777 97.8181 99.0762C100.744 101.455 92.6138 102.253 92.6138 102.253Z"
          fill="#DDDDDD"
        />
        <path
          d="M87.1766 102.868C87.1766 102.868 81.9556 100.423 81.091 101.355C78.6468 103.966 87.1766 102.868 87.1766 102.868Z"
          fill="#DDDDDD"
        />
        <path
          d="M81.2241 102.785C81.2241 102.785 80.6089 101.654 82.4711 101.571C83.8179 101.504 85.198 104.216 89.9368 103.534C94.9914 102.802 95.3905 100.174 96.6874 99.9575C97.868 99.7579 97.868 100.872 97.868 100.872C97.868 100.872 100.129 99.3586 99.0984 98.3439C97.2195 96.5141 97.1862 100.723 89.6042 101.488C81.3737 102.32 79.977 98.3938 79.1457 101.105C78.8131 102.22 79.711 102.436 81.2241 102.769V102.785Z"
          fill="#DDDDDD"
        />
        <path
          d="M150.46 88.9287C150.46 88.9287 154.6 110.521 152.173 131.38C149.762 152.157 133.683 164.816 133.683 164.816C133.683 164.816 144.624 152.124 148.415 127.771C149.928 118.039 150.443 88.9121 150.443 88.9121L150.46 88.9287Z"
          fill="#DDDDDD"
        />
        <path
          d="M156.828 136.438C169.98 73.8745 129.992 39.2245 120.132 30.8572C111.253 23.3384 100.063 14.4887 92.6803 25.8502C92.6803 25.8502 99.6139 22.7728 108.011 30.0588C151.99 73.5917 149.562 101.704 141.248 135.323C132.835 169.308 111.336 175.812 132.519 216.617C124.388 166.979 148.116 177.825 156.812 136.438H156.828Z"
          fill="#DDDDDD"
        />
        <path
          d="M66.8416 138.218C66.8416 138.218 88.9725 131.048 116.025 138.218C116.025 138.218 121.03 141.944 122.676 152.241C124.305 162.538 92.115 168.643 92.115 168.643C92.115 168.643 60.8225 157.564 59.8083 153.788C58.079 147.417 66.8416 138.218 66.8416 138.218Z"
          fill="#DDDDDD"
        />
        <path
          d="M64.5138 142.193C64.5138 142.193 69.8678 136.621 91.6661 136.77C113.481 136.92 117.372 140.912 118.369 141.511C118.369 141.511 117.588 139.831 116.042 138.217C114.678 137.685 111.852 134.891 91.6827 135.024C74.2574 135.14 69.4521 137.103 66.8582 138.217C65.8107 139.232 64.5138 142.193 64.5138 142.193Z"
          fill="#DDDDDD"
        />
        <path
          d="M166.505 166.879C163.396 160.408 148.498 156.349 146.669 156.183C140.267 155.567 114.362 153.538 111.702 144.988C108.376 134.358 111.336 120.402 111.336 120.402L72.4117 118.722C76.901 126.207 76.7347 134.192 76.5518 142.276C76.3523 151.159 49.4827 157.414 40.2545 158.429C35.1999 158.978 17.4087 164.85 14.9645 173.55C11.506 185.759 11.7887 189.835 12.803 205.854L173.522 190.45C172.541 181.534 172.425 179.255 166.505 166.912V166.879Z"
          fill="#DDDDDD"
        />
        <path
          d="M131.339 154.653C132.719 154.087 134.082 153.255 135.479 152.424C119.966 150.411 115.277 147.766 114.163 147.001C112.965 146.186 111.818 142.31 111.386 137.785C111.203 135.822 110.904 130.882 110.837 128.303C110.289 128.969 76.2525 127.721 74.9389 125.792L70.9817 125.825C70.9817 125.825 72.1456 138.351 70.2999 147.333C69.9175 149.163 58.8936 151.742 51.6442 153.255C52.1596 153.655 54.0219 154.902 54.4375 155.135C60.6395 155.751 65.9602 156.482 68.4876 156.033C71.5802 155.501 76.7846 153.139 80.559 151.409C100.977 142.044 107.345 138.95 107.345 138.95C108.31 147.649 114.728 153.405 114.395 153.322C119.533 154.569 125.785 154.802 131.339 154.653Z"
          fill="#DDDDDD"
        />
        <path
          d="M98.8157 167.312C98.8157 167.312 96.6874 161.456 89.5377 163.735C89.5377 163.735 96.887 165.033 98.8157 167.312Z"
          fill="#DDDDDD"
        />
        <path
          d="M99.0652 162.305C99.0652 162.305 101.11 156.998 103.97 156.283C106.83 155.568 138.439 154.52 142.146 153.788C142.146 153.788 128.08 156.865 119.949 157.813C111.818 158.761 105.433 157.347 99.0652 162.305Z"
          fill="#DDDDDD"
        />
        <path
          d="M88.9724 162.255C88.9724 162.255 86.9938 158.179 83.4854 156.998C79.9605 155.817 53.0908 154.154 48.2689 154.619C48.2689 154.619 82.1719 158.013 88.9891 162.255H88.9724Z"
          fill="#DDDDDD"
        />
        <path
          d="M71.0981 141.561C71.0981 141.561 71.281 125.958 70.0007 120.468L111.02 132.279C111.02 132.279 94.5426 149.013 71.0981 141.561Z"
          fill="#DDDDDD"
        />
        <path
          d="M17.6249 165.132C17.6249 165.132 20.4681 163.585 22.5632 162.837C22.5632 162.837 36.6298 233.85 37.2949 237.443C37.3282 237.66 34.435 256.457 34.435 256.457C34.435 256.457 32.0241 242.484 31.7913 240.554C30.8435 232.786 26.4706 197.404 17.6249 165.132Z"
          fill="#DDDDDD"
        />
        <path
          d="M141.115 315.128C145.173 312.649 143.526 306.012 143.526 298.06C143.526 281.625 147.484 241.403 149.978 223.487C148.298 201.995 164.294 162.687 164.294 162.687L132.985 151.725C90.0698 195.724 53.9388 152.158 53.9388 152.158L19.9194 163.486C30.5276 204.008 34.302 236.063 34.302 239.34C34.302 242.617 43.0479 248.14 40.4375 276.535C40.0384 280.893 26.0383 305.978 30.2782 308.973C68.1385 325.341 99.5473 319.203 141.115 315.111V315.128Z"
          fill="#DDDDDD"
        />
        <path
          d="M30.2616 308.989C30.2616 308.989 54.554 317.706 86.1458 318.038C112.517 318.304 142.146 314.412 142.146 314.412C142.146 314.412 148.132 312.998 124.937 313.464C98.3502 314.013 99.83 312.981 99.83 312.981C99.83 312.981 108.393 307.292 114.046 290.94C114.046 290.94 104.935 299.524 92.9131 305.579C86.0294 309.056 78.1813 311.701 70.5328 311.418C69.7679 311.384 57.5802 304.714 55.4851 295.199C55.4851 295.199 53.6229 303.999 55.9673 308.157C55.9673 308.157 26.454 306.61 30.2616 308.989Z"
          fill="#DDDDDD"
        />
        <path
          d="M53.9388 152.158C53.9388 152.158 70.5328 171.005 92.5639 171.387C112.367 171.72 127.963 159.377 132.985 151.725L139.719 153.389C139.719 153.389 127.847 177.259 92.5805 176.344C62.3023 175.563 48.0527 154.137 48.0527 154.137L53.9388 152.174V152.158Z"
          fill="#DDDDDD"
        />
        <path
          d="M46.3069 235.946C57.231 238.392 136.992 239.706 141.897 237.244C146.802 234.799 142.612 179.006 142.612 179.006H34.2854C34.2854 179.006 39.7391 234.482 46.3069 235.946Z"
          fill="#DDDDDD"
        />
        <path
          d="M67.623 296.147V304.498L116.175 298.409L115.593 287.979L67.623 296.147Z"
          fill="#DDDDDD"
        />
        <path
          d="M204.997 740.176C204.997 740.176 208.14 754.432 208.29 757.476C208.439 760.52 209.786 782.378 209.786 782.378H184.346C184.346 782.378 182.95 761.286 180.855 750.822L204.997 740.176Z"
          fill="#DDDDDD"
        />
        <path
          d="M51.0292 776.54V751.887L72.7776 748.211C72.7776 748.211 77.2836 759.057 77.4831 762.384C77.6827 765.711 77.134 779.052 77.134 779.052L51.0292 776.556V776.54Z"
          fill="#DDDDDD"
        />
        <path
          d="M84.3336 807.563V821.404C84.3336 823.2 81.6399 827.276 75.97 827.276C74.59 827.276 49.7322 828.207 47.1051 827.276C44.478 826.344 42.2666 823.067 42.2666 821.404V808.778L84.3169 807.563H84.3336Z"
          fill="#DDDDDD"
        />
        <path
          d="M51.1788 775.841C51.1788 775.841 47.5042 791.345 47.1218 793.99C46.756 796.635 42.6989 804.536 42.3331 809.427C41.9673 814.317 42.6657 820.023 50.946 819.557C58.6777 819.125 73.8251 819.324 77.7159 817.861C81.5901 816.38 84.6827 812.271 84.3169 805.933C83.9511 799.596 78.0318 794.788 77.4831 772.88C77.4831 772.88 75.0888 766.26 63.3167 766.709C51.5446 767.175 51.1788 775.858 51.1788 775.858V775.841Z"
          fill="#DDDDDD"
        />
        <path
          d="M53.2904 774.111C53.8225 773.363 53.6396 771.882 56.2334 770.169C60.0743 767.64 69.8512 769.021 70.9818 769.62C72.1623 770.235 75.2384 772.464 75.704 772.83C76.1695 773.196 71.4806 792.06 73.2764 800.76C73.5757 802.191 49.7821 801.875 49.6823 801.176C49.5825 800.461 54.87 779.917 54.9698 778.287C55.0695 776.656 53.3902 774.527 53.2904 774.128V774.111Z"
          fill="#DDDDDD"
        />
        <path
          d="M55.3357 775.658C55.3357 775.658 56.4331 774.86 56.9485 775.159C57.4473 775.458 59.7585 779.85 59.7585 779.85C59.7585 779.85 54.5875 794.422 49.4164 802.756C49.5161 800.743 51.7276 794.222 51.8273 793.707C51.9271 793.208 55.3357 775.658 55.3357 775.658Z"
          fill="#DDDDDD"
        />
        <path
          d="M51.0291 776.54C51.0291 776.54 56.0838 774.843 56.4829 774.544C56.8819 774.244 58.6444 777.421 58.6444 779.634C58.6444 781.846 49.3997 802.739 49.3997 802.739L51.0125 776.54H51.0291Z"
          fill="#DDDDDD"
        />
        <path
          d="M70.0009 774.543L69.2361 774.81C69.2361 774.81 68.6208 779.384 68.8037 780.05C69.2028 781.497 70.0342 795.819 72.8275 802.922L70.0009 774.56V774.543Z"
          fill="#DDDDDD"
        />
        <path
          d="M77.4831 775.159C77.4831 775.159 69.6018 773.845 69.3025 774.045C69.0032 774.244 69.7015 778.436 69.7015 779.534C69.7015 780.632 71.9129 803.139 73.426 804.253C74.9225 805.351 77.4831 775.159 77.4831 775.159Z"
          fill="#DDDDDD"
        />
        <path
          d="M53.0078 793.174C58.2122 796.052 64.3975 796.368 69.4189 799.579C70.7325 800.411 71.9463 798.331 70.6327 797.499C65.578 794.289 59.4093 793.973 54.2216 791.095C52.8582 790.347 51.6444 792.426 53.0078 793.174Z"
          fill="#DDDDDD"
        />
        <path
          d="M51.8439 800.195C55.0529 798.864 58.2288 797.383 61.5043 796.269C64.2312 795.337 67.2075 795.254 69.7847 793.923C71.1648 793.208 69.951 791.129 68.5709 791.844C65.9105 793.225 62.5186 793.308 59.6753 794.389C56.8154 795.47 54.0221 796.718 51.1954 797.882C49.7821 798.464 50.3973 800.793 51.8439 800.211V800.195Z"
          fill="#DDDDDD"
        />
        <path
          d="M56.3333 786.887C59.6588 789.598 62.7847 791.827 67.0745 792.792C68.5876 793.125 69.236 790.812 67.723 790.463C63.8488 789.598 61.0721 787.635 58.0459 785.173C56.8487 784.208 55.1361 785.888 56.3499 786.87L56.3333 786.887Z"
          fill="#DDDDDD"
        />
        <path
          d="M55.7015 792.825C57.8796 791.511 60.3405 791.078 62.6849 790.18C64.7633 789.398 66.4593 787.901 68.5377 787.153C69.9843 786.637 69.3525 784.291 67.8893 784.824C65.5282 785.672 63.6493 787.302 61.255 788.117C58.9272 788.916 56.5993 789.448 54.4711 790.729C53.1409 791.527 54.3547 793.607 55.6849 792.808L55.7015 792.825Z"
          fill="#DDDDDD"
        />
        <path
          d="M58.4782 780.765C59.958 781.863 61.4046 782.628 63.1172 783.293C64.8464 783.959 66.2764 785.24 68.0888 785.672C69.6018 786.022 70.2337 783.709 68.7372 783.343C67.1743 782.977 65.8773 781.813 64.3809 781.197C62.6683 780.499 61.2051 779.784 59.7086 778.669C58.4616 777.737 57.2644 779.833 58.4948 780.748L58.4782 780.765Z"
          fill="#DDDDDD"
        />
        <path
          d="M57.4639 785.922C61.2549 784.957 64.7798 783.327 67.9557 781.031C69.2027 780.133 68.0055 778.037 66.7419 778.952C63.7157 781.148 60.4401 782.695 56.832 783.61C55.3356 783.992 55.9674 786.321 57.4805 785.938L57.4639 785.922Z"
          fill="#DDDDDD"
        />
        <path
          d="M49.3332 799.529C53.2905 800.593 57.2976 801.675 61.4378 801.658C65.5115 801.641 69.5519 800.577 73.5258 799.795C75.0389 799.496 74.4071 797.167 72.8774 797.466C69.1196 798.198 65.2787 799.229 61.4212 799.246C57.4971 799.263 53.7061 798.215 49.965 797.2C48.4685 796.801 47.8201 799.113 49.3165 799.529H49.3332Z"
          fill="#DDDDDD"
        />
        <path
          d="M52.4425 792.842C56.2335 793.507 60.0578 794.156 63.9153 793.89C66.4759 793.707 69.9344 794.322 72.1791 793.058C73.5259 792.293 72.3121 790.213 70.9653 790.979C69.8679 791.594 68.6541 791.162 67.4902 791.162C66.0602 791.162 64.6469 791.444 63.217 791.527C59.7918 791.71 56.4331 791.128 53.0744 790.529C51.5613 790.263 50.9128 792.592 52.4259 792.858L52.4425 792.842Z"
          fill="#DDDDDD"
        />
        <path
          d="M55.2193 785.805C60.4569 787.336 65.761 788.367 70.8489 785.839C72.2456 785.157 71.0152 783.061 69.6351 783.759C65.179 785.972 60.3903 784.807 55.8511 783.493C54.3547 783.061 53.7228 785.39 55.2026 785.822L55.2193 785.805Z"
          fill="#DDDDDD"
        />
        <path
          d="M69.5852 777.904C69.5852 777.904 68.8037 774.444 69.3025 774.045C69.8014 773.645 77.4831 775.159 77.4831 775.159C77.4831 775.159 70.3999 774.344 70.0009 774.544C69.6018 774.76 69.5852 777.904 69.5852 777.904Z"
          fill="#DDDDDD"
        />
        <path
          d="M51.0292 776.54C51.0292 776.54 56.2501 774.344 56.6159 774.527C57.2644 774.843 57.9627 776.673 57.9627 776.673C57.9627 776.673 56.1005 775.176 55.6516 775.342C55.2026 775.508 51.0458 776.54 51.0458 776.54H51.0292Z"
          fill="#DDDDDD"
        />
        <path
          d="M57.0482 780.599C59.4758 780.299 61.92 780.166 64.3642 780.249C66.4426 780.316 68.388 780.465 70.3999 779.85C71.8798 779.401 71.2479 777.072 69.7515 777.521C67.6398 778.153 65.5448 777.837 63.3666 777.804C61.2383 777.771 59.1267 777.92 57.015 778.186C55.5019 778.369 55.4687 780.781 57.015 780.599H57.0482Z"
          fill="#DDDDDD"
        />
        <path
          d="M42.4329 822.252C42.4329 822.252 43.6134 826.427 46.8225 827.242C49.0505 827.808 49.7655 827.874 59.7585 827.658C69.7514 827.442 76.8346 827.675 79.4451 826.826C82.0556 825.978 84.1008 824.198 84.3335 821.886C84.3335 821.886 83.0034 825.329 77.7824 825.945C73.6256 826.427 51.0458 826.926 47.6206 826.327C45.6419 825.978 43.1811 823.865 42.4329 822.235V822.252Z"
          fill="#DDDDDD"
        />
        <path
          d="M42.3164 811.739C42.3164 811.739 42.3497 817.711 46.7559 818.226C51.1621 818.742 76.9344 819.441 79.5615 816.629C82.1886 813.818 84.3335 811.556 84.3169 806.632C84.3169 806.632 85.3644 816.629 78.1648 818.759C73.0103 820.289 50.3973 820.222 46.5564 819.624C41.0361 818.759 42.2998 811.739 42.2998 811.739H42.3164Z"
          fill="#DDDDDD"
        />
        <path
          d="M84.7491 409.779C84.7491 409.779 87.4428 405.836 94.6424 445.177C101.842 484.518 110.388 563.782 119.833 594.573C129.277 625.364 137.308 638.672 160.32 694.365C165.84 707.722 180.855 754.233 180.855 754.233C180.855 754.233 199.876 756.595 207.076 745.749C207.076 745.749 197.814 676.249 191.662 655.057C180.871 617.962 167.819 595.688 164.211 577.439C160.619 559.191 164.992 450.101 162.299 401.062L84.7491 409.779Z"
          fill="#DDDDDD"
        />
        <path
          d="M84.7492 409.778C84.7492 409.778 85.2646 484.036 85.0983 510.95C84.9321 537.849 82.3382 575.011 79.7277 589.882C77.1172 604.753 73.8749 613.67 73.8749 618.128C73.8749 622.586 76.4854 666.451 77.1339 679.826C77.7823 693.2 77.1339 758.624 77.1339 758.624C77.1339 758.624 61.5209 764.563 49.7987 758.624C49.7987 758.624 31.8412 667.932 31.5752 659.016C30.96 638.838 36.1311 593.592 35.4826 582.446C34.8341 571.301 12.5536 450.4 9.95977 427.361C7.3493 404.322 8.16403 387.405 10.7579 363.9L84.7492 409.778Z"
          fill="#DDDDDD"
        />
        <path
          d="M162.232 399.232C159.721 373.465 153.17 357.778 144.99 333.009L141.232 314.412C141.232 314.412 78.3144 324.409 30.378 309.172C30.378 309.172 4.45612 353.603 4.90506 393.975C5.12121 413.787 20.0525 485.45 27.1191 519.251C48.3022 519.717 61.1551 522.728 80.3595 531.694C80.3096 498.292 83.9344 422.487 84.4997 409.928C90.4523 387.754 97.7516 471.643 104.12 504.862C126.7 498.242 137.607 487.479 161.018 484.102C161.035 471.293 162.93 406.285 162.232 399.232Z"
          fill="#DDDDDD"
        />
        <path
          d="M45.9411 330.198C45.9411 330.198 84.3501 335.588 85.4641 335.97C86.5782 336.353 86.6447 344.787 83.8845 344.72C80.8251 344.654 45.6917 340.678 43.5801 340.978C43.5801 340.978 46.706 335.621 45.9411 330.198Z"
          fill="#DDDDDD"
        />
        <path
          d="M86.9606 333.907L116.441 333.791C116.441 333.791 116.158 341.227 117.255 346.034L86.0793 345.435L86.9606 333.907Z"
          fill="#DDDDDD"
        />
        <path
          d="M83.1029 323.777C84.7158 325.391 84.7158 328.019 83.1029 329.649C81.4901 331.279 78.8796 331.263 77.2668 329.649C75.6539 328.036 75.6539 325.407 77.2668 323.777C78.8796 322.164 81.4901 322.164 83.1029 323.777Z"
          fill="#DDDDDD"
        />
        <path
          d="M83.0198 326.206C83.0198 328.135 81.4569 329.699 79.5281 329.699C77.5993 329.699 76.0364 328.135 76.0364 326.206C76.0364 324.276 77.5993 322.696 79.5281 322.696C81.4569 322.696 83.0198 324.259 83.0198 326.206Z"
          fill="#DDDDDD"
        />
        <path
          d="M76.6516 326.206C76.6516 326.206 76.7015 328.984 79.179 329.15C81.6564 329.316 83.5353 326.488 81.7063 324.193C81.7063 324.193 82.2217 327.27 80.1932 327.852C78.4141 328.368 77.0174 327.47 76.6682 326.189L76.6516 326.206Z"
          fill="#DDDDDD"
        />
        <path
          d="M116.441 333.791C116.441 333.791 115.609 347.581 124.771 353.437C133.916 359.292 153.652 358.893 153.652 358.893L144.99 333.009L116.457 333.791H116.441Z"
          fill="#DDDDDD"
        />
        <path
          d="M162.714 410.86C162.714 410.86 135.662 421.739 126.517 417.381C117.372 413.022 107.296 317.406 107.296 317.406C107.296 317.406 124.388 317.09 140.783 313.297L162.382 390.582L162.714 410.86Z"
          fill="#DDDDDD"
        />
        <path
          d="M23.4944 322.546C32.1572 327.82 46.5564 330.099 46.5564 330.099C46.5564 330.099 44.3117 347.731 31.8412 349.345C19.3708 350.958 12.9194 348.896 12.9194 348.896C12.9194 348.896 18.4064 332.444 23.4944 322.546Z"
          fill="#DDDDDD"
        />
        <path
          d="M98.0842 395.539C98.0842 395.539 140.002 386.556 159.356 393.643L98.0842 395.539Z"
          fill="#DDDDDD"
        />
        <path
          d="M84.084 542.523C82.7705 534.239 55.3687 397.552 45.991 385.791C45.991 385.791 60.3902 399.232 101.609 396.387C101.609 396.387 101.509 454.043 104.12 507.407C104.12 507.407 90.2028 406.185 84.7491 409.778C85.7135 428.659 85.7135 517.987 84.084 542.523Z"
          fill="#DDDDDD"
        />
        <path
          d="M85.1315 503.931C85.1315 503.931 85.8797 428.409 84.7491 409.778C89.5544 408.115 100.695 483.836 100.695 483.836C100.695 483.836 94.8584 420.425 94.3264 409.562C94.1934 407.017 95.0746 404.572 94.5093 404.572C93.9439 404.572 78.9794 404.089 67.1741 399.215C67.1741 399.215 74.5399 408.83 75.4877 410.693C76.4188 412.573 85.1481 503.897 85.1481 503.897L85.1315 503.931Z"
          fill="#DDDDDD"
        />
        <path
          d="M5.78627 405.952C5.78627 405.952 46.6893 421.739 55.8343 417.38C64.9793 413.022 75.1053 316.924 75.1053 316.924C75.1053 316.924 57.7464 316.009 41.5681 313.314L4.90503 395.789L5.78627 405.952Z"
          fill="#DDDDDD"
        />
        <path
          d="M86.3453 317.489C86.3453 317.489 87.5425 327.121 87.3928 328.152C87.2432 329.183 87.0769 334.839 85.8132 335.039L84.5495 335.238C84.5495 335.238 83.4521 348.263 83.4521 361.588C83.4521 374.912 86.3453 387.222 86.5947 389.717C86.7776 384.876 86.1291 363.168 86.2788 361.837C86.4284 360.523 86.5947 337.102 88.008 336.153C89.4213 335.205 89.2717 332.394 89.2717 332.394L89.5211 317.589L86.3287 317.473L86.3453 317.489Z"
          fill="#DDDDDD"
        />
        <path
          d="M25.1737 323.877C25.1737 323.877 43.3972 329.616 46.573 330.098C49.7488 330.581 62.8511 331.845 77.3667 334.673C77.3667 334.673 49.7322 333.542 46.573 333.009C43.3972 332.46 25.1737 323.877 25.1737 323.877Z"
          fill="#DDDDDD"
        />
        <path
          d="M86.1957 333.908C86.1957 333.908 115.343 332.81 115.842 333.01C116.358 333.209 138.439 331.296 144.973 333.01C144.973 333.01 119.999 335.372 115.842 335.521C111.685 335.654 86.1957 333.908 86.1957 333.908Z"
          fill="#DDDDDD"
        />
        <path
          d="M116.358 334.856C116.358 334.856 116.923 343.789 120.847 348.613C124.771 353.437 132.868 358.56 153.636 358.893C153.636 358.893 136.127 363.883 123.773 356.631C113.415 350.559 116.341 334.856 116.341 334.856H116.358Z"
          fill="#DDDDDD"
        />
        <path
          d="M46.2903 330.863C46.2903 330.863 45.0599 338.898 41.1525 343.722C37.2285 348.546 25.2069 350.076 12.936 348.895C12.936 348.895 27.4516 355.765 38.3258 349.644C48.801 343.772 46.2903 330.863 46.2903 330.863Z"
          fill="#DDDDDD"
        />
        <path
          d="M72.9271 316.84C72.9271 316.84 86.3952 317.173 87.8917 317.606C89.3881 318.038 88.7064 328.368 88.6399 330.015C88.5734 331.662 88.6399 337.234 86.2289 336.852C83.8013 336.469 77.3832 334.656 77.3832 334.656C77.3832 334.656 83.8346 334.906 85.3477 333.924C86.8608 332.943 87.0437 319.901 86.1125 319.918C85.1814 319.934 72.9271 316.84 72.9271 316.84Z"
          fill="#DDDDDD"
        />
        <path
          d="M84.4332 336.885C84.4332 336.885 83.7182 353.254 83.9011 355.4C84.084 357.546 86.6945 390.915 86.6945 390.915C86.6945 390.915 84.5994 377.707 83.8678 375.96C83.1362 374.213 81.7395 353.62 81.6065 351.84C81.4735 350.06 84.4332 336.885 84.4332 336.885Z"
          fill="#DDDDDD"
        />
        <path
          d="M100.512 317.589C100.512 317.589 102.191 321.798 102.191 323.095C102.191 324.393 103.688 342.192 103.688 342.857C103.688 343.522 104.619 345.435 104.619 345.435L105.932 321.066L103.87 317.556L100.528 317.589H100.512Z"
          fill="#DDDDDD"
        />
        <path
          d="M108.942 333.009H113.631L111.452 317.223L109.773 317.29L108.942 333.009Z"
          fill="#DDDDDD"
        />
        <path
          d="M50.4138 314.046L51.8604 314.295L51.8271 331.047L46.0741 330.098L50.4138 314.046Z"
          fill="#DDDDDD"
        />
        <path
          d="M57.9128 315.244L60.3736 315.576L56.6824 328.967L56.6491 336.885L54.6372 340.978L51.7939 337.617L57.9128 315.244Z"
          fill="#DDDDDD"
        />
        <path
          d="M58.2619 315.293C58.2619 315.293 56.0339 320.084 55.9009 321.465C55.7679 322.846 53.3735 331.396 54.6372 340.977L48.0528 340.628C48.0528 340.628 47.9198 331.146 48.9008 327.221C49.8818 323.278 51.8272 314.312 51.8272 314.312L58.2619 315.31V315.293Z"
          fill="#DDDDDD"
        />
        <path
          d="M58.212 315.31C58.212 315.31 54.4542 327.736 54.5207 330.098C54.5872 332.46 54.6371 340.977 54.6371 340.977L48.0527 340.628L51.7939 337.617C51.7939 337.617 53.2404 327.67 53.1739 326.788C53.1074 325.906 58.2286 315.31 58.2286 315.31H58.212Z"
          fill="#DDDDDD"
        />
        <path
          d="M102.79 317.556L109.773 317.29C109.773 317.29 111.436 325.324 111.585 330.647C111.768 336.918 112.167 344.371 112.167 345.402C111.719 345.186 104.602 345.402 104.602 345.402C104.602 345.402 105.45 330.98 105.167 326.904C104.885 322.829 102.79 317.539 102.79 317.539V317.556Z"
          fill="#DDDDDD"
        />
        <path
          d="M102.79 317.556C102.79 317.556 105.3 329.117 104.918 334.856C104.502 341.144 104.602 345.435 104.602 345.435H112.167C112.167 345.435 106.098 345.219 106.281 342.973C106.464 340.728 108.576 324.609 102.79 317.572V317.556Z"
          fill="#DDDDDD"
        />
        <path
          d="M73.5756 335.97C73.5756 335.97 73.9913 368.674 73.8417 371.153C73.692 373.648 73.2098 387.022 85.0152 389.9C85.0152 389.9 72.7276 390.515 71.7965 379.553C70.8488 368.591 73.5756 335.97 73.5756 335.97Z"
          fill="#DDDDDD"
        />
        <path
          d="M160.054 401.079C160.054 401.079 147.899 394.658 119.35 400.313L160.054 401.079Z"
          fill="#DDDDDD"
        />
        <path
          d="M71.3476 564.365C71.1813 558.942 59.4591 471.676 53.0243 425.698C39.656 423.519 26.5371 419.992 15.1475 412.34C21.9314 450.999 42.2499 563.649 44.1786 571.584C49.4162 593.276 71.9129 583.511 71.3476 564.348V564.365Z"
          fill="#DDDDDD"
        />
        <path
          d="M75.9865 593.941C75.9865 593.941 72.2454 605.851 43.9292 609.278C43.9292 609.278 83.0365 607.165 75.9865 593.941Z"
          fill="#DDDDDD"
        />
        <path
          d="M49.0504 613.819C49.0504 613.819 62.1693 613.038 71.3143 604.72C71.3143 604.72 76.6184 613.57 49.0504 613.819Z"
          fill="#DDDDDD"
        />
        <path
          d="M108.509 439.721C109.806 422.071 147.434 419.443 153.702 433.233C159.971 447.04 154.883 554.35 150.66 573.014C144.491 600.245 123.74 592.011 122.543 579.419C119.35 545.551 105.749 477.049 108.509 439.721Z"
          fill="#DDDDDD"
        />
        <path
          d="M140.168 609.212C140.168 609.212 158.774 603.789 166.439 594.107C166.439 594.107 168.151 605.552 140.168 609.212Z"
          fill="#DDDDDD"
        />
        <path
          d="M162.432 585.474C162.432 585.474 159.971 593.791 145.339 597.733C145.339 597.733 165.574 595.621 162.432 585.474Z"
          fill="#DDDDDD"
        />
        <path
          d="M127.115 615.865C127.115 615.865 143.876 644.211 148.431 648.103C152.987 651.996 185.643 750.24 185.643 750.24L179.375 749.142C179.375 749.142 148.182 664.954 144.591 657.269C140.999 649.584 130.008 623.817 127.115 615.865Z"
          fill="#DDDDDD"
        />
        <path
          d="M73.8749 618.128C73.8749 618.128 80.3097 710.833 77.1339 758.624L72.8274 759.905C72.8274 759.905 71.1314 662.559 69.2193 643.28C68.4877 635.827 73.8749 618.128 73.8749 618.128Z"
          fill="#DDDDDD"
        />
        <path
          d="M77.5994 749.658C77.5994 749.658 80.1434 755.397 71.8796 759.04C63.6159 762.683 54.6871 761.785 53.1574 760.986C51.6277 760.188 49.5825 757.593 48.8842 754.033C48.8842 754.033 49.3331 757.593 54.6871 758.208C60.041 758.841 68.7537 757.377 72.1291 755.347C74.8559 753.717 77.2336 751.738 77.5994 749.642V749.658Z"
          fill="#DDDDDD"
        />
        <path
          d="M48.9341 754.249C48.9341 754.249 50.2476 759.855 56.7156 759.855C63.1836 759.855 76.7681 758.342 77.5995 749.658C77.5995 749.658 79.1125 752.087 79.3121 757.343C79.5282 762.6 66.9081 766.908 61.0553 766.675C56.0339 766.476 47.3046 766.675 48.9175 754.249H48.9341Z"
          fill="#DDDDDD"
        />
        <path
          d="M177.695 744.585C177.695 744.585 178.76 753.418 186.641 752.087C194.522 750.773 205.513 745.516 206.095 738.58C206.095 738.58 204.05 745.749 186.574 748.145C180.672 748.96 177.695 744.585 177.695 744.585Z"
          fill="#DDDDDD"
        />
        <path
          d="M177.695 744.585C177.695 744.585 179.242 749.775 185.66 750.241C192.078 750.69 205.297 744.618 206.095 738.58C206.095 738.58 209.603 746.597 206.826 749.791C204.05 752.985 196.118 758.757 186.94 757.543C180.04 756.628 178.161 751.605 177.961 749.326C177.745 747.047 177.695 744.585 177.695 744.585Z"
          fill="#DDDDDD"
        />
        <path
          d="M177.446 803.138C177.446 803.138 176.166 815.581 176.166 817.677C176.166 819.773 179.574 826.543 185.144 827.541C189.883 828.39 208.639 828.839 213.378 826.36C215.905 825.046 218.233 820.106 218.233 818.159C218.233 814.4 218.233 808.794 218.233 808.794L177.446 803.122V803.138Z"
          fill="#DDDDDD"
        />
        <path
          d="M209.337 766.359C209.337 766.359 213.012 784.441 213.394 787.518C213.76 790.596 217.817 799.811 218.183 805.517C218.549 811.223 217.518 815.914 209.487 818.026C200.674 820.339 187.921 819.074 183.033 816.18C179.375 814.001 177.33 809.16 177.696 801.774C178.061 794.389 182.484 788.433 183.033 762.882C183.033 762.882 185.427 755.147 197.199 755.696C208.971 756.245 209.337 766.359 209.337 766.359Z"
          fill="#DDDDDD"
        />
        <path
          d="M207.209 764.363C206.677 763.498 206.86 761.751 204.266 759.755C200.425 756.811 190.648 758.408 189.517 759.107C188.337 759.838 185.261 762.433 184.795 762.849C184.33 763.265 189.019 785.29 187.223 795.42C186.924 797.084 210.717 796.734 210.817 795.902C210.917 795.071 205.629 771.1 205.529 769.204C205.43 767.307 207.109 764.812 207.209 764.346V764.363Z"
          fill="#DDDDDD"
        />
        <path
          d="M204.731 766.259C204.731 766.259 203.551 765.278 203.019 765.644C202.487 766.01 200.026 771.399 200.026 771.399C200.026 771.399 205.529 789.265 211.033 799.479C210.917 797 208.572 789.016 208.473 788.4C208.373 787.785 204.731 766.259 204.731 766.259Z"
          fill="#DDDDDD"
        />
        <path
          d="M209.304 767.34C209.304 767.34 203.917 765.261 203.501 764.895C203.069 764.529 201.206 768.422 201.206 771.133C201.206 773.845 211.033 799.462 211.033 799.462L209.321 767.324L209.304 767.34Z"
          fill="#DDDDDD"
        />
        <path
          d="M189.135 764.896L189.95 765.212C189.95 765.212 190.615 770.817 190.415 771.633C190 773.396 189.102 790.979 186.125 799.679L189.135 764.896Z"
          fill="#DDDDDD"
        />
        <path
          d="M182.983 765.245C182.983 765.245 189.567 764.03 189.883 764.28C190.199 764.529 189.451 769.653 189.451 771.017C189.451 772.381 187.09 799.961 185.494 801.325C183.897 802.673 182.966 765.261 182.966 765.261L182.983 765.245Z"
          fill="#DDDDDD"
        />
        <path
          d="M207.209 787.718C201.689 791.245 195.104 791.644 189.75 795.57C188.354 796.601 187.073 794.039 188.453 793.024C193.824 789.082 200.392 788.699 205.912 785.173C207.359 784.258 208.655 786.803 207.209 787.718Z"
          fill="#DDDDDD"
        />
        <path
          d="M208.439 796.335C205.031 794.704 201.639 792.891 198.164 791.511C195.254 790.363 192.111 790.263 189.368 788.633C187.905 787.751 189.201 785.206 190.665 786.088C193.508 787.784 197.099 787.884 200.126 789.215C203.168 790.546 206.145 792.06 209.138 793.49C210.634 794.205 209.986 797.067 208.456 796.335H208.439Z"
          fill="#DDDDDD"
        />
        <path
          d="M203.667 780.016C200.126 783.343 196.8 786.071 192.244 787.252C190.632 787.668 189.95 784.824 191.563 784.408C195.686 783.343 198.646 780.948 201.855 777.937C203.119 776.739 204.948 778.819 203.667 780.033V780.016Z"
          fill="#DDDDDD"
        />
        <path
          d="M204.332 787.302C202.005 785.688 199.411 785.156 196.9 784.075C194.689 783.11 192.876 781.28 190.681 780.365C189.135 779.717 189.8 776.872 191.363 777.521C193.874 778.569 195.869 780.565 198.413 781.563C200.89 782.528 203.368 783.193 205.629 784.757C207.043 785.738 205.762 788.3 204.332 787.302Z"
          fill="#DDDDDD"
        />
        <path
          d="M201.389 772.514C199.81 773.861 198.28 774.809 196.468 775.624C194.639 776.456 193.109 778.003 191.18 778.535C189.584 778.968 188.886 776.123 190.498 775.691C192.161 775.242 193.541 773.811 195.137 773.063C196.966 772.214 198.513 771.333 200.109 769.969C201.439 768.837 202.719 771.399 201.406 772.514H201.389Z"
          fill="#DDDDDD"
        />
        <path
          d="M202.47 778.852C198.43 777.671 194.689 775.675 191.313 772.847C189.983 771.732 191.263 769.17 192.61 770.302C195.819 772.996 199.311 774.876 203.152 776.007C204.748 776.473 204.066 779.318 202.47 778.852Z"
          fill="#DDDDDD"
        />
        <path
          d="M211.116 795.52C206.91 796.834 202.636 798.165 198.247 798.131C193.924 798.115 189.617 796.801 185.394 795.852C183.781 795.486 184.463 792.642 186.076 793.008C190.083 793.906 194.157 795.17 198.247 795.187C202.42 795.204 206.444 793.923 210.435 792.675C212.031 792.176 212.713 795.021 211.116 795.52Z"
          fill="#DDDDDD"
        />
        <path
          d="M207.807 787.319C203.767 788.134 199.71 788.932 195.603 788.6C192.876 788.367 189.202 789.132 186.807 787.585C185.377 786.653 186.658 784.092 188.104 785.04C189.285 785.805 190.548 785.256 191.795 785.256C193.309 785.256 194.822 785.605 196.335 785.688C199.976 785.905 203.551 785.206 207.109 784.474C208.722 784.141 209.42 787.003 207.791 787.319H207.807Z"
          fill="#DDDDDD"
        />
        <path
          d="M204.864 778.702C199.294 780.565 193.658 781.829 188.237 778.735C186.757 777.887 188.054 775.342 189.534 776.19C194.273 778.902 199.361 777.471 204.183 775.858C205.762 775.325 206.444 778.17 204.864 778.702Z"
          fill="#DDDDDD"
        />
        <path
          d="M189.584 769.021C189.584 769.021 190.415 764.779 189.883 764.297C189.351 763.798 182.983 765.261 182.983 765.261C182.983 765.261 188.703 764.646 189.135 764.895C189.567 765.145 189.584 769.021 189.584 769.021Z"
          fill="#DDDDDD"
        />
        <path
          d="M209.304 767.341C209.304 767.341 203.75 764.646 203.368 764.862C202.686 765.245 201.938 767.49 201.938 767.49C201.938 767.49 203.917 765.66 204.399 765.86C204.881 766.06 209.304 767.324 209.304 767.324V767.341Z"
          fill="#DDDDDD"
        />
        <path
          d="M202.919 772.314C200.325 771.948 197.748 771.782 195.138 771.882C192.926 771.965 190.864 772.148 188.719 771.399C187.14 770.85 187.822 768.006 189.401 768.555C191.646 769.337 193.89 768.937 196.185 768.904C198.446 768.871 200.691 769.054 202.936 769.37C204.549 769.603 204.565 772.564 202.936 772.331L202.919 772.314Z"
          fill="#DDDDDD"
        />
        <path
          d="M218.249 818.176C218.249 818.176 217.967 825.046 213.361 826.843C206.261 829.621 186.525 829.388 181.47 826.843C178.959 825.579 176.182 823.1 176.182 817.943C176.182 817.943 177.413 824.93 183.033 826.244C192.71 828.49 205.845 827.891 212.53 825.828C215.722 824.847 217.717 820.721 218.249 818.16V818.176Z"
          fill="#DDDDDD"
        />
        <path
          d="M218.183 808.229C218.183 808.229 217.701 813.718 213.743 815.781C207.275 819.141 186.674 820.073 180.938 813.918C178.078 810.857 177.679 807.497 177.695 801.758C177.695 801.758 175.168 812.188 181.603 816.313C187.339 819.989 205.912 821.004 213.943 817.411C219.081 815.115 218.199 808.229 218.199 808.229H218.183Z"
          fill="#DDDDDD"
        />
        <path
          d="M62.5351 144.921C62.5351 144.921 59.6253 151.06 64.1479 181.102C71.7798 231.854 72.0625 291.656 65.2121 327.42C57.231 369.057 53.9887 409.246 45.2594 409.213C36.5134 409.18 -0.332547 401.162 0.0166254 397.968C0.382425 394.774 9.76019 345.419 27.1357 307.758C36.0978 288.329 36.9956 268.284 36.1809 262.279L31.9576 231.072L13.4016 163.203L62.5351 144.921Z"
          fill="#DDDDDD"
        />
        <path
          d="M36.9457 235.664L69.5518 238.558L62.5184 175.296L34.2687 179.006C34.2687 179.006 32.4397 226.764 36.9291 235.68L36.9457 235.664Z"
          fill="#DDDDDD"
        />
        <path
          d="M119.833 144.921C119.833 144.921 122.742 151.06 118.22 181.102C110.588 231.854 110.305 291.656 117.156 327.42C125.137 369.057 128.379 409.246 137.108 409.213C145.854 409.18 174.985 401.162 174.636 397.968C174.27 394.774 164.294 362.936 152.123 313.281C147.035 292.488 145.355 268.284 146.17 262.262C146.985 256.257 152.505 229.841 152.505 229.841L168.95 163.186L119.816 144.905L119.833 144.921Z"
          fill="#DDDDDD"
        />
        <path
          d="M145.405 235.664L112.799 238.558L119.833 175.296L148.082 179.006C148.082 179.006 149.911 226.764 145.422 235.68L145.405 235.664Z"
          fill="#DDDDDD"
        />
        <path
          d="M118.685 263.477C118.685 263.477 124.023 262.794 124.422 262.512C124.804 262.229 134.415 245.844 134.415 245.844L136.892 255.492C136.892 255.492 126.035 269.282 124.405 269.215C122.776 269.132 117.488 268.5 117.488 268.5L118.669 263.493L118.685 263.477Z"
          fill="#DDDDDD"
        />
        <path
          d="M117.488 288.562V298.41C117.488 298.41 130.391 295.715 132.037 295.166C133.683 294.617 142.845 285.318 142.845 285.318L148.282 293.003L146.636 278.98L143.46 271.927L117.505 288.545L117.488 288.562Z"
          fill="#DDDDDD"
        />
        <path
          d="M152.505 207.85L152.139 215.702L138.339 220.093L120.913 223.936L121.263 216.217C121.263 216.217 149.595 209.48 152.488 207.85H152.505Z"
          fill="#DDDDDD"
        />
        <path
          d="M120.93 223.936C120.93 223.936 140.883 222.589 152.139 215.901C152.139 215.901 125.968 222.938 120.93 223.936Z"
          fill="#DDDDDD"
        />
        <path
          d="M121.279 216.217C121.279 216.217 138.189 213.506 152.505 207.85L121.279 216.217Z"
          fill="#DDDDDD"
        />
        <path
          d="M31.1262 296.413C31.1262 296.413 28.1499 305.496 27.119 307.758C27.119 307.758 55.6348 306.294 65.9603 304.498L66.8249 294.417L31.1096 296.413H31.1262Z"
          fill="#DDDDDD"
        />
        <path
          d="M188.586 267.702C188.586 267.702 182.667 241.136 178.527 213.206C175.185 190.617 175.783 166.047 168.95 163.219C157.261 185.16 153.736 225.749 153.736 225.749C153.736 225.749 172.824 266.238 188.57 267.718L188.586 267.702Z"
          fill="#DDDDDD"
        />
        <path
          d="M41.485 259.052L42.2 263.676L63.4662 265.739V261.431L41.485 259.052Z"
          fill="#DDDDDD"
        />
        <path
          d="M185.261 266.321C185.261 266.321 180.572 250.452 178.111 245.544C176.249 241.868 133.899 177.143 133.899 177.143L118.053 182.316L185.261 266.321Z"
          fill="#DDDDDD"
        />
        <path
          d="M148.232 264.991L139.553 225.999L129.061 208.898C128.745 213.922 125.253 224.585 123.707 229.11C129.56 246.925 139.719 274.805 139.719 274.805L148.232 264.991Z"
          fill="#DDDDDD"
        />
        <path
          d="M53.1574 150.394L42.4661 179.006L55.6182 181.235L45.2428 187.057C45.2428 187.057 60.623 235.098 61.2216 246.376C61.8036 257.654 66.7751 311.068 62.8677 337.451C62.8677 337.451 72.2621 231.089 61.2216 180.952L53.1574 150.377V150.394Z"
          fill="#DDDDDD"
        />
        <path
          d="M66.8416 138.217C66.8416 138.217 55.6348 141.777 51.8271 157.081C48.0195 172.385 46.1406 177.525 46.1406 177.525L58.1289 180.054L48.2523 184.079C48.2523 184.079 60.9555 228.826 63.5826 244.646C68.6207 274.971 62.851 337.484 62.851 337.484C62.851 337.484 73.6587 277.965 71.015 241.535C68.5874 208.1 64.3308 160.242 62.9341 154.852C60.6728 146.152 66.8582 138.234 66.8582 138.234L66.8416 138.217Z"
          fill="#DDDDDD"
        />
        <path
          d="M129.21 150.394L139.902 179.006L126.75 181.235L137.125 187.057C137.125 187.057 121.745 235.098 121.146 246.376C120.548 257.654 115.593 311.068 119.5 337.451C119.5 337.451 110.106 231.089 121.146 180.952L129.21 150.377V150.394Z"
          fill="#DDDDDD"
        />
        <path
          d="M116.025 138.217C116.025 138.217 126.716 141.777 130.524 157.081C134.332 172.385 136.211 177.525 136.211 177.525L124.222 180.054L134.099 184.079C134.099 184.079 121.396 228.826 118.769 244.646C113.73 274.971 119.5 337.484 119.5 337.484C119.5 337.484 108.692 277.965 111.336 241.535C113.764 208.1 118.02 160.242 119.417 154.852C121.678 146.152 116.025 138.234 116.025 138.234V138.217Z"
          fill="#DDDDDD"
        />
        <path
          d="M52.0433 320.583C51.7772 322.979 53.257 325.108 55.3521 325.341C57.4471 325.574 59.3759 323.827 59.6419 321.432C59.9079 319.036 58.4281 316.907 56.3331 316.674C54.2381 316.441 52.3093 318.188 52.0433 320.583Z"
          fill="#DDDDDD"
        />
        <path
          d="M58.6277 320.949C58.6277 322.862 57.2476 324.426 55.5516 324.426C53.8556 324.426 52.4756 322.879 52.4756 320.949C52.4756 319.02 53.8556 317.473 55.5516 317.473C57.2476 317.473 58.6277 319.02 58.6277 320.949Z"
          fill="#DDDDDD"
        />
        <path
          d="M115.892 187.14C115.377 192.114 114.529 202.544 114.063 207.218C117.555 216.401 120.282 221.258 122.443 227.862C124.006 223.337 126.733 210.944 128.379 204.607L115.892 187.157V187.14Z"
          fill="#DDDDDD"
        />
        <path
          d="M155.249 207.019C164.377 227.612 175.783 252.198 175.783 252.198L173.705 265.057L153.885 246.709L155.249 207.019Z"
          fill="#DDDDDD"
        />
        <path
          d="M153.885 246.709C153.885 246.709 150.56 280.727 150.31 286.266C150.31 286.266 147.633 242.55 147.633 239.24C147.633 235.93 153.403 198.501 154.6 195.94C155.797 193.378 154.816 229.309 155.714 231.787L153.885 246.692V246.709Z"
          fill="#DDDDDD"
        />
        <path
          d="M105.384 266.936C105.384 266.936 124.921 266.936 125.27 266.936C125.619 266.936 134.564 252.248 134.564 252.248L142.246 276.535C142.246 276.535 133.267 286.133 128.662 286.582C124.039 287.031 103.255 289.343 103.255 289.343L105.4 266.953L105.384 266.936Z"
          fill="#DDDDDD"
        />
        <path
          d="M134.415 252.531C134.415 252.531 126.151 265.839 123.823 266.321C123.225 266.454 107.927 266.887 107.927 266.887C107.927 266.887 123.141 269.765 125.453 269.116C127.764 268.467 134.398 252.531 134.398 252.531H134.415Z"
          fill="#DDDDDD"
        />
        <path
          d="M91.6993 290.442C108.742 287.78 123.707 286.033 128.795 284.303C133.883 282.573 143.726 274.023 144.524 271.927L146.054 275.72C146.054 275.72 138.472 285.601 130.424 287.763C122.925 288.329 116.125 289.909 109.923 291.307C105.749 292.238 99.564 293.136 92.3811 294.268C79.5282 296.297 77.5329 292.654 91.6993 290.442Z"
          fill="#DDDDDD"
        />
        <path
          d="M119.766 266.521L120.814 266.471L127.198 288.079L119.766 289.21V266.521Z"
          fill="#DDDDDD"
        />
        <path
          d="M13.4016 163.203C13.4016 163.203 36.4636 178.789 36.8127 185.959C37.1785 193.112 39.0741 223.869 39.0741 227.812C39.0741 231.754 39.789 248.572 39.789 261.447C39.789 261.447 71.7466 268.783 119.766 266.537C120.564 272.126 124.405 293.552 124.405 293.552C124.405 293.552 86.1292 299.258 60.3736 300.405C35.5657 301.503 13.3184 300.821 9.74358 299.041C6.16872 297.245 4.0238 266.837 4.37297 259.684C4.73877 252.531 7.23286 225.699 8.31363 218.18C9.3944 210.661 3.67463 176.411 13.4016 163.253V163.203Z"
          fill="#DDDDDD"
        />
        <path
          d="M36.9458 200.914C36.9458 200.914 42.0504 224.651 42.5492 225.915C42.8318 226.614 40.0717 247.091 44.4114 267.885C46.1573 276.285 40.0717 268.434 40.0717 268.434L36.9458 200.93V200.914Z"
          fill="#DDDDDD"
        />
        <path
          d="M148.515 155.601L168.966 163.203C168.966 163.203 174.653 162.404 177.429 191.748C180.705 226.298 182.617 237.842 182.617 237.842C182.617 237.842 166.356 178.606 164.826 175.296C160.902 166.829 148.498 155.584 148.498 155.584L148.515 155.601Z"
          fill="#DDDDDD"
        />
        <path
          d="M16.9432 161.889C16.9432 161.889 14.7816 162.338 13.4015 163.203C11.5892 164.351 7.11645 173.782 7.63189 193.445C8.13071 212.358 8.33024 218.131 8.33024 218.131C8.33024 218.131 18.8553 194.376 18.8719 180.752C18.8719 167.112 16.9598 161.889 16.9598 161.889H16.9432Z"
          fill="#DDDDDD"
        />
        <path
          d="M136.659 247.923L133.184 254.461L141.598 280.627C141.598 280.627 145.455 277.234 146.104 275.138L136.676 247.923H136.659Z"
          fill="#DDDDDD"
        />
        <path
          d="M118.685 208.915C118.685 208.915 125.536 193.877 138.106 191.748C138.106 191.748 131.921 210.628 118.685 208.915Z"
          fill="#DDDDDD"
        />
        <path
          d="M4.373 259.651C4.373 259.651 2.94306 295.781 9.7436 299.009C16.5442 302.236 40.5872 301.67 52.4756 300.672C52.4756 300.672 32.6393 296.147 22.2639 290.774C11.8885 285.401 4.38963 269.299 4.38963 259.651H4.373Z"
          fill="#DDDDDD"
        />
        <path
          d="M39.789 261.431L37.6774 215.885C37.6774 215.885 35.2165 255.226 24.8744 265.556C24.8744 265.556 37.5942 261.88 39.789 261.414V261.431Z"
          fill="#DDDDDD"
        />
        <path
          d="M119.766 266.521C119.766 266.521 54.0718 265.523 39.789 261.431C39.789 261.431 15.8458 264.525 23.0953 269.765C30.3448 275.005 105.733 272.127 105.733 272.127L119.766 266.521Z"
          fill="#DDDDDD"
        />
        <path
          d="M170.546 244.862L131.721 182.599C131.721 182.599 121.263 154.586 120.814 153.671C121.695 156.831 128.529 181.035 130.491 184.229C130.873 184.844 163.479 239.157 170.546 244.862Z"
          fill="#DDDDDD"
        />
        <path
          d="M133.966 173.383C133.966 173.383 132.686 163.835 132.303 162.854C131.921 161.889 117.438 136.771 116.873 135.323C116.308 133.859 113.997 127.888 112.35 129.235C110.721 130.582 109.939 135.124 112.184 138.916C112.184 138.916 115.51 147.566 117.538 149.779L122.41 164.983L133.949 173.4L133.966 173.383Z"
          fill="#DDDDDD"
        />
        <path
          d="M112.816 128.752C112.816 128.752 111.818 129.335 110.954 131.331C109.258 135.223 114.479 146.418 117.372 149.745C117.372 149.745 120.864 162.155 122.144 164.484L124.073 164.334C124.073 164.334 119.317 150.81 118.902 149.446C118.486 148.065 108.776 133.876 112.816 128.752Z"
          fill="#DDDDDD"
        />
        <path
          d="M107.462 190.301C107.761 189.319 110.571 189.419 112.616 190.783C113.564 191.399 112.051 192.547 112.051 192.547C112.051 192.547 109.341 191.914 107.462 190.318V190.301Z"
          fill="#DDDDDD"
        />
        <path
          d="M107.861 164.816C107.861 164.816 103.255 163.669 101.942 163.685C100.612 163.719 99.4643 165.898 100.545 167.162C101.626 168.426 105.45 169.225 105.45 169.225L107.861 164.816Z"
          fill="#DDDDDD"
        />
        <path
          d="M116.624 169.524C126.217 178.39 136.809 196.938 136.809 196.938C136.809 196.938 133.168 169.391 132.253 167.511C131.322 165.632 120.165 162.138 119.001 162.088C118.27 162.055 107.645 160.575 106.265 161.09C104.901 161.606 100.927 169.557 100.778 170.722C100.645 171.886 102.873 177.159 103.854 177.159C104.835 177.159 107.296 172.485 106.597 170.705C106.597 170.705 108.942 168.46 109.441 166.896C110.987 168.742 115.21 169.325 116.607 169.524H116.624Z"
          fill="#DDDDDD"
        />
        <path
          d="M113.132 128.47C111.702 128.919 113.115 131.63 115.044 134.292C115.925 135.506 116.657 134.841 116.657 134.841C116.657 134.841 115.41 130.898 113.132 128.47Z"
          fill="#DDDDDD"
        />
        <path
          d="M101.593 173.616C101.593 173.616 100.645 171.67 100.778 170.622C100.894 169.574 104.901 161.273 106.398 160.957C107.894 160.658 115.659 161.689 115.659 161.689C115.659 161.689 108.194 161.606 106.747 161.939C105.3 162.271 101.775 169.458 101.509 170.622C101.31 171.487 101.593 173.6 101.593 173.6V173.616Z"
          fill="#DDDDDD"
        />
        <path
          d="M105.45 170.406C105.45 170.406 107.994 168.459 109.158 165.582C109.158 165.582 127.365 167.212 127.598 170.955L115.277 169.657C115.277 169.657 110.654 168.409 109.657 167.312C109.657 167.312 107.595 170.372 106.414 170.821L105.45 170.406Z"
          fill="#DDDDDD"
        />
        <path
          d="M138.073 200.165C138.073 200.165 130.208 173.284 129.842 172.868C129.476 172.452 126.883 168.776 122.493 168.559C118.103 168.343 105.151 169.824 104.02 170.439C102.89 171.038 102.008 176.378 102.025 176.927C102.042 177.476 102.025 184.396 102.474 185.527C102.906 186.658 104.103 186.325 104.885 185.311C105.666 184.279 107.678 176.411 107.678 176.411C107.678 176.411 113.215 175.479 114.329 175.746C115.21 175.962 122.21 175.912 122.21 175.912L138.073 200.182V200.165Z"
          fill="#DDDDDD"
        />
        <path
          d="M137.291 197.52C135.396 184.895 133.201 166.929 132.735 165.083C132.353 163.619 132.237 170.156 131.505 174.681C130.773 179.189 135.645 195.441 137.291 197.52Z"
          fill="#DDDDDD"
        />
        <path
          d="M102.091 180.22C103.372 179.804 104.403 181.95 104.319 184.961C104.286 186.342 103.355 186.957 102.707 186.841C102.208 186.757 101.958 183.863 102.108 180.22H102.091Z"
          fill="#DDDDDD"
        />
        <path
          d="M102.141 175.978C102.141 175.978 102.923 171.071 103.771 170.406C104.619 169.74 115.21 168.892 115.21 168.892C115.21 168.892 105.733 170.273 104.752 171.021C103.771 171.77 102.158 175.962 102.158 175.962L102.141 175.978Z"
          fill="#DDDDDD"
        />
        <path
          d="M106.148 182.915C106.148 182.915 107.878 178.989 107.795 176.644C107.795 176.644 122.676 177.126 124.056 176.677C124.056 176.677 124.322 172.219 106.514 175.496C106.514 175.496 105.101 181.95 106.148 182.931V182.915Z"
          fill="#DDDDDD"
        />
        <path
          d="M134.714 235.897C134.714 235.897 125.652 211.078 124.987 210.396C124.339 209.714 119.966 206.52 119.65 205.721C119.334 204.906 117.106 192.048 116.225 186.259C116.225 186.259 113.548 183.913 112.833 183.081C112.833 183.081 110.538 183.747 109.624 183.897C109.624 183.897 110.305 185.677 110.289 186.608C110.289 186.608 113.664 189.236 113.514 190.401C113.381 191.565 110.255 193.096 106.498 189.486C102.757 185.876 102.507 179.688 102.823 179.006C103.156 178.324 112.251 175.496 116.956 175.047C121.678 174.581 124.123 175.346 124.438 176.145C124.754 176.96 137.424 198.518 138.04 200.149C138.671 201.762 148.398 219.794 148.398 219.794L134.681 235.863L134.714 235.897Z"
          fill="#DDDDDD"
        />
        <path
          d="M116.225 186.275C116.225 186.275 118.37 205.688 120.465 207.285C124.655 210.479 125.769 209.98 127.448 208.3C129.127 206.62 117.505 187.124 116.225 186.275Z"
          fill="#DDDDDD"
        />
        <path
          d="M108.875 176.793C108.875 176.793 102.89 177.975 102.574 179.222C102.258 180.453 103.505 185.776 104.935 187.556C104.935 187.556 102.823 181.301 103.422 179.705C104.02 178.108 108.875 176.777 108.875 176.777V176.793Z"
          fill="#DDDDDD"
        />
        <path
          d="M113.148 191.066C113.148 191.066 114.562 190.201 111.951 187.905C110.837 186.924 110.338 186.558 110.338 186.558C110.338 186.558 110.139 184.795 109.623 183.913C109.623 183.913 111.669 183.73 112.833 183.098C112.833 183.098 114.977 185.809 116.225 186.275C116.225 186.275 112.966 183.214 113.099 181.501C113.099 181.501 109.806 183.148 108.476 182.948C108.476 182.948 109.657 186.092 109.008 186.774C109.008 186.774 113.481 189.419 113.132 191.066H113.148Z"
          fill="#DDDDDD"
        />
        <path
          d="M114.379 176.943C116.025 175.745 118.07 175.246 120.298 175.379C122.526 175.512 124.389 176.444 121.063 178.606C117.738 180.769 112.733 178.141 114.379 176.943Z"
          fill="#DDDDDD"
        />
        <path
          d="M120.864 169.042C123.723 168.875 127.481 168.493 126.982 170.406C126.5 172.202 123.507 173.899 120.614 172.851C117.721 171.803 117.206 169.258 120.864 169.042Z"
          fill="#DDDDDD"
        />
        <path
          d="M123.275 163.569C123.275 163.569 130.474 164.284 129.876 166.181C129.277 168.077 126.068 168.476 122.327 167.146C118.586 165.815 120.564 163.353 123.275 163.552V163.569Z"
          fill="#DDDDDD"
        />
        <path
          d="M117.954 137.386C117.954 137.386 121.529 145.836 122.16 146.967C122.792 148.098 130.507 161.29 132.303 162.82C132.303 162.82 125.054 148.265 123.491 146.319C121.928 144.372 117.97 137.402 117.97 137.402L117.954 137.386Z"
          fill="#DDDDDD"
        />
        <path
          d="M121.113 205.471L124.405 212.042L138.488 193.461L137.009 191.099C137.009 191.099 126.384 204.241 121.113 205.471Z"
          fill="#DDDDDD"
        />
        <path
          d="M188.586 267.702C174.254 253.812 138.106 191.748 138.106 191.748C138.106 191.748 128.445 208.316 118.685 208.915C118.685 208.915 141.248 266.288 146.785 283.405C149.978 293.253 168.268 308.407 176.465 305.945C188.919 302.219 188.586 267.702 188.586 267.702Z"
          fill="#DDDDDD"
        />
        <path
          d="M144.441 211.942C154.051 228.627 166.023 249.254 174.187 268.733C181.42 286 182.75 300.223 175.783 303.3C171.46 305.213 161.384 298.143 151.591 273.108C146.02 258.869 138.937 237.593 132.037 219.012C135.944 216.75 140.101 215.336 144.441 211.926V211.942Z"
          fill="#DDDDDD"
        />
        <path
          d="M138.106 191.748L163.845 233.751L136.709 193.96L138.106 191.748Z"
          fill="#DDDDDD"
        />
        <path
          d="M55.5017 118.588C59.3592 123.063 63.6989 124.943 68.9033 128.22C72.1955 130.299 77.5162 134.974 81.0245 137.136C84.2336 139.099 87.6256 140.596 91.5995 140.297C103.638 139.398 111.768 127.704 114.811 123.862"
          fill="#DDDDDD"
        />
        <path
          d="M69.5684 112.833C68.4211 112.584 67.6895 111.769 68.0553 110.87C68.3712 110.055 73.4426 112.833 75.5376 115.212C72.8939 113.698 70.7323 113.083 69.5684 112.833Z"
          fill="#DDDDDD"
        />
        <path
          d="M111.037 107.726C112.068 107.144 112.517 106.146 111.901 105.397C111.353 104.715 107.346 108.857 106.049 111.768C108.127 109.523 110.006 108.308 111.037 107.726Z"
          fill="#DDDDDD"
        />
        <path
          d="M80.5258 120.435C83.1362 124.693 95.4071 129.617 102.69 117.773C102.69 117.773 91.2005 124.577 80.5258 120.435Z"
          fill="#DDDDDD"
        />
        <path
          d="M89.4048 111.635C78.5139 112.75 71.9794 111.835 69.3523 111.602V111.635C69.4354 111.735 69.4853 111.785 69.5685 111.885C70.9984 112.334 72.6112 112.55 74.7894 113.765C76.2859 114.596 78.3975 121.134 91.8989 119.437C99.2814 118.505 103.455 115.461 107.229 109.905C108.41 108.175 109.657 107.527 110.654 106.778C110.654 106.645 110.721 106.595 110.738 106.462C107.878 107.643 102.158 110.338 89.4048 111.652V111.635Z"
          fill="#DDDDDD"
        />
        <path
          d="M101.725 111.419C94.3263 113.997 83.9842 114.979 80.1599 114.729C77.882 114.014 78.4141 116.759 78.4141 116.759C83.7348 121.084 87.4427 119.736 91.2835 119.387C95.0746 119.054 100.528 116.875 103.538 113.515C103.754 112.583 102.773 111.07 101.742 111.419H101.725Z"
          fill="#DDDDDD"
        />
        <path
          d="M104.768 108.209C104.768 108.209 102.424 109.706 96.6875 110.987C90.8845 112.284 88.3738 112.217 83.0864 112.567C80.6588 112.733 79.1291 112.55 76.0198 112.168C76.0198 112.168 76.6682 112.8 77.1172 113.565C77.6991 114.58 80.4094 117.907 91.2836 116.526C99.4642 115.478 103.438 112.068 103.821 110.221C104.053 109.123 104.752 108.558 104.785 108.192L104.768 108.209Z"
          fill="#DDDDDD"
        />
        <path
          d="M92.5805 118.838C75.9366 120.734 80.9414 113.265 69.5684 111.868C69.6515 111.868 72.262 112.916 75.5708 115.96C79.2122 119.304 82.654 124.843 91.9653 123.695C100.362 122.647 104.303 115.993 106.597 112.317C109.224 108.109 110.638 106.794 110.704 106.761C101.958 110.271 105.749 116.908 92.5805 118.838Z"
          fill="#DDDDDD"
        />
        <path
          d="M82.5543 118.439C79.8441 117.424 77.2336 114.896 76.7181 114.596C77.4165 115.345 82.0389 123.745 91.3834 122.465C102.191 120.984 103.305 113.482 104.984 111.103C104.186 112.168 102.191 115.029 99.4642 116.326C97.6851 117.191 94.6257 118.306 91.1506 118.871C88.9392 119.221 85.1482 119.204 82.5543 118.439Z"
          fill="#DDDDDD"
        />
        <path
          d="M99.2647 117.441C99.4476 117.807 98.5497 119.187 96.4214 120.052C93.6779 121.184 91.3335 120.668 91.1506 120.302C90.9677 119.936 94.6922 119.237 95.7563 118.738C96.8038 118.239 99.0817 117.075 99.2813 117.424L99.2647 117.441Z"
          fill="#DDDDDD"
        />
        <path
          d="M69.8178 112.484C70.5161 112.7 70.9651 112.151 73.6753 112.8C73.6753 112.8 68.9698 111.003 68.6871 111.17C68.4876 111.286 68.7869 112.168 69.8178 112.5V112.484Z"
          fill="#DDDDDD"
        />
        <path
          d="M110.787 107.41C110.172 107.793 109.391 107.41 107.229 108.691C107.229 108.691 111.17 105.647 111.552 105.846C111.752 105.946 111.702 106.844 110.787 107.41Z"
          fill="#DDDDDD"
        />
        <path
          d="M76.2858 112.417L77.1338 113.632C77.1338 113.632 82.1386 115.628 91.8156 114.48C98.7991 113.648 103.787 110.371 103.787 110.371L104.186 109.024L76.2858 112.417Z"
          fill="#DDDDDD"
        />
        <path
          d="M96.5212 109.14C93.2622 109.257 92.9629 109.839 90.6351 110.122C88.3738 110.404 86.4783 109.955 83.7016 110.521C80.0935 111.269 75.6706 112.068 70.2501 111.569C70.2501 111.569 72.3618 112.55 82.3215 113.315C85.2812 113.532 87.9249 113.798 90.9012 113.382C94.476 112.983 97.3525 111.901 100.063 111.12C104.652 109.789 109.923 106.811 109.939 106.711C107.528 107.71 100.113 109.007 96.5212 109.14Z"
          fill="#DDDDDD"
        />
        <path
          d="M79.9605 112.001C80.0104 111.652 84.799 110.67 85.5971 110.571C86.3952 110.488 89.1055 110.637 90.0532 110.537C91.001 110.421 93.9108 109.922 94.6756 109.639C95.4571 109.373 101.393 109.207 101.393 109.639C101.393 110.055 92.963 112.068 91.9654 112.284C90.9677 112.484 79.7942 113.049 79.9439 112.018L79.9605 112.001Z"
          fill="#DDDDDD"
        />
        <path
          d="M92.4641 128.935C89.2717 129.068 86.6779 130.133 86.6779 131.331C86.6779 132.528 89.2385 133.393 92.4143 133.26C95.5901 133.127 98.1839 132.063 98.2005 130.865C98.2005 129.667 95.6399 128.802 92.4641 128.935Z"
          fill="#DDDDDD"
        />
        <path
          d="M48.0527 90.3096C48.0527 90.3096 45.7415 113.149 63.3332 123.779C71.5969 128.769 74.1741 132.029 78.6469 135.523C78.6469 135.523 67.5731 127.787 61.022 124.178C57.8462 122.431 42.1667 113.898 48.0527 90.3096Z"
          fill="#DDDDDD"
        />
        <path
          d="M80.426 136.754C80.426 136.754 91.9155 149.596 106.647 133.144C106.647 133.144 94.7421 145.603 80.426 136.754Z"
          fill="#DDDDDD"
        />
        <path
          d="M130.557 85.6186C130.557 85.6186 128.113 79.7632 123.923 78.2827C134.681 91.2079 126.866 107.709 120.281 115.644C112.999 124.411 110.139 129.251 108.992 130.715C110.039 129.484 119.783 118.106 122.609 115.411C125.236 112.916 134.997 99.9577 130.557 85.6186Z"
          fill="#DDDDDD"
        />
        <path
          d="M110.338 206.736C109.524 214.903 106.83 224.502 99.2814 231.089C101.726 230.091 103.937 228.544 105.916 226.448C109.59 222.555 111.386 217.898 111.27 212.508C111.22 210.462 110.92 208.549 110.355 206.736H110.338Z"
          fill="#DDDDDD"
        />
        <path
          d="M78.3643 197.454C82.1553 196.156 85.4142 194.543 88.0247 192.713C84.3833 193.295 81.1576 194.859 78.3643 197.454Z"
          fill="#DDDDDD"
        />
        <path
          d="M94.526 192.546C97.07 194.16 100.179 195.557 103.721 196.655C100.994 194.376 97.9346 193.012 94.526 192.546Z"
          fill="#DDDDDD"
        />
        <path
          d="M72.6113 206.22C71.9462 208.266 71.6303 210.445 71.6635 212.774C71.7633 218.164 73.7419 222.738 77.5662 226.498C79.7942 228.677 82.2717 230.191 84.9986 231.072C76.7514 224.602 73.6754 214.604 72.5946 206.22H72.6113Z"
          fill="#DDDDDD"
        />
        <path
          d="M108.277 206.653C108.526 204.191 108.593 201.862 108.576 199.849C106.431 199.45 104.436 198.934 102.54 198.352C99.298 197.337 96.4714 196.09 94.1602 194.609C93.2124 194.01 92.3312 193.378 91.5663 192.696H91.1839C90.4689 193.395 89.6376 194.027 88.7397 194.659C86.362 196.323 83.3857 197.787 79.9272 198.968C78.2146 199.55 76.3856 200.082 74.4735 200.515C74.5234 202.344 74.6564 204.424 74.9391 206.62C75.9035 214.255 78.7135 223.354 86.229 229.259C87.9582 230.607 89.9036 231.821 92.1649 232.769C94.4927 231.688 96.4714 230.324 98.184 228.827C105.068 222.822 107.512 214.088 108.26 206.653H108.277ZM97.1032 225.017C96.2718 225.749 95.3906 226.415 94.4761 227.014C93.9773 227.33 93.4618 227.612 92.9464 227.879C92.697 228.012 92.3644 228.261 92.0818 228.261C91.8324 228.261 91.4666 228.028 91.2504 227.929C89.2385 226.997 87.3596 225.716 85.78 224.169C85.4808 223.87 85.1815 223.57 84.9154 223.254C82.5045 220.559 80.8584 217.249 79.8441 213.789C79.5282 212.658 79.2455 211.527 79.0294 210.379C78.6137 208.117 78.3975 205.788 78.331 203.492C78.331 203.326 78.4474 203.16 78.6137 203.11C79.8607 202.827 81.0912 202.494 82.3049 202.095C83.2194 201.795 84.1173 201.463 84.9986 201.08C85.8299 200.731 86.628 200.332 87.4261 199.899C87.5425 199.833 87.6589 199.783 87.7587 199.716C88.5235 199.284 89.2385 198.818 89.9369 198.269C90.2528 198.019 90.5521 197.787 90.8347 197.52C91.001 197.371 91.134 197.204 91.3668 197.188C91.4499 197.188 91.5497 197.188 91.6328 197.188C91.7492 197.188 91.8324 197.221 91.8989 197.304C92.5473 197.87 93.2457 198.369 93.9606 198.818C97.1697 200.797 100.894 201.912 104.569 202.611C104.735 202.627 104.868 202.81 104.868 202.977C104.868 203.342 104.868 203.708 104.868 204.074C104.851 206.437 104.669 208.799 104.236 211.128C104.02 212.325 103.737 213.54 103.388 214.704C102.574 217.449 101.31 220.077 99.5973 222.339C98.8657 223.304 98.0343 224.202 97.1198 225.017H97.1032Z"
          fill="#DDDDDD"
        />
        <path
          d="M42.4494 174.298C-14.4491 127.671 34.302 80.0127 53.4566 51.3511C59.6253 42.1189 68.6539 16.1854 85.4474 18.7305C89.7705 19.3793 92.2812 22.3735 92.5805 22.5731C92.4475 22.4733 88.6898 20.9429 84.35 21.7913C79.5947 22.7062 76.7846 25.2513 72.3784 32.0549C67.7893 38.7753 64.9128 45.1963 59.6419 55.227C39.3733 93.7695 5.88604 122.481 42.4494 174.298Z"
          fill="#DDDDDD"
        />
        <path
          d="M159.705 86.4506C158.724 61.7149 150.427 30.4084 130.125 15.5037C117.555 6.28812 89.122 9.3988 89.122 9.3988C8.01422 13.2081 10.9406 160.358 68.288 175.779C73.0102 175.962 118.901 197.554 129.643 198.202C124.272 191.199 136.111 169.591 145.738 152.174C153.952 137.336 160.752 112.9 159.688 86.4673L159.705 86.4506Z"
          fill="url(#paint0_linear_2079_14077)"
        />
        <path
          d="M57.9292 56.8076C57.9292 56.8076 -10.5751 90.2433 77.0339 222.19L84.4497 207.235C84.4497 207.235 54.9529 139.083 62.5183 110.787C67.9055 90.6592 59.6418 78.782 57.9126 56.8076H57.9292Z"
          fill="url(#paint1_linear_2079_14077)"
        />
        <path
          d="M106.764 219.578C108.626 219.362 132.685 216.068 133.351 214.97C170.579 153.638 167.154 94.3022 152.904 74.8729C105.101 9.798 105.766 26.1832 105.766 26.1832C100.462 59.4026 106.597 80.8281 120.165 97.8454C138.937 121.383 117.189 190.484 106.764 219.578Z"
          fill="url(#paint2_linear_2079_14077)"
        />
        <path
          d="M51.877 92.3391C55.0861 105.647 56.7322 115.861 56.7322 115.861C51.3283 116.476 49.6157 110.82 46.9221 105.963C42.7985 98.5438 40.1049 90.3429 48.5017 88.6296C49.915 88.3468 51.877 92.3391 51.877 92.3391Z"
          fill="#F0C499"
        />
        <path
          d="M53.1074 111.685C53.1074 111.685 50.1145 108.991 50.2641 103.019C50.6133 88.9125 41.7011 88.979 44.7771 98.2279C44.7771 98.2279 45.4755 93.6201 46.7391 94.8344C48.3021 96.3149 45.5586 97.5459 47.8365 104.549C49.0171 108.192 53.0908 111.702 53.0908 111.702L53.1074 111.685Z"
          fill="url(#paint3_linear_2079_14077)"
        />
        <path
          d="M94.1601 25.7506C68.1883 24.1037 52.9744 50.2535 51.4946 79.78C50.6632 96.3482 53.1407 110.571 62.6515 121.616C66.509 126.091 70.8487 127.971 76.053 131.248C79.3452 133.327 84.6659 138.001 88.1743 140.164C91.3834 142.127 94.7753 143.624 98.7492 143.324C110.787 142.426 117.571 131.996 120.614 128.17C131.339 114.713 141.93 108.974 135.695 75.6047C130.79 49.3885 110.92 24.4364 94.1601 25.7506Z"
          fill="#F7D4AB"
        />
        <path
          d="M101.193 93.4369C100.744 79.9462 98.8822 68.5847 112.766 64.2597C125.735 60.2175 130.723 68.0025 132.17 69.8323C135.894 74.5566 137.158 87.5649 137.158 87.5649C137.158 87.5649 125.253 100.906 101.193 93.4536V93.4369Z"
          fill="url(#paint4_linear_2079_14077)"
        />
        <path
          d="M110.172 100.872C112.5 108.358 117.92 114.38 124.571 116.326C133.251 118.888 137.99 106.828 137.84 92.605C137.757 84.7867 126.583 80.2454 120.065 82.4579C113.548 84.6703 107.861 93.3868 110.172 100.872Z"
          fill="url(#paint5_radial_2079_14077)"
        />
        <path
          d="M90.3192 95.9819C87.5092 85.5686 88.407 69.0836 69.4852 69.7989C63.5825 70.0151 59.0433 72.2941 56.2998 75.0554C49.8983 81.4931 55.7012 95.8654 55.7012 95.8654C55.7012 95.8654 68.2548 108.341 90.3192 95.9819Z"
          fill="url(#paint6_linear_2079_14077)"
        />
        <path
          d="M81.2906 104.283C80.4925 111.003 77.3665 123.18 71.0149 122.88C62.8177 122.514 56.2499 118.505 54.1383 102.586C53.2404 95.8322 62.3355 89.877 68.6206 90.8418C74.9223 91.8066 82.1053 97.5622 81.2906 104.283Z"
          fill="url(#paint7_radial_2079_14077)"
        />
        <path
          d="M128.695 88.6794C126.483 89.5444 125.087 91.291 125.552 92.5719C126.018 93.8528 128.163 94.2021 130.374 93.3371C132.569 92.4721 133.982 90.7254 133.517 89.4446C133.051 88.1637 130.906 87.8144 128.712 88.6794H128.695Z"
          fill="url(#paint8_linear_2079_14077)"
        />
        <path
          d="M60.706 96.7304C58.3782 96.5308 56.4328 97.4457 56.3331 98.8098C56.2499 100.174 58.0623 101.421 60.3901 101.638C62.7179 101.837 64.68 100.922 64.7631 99.575C64.8462 98.2276 63.0339 96.9467 60.706 96.7471V96.7304Z"
          fill="url(#paint9_linear_2079_14077)"
        />
        <path
          d="M76.7181 115.877C75.5708 115.628 74.8392 114.812 75.205 113.914C75.5209 113.099 80.5922 115.877 82.6873 118.256C80.0435 116.742 77.882 116.127 76.7181 115.877Z"
          fill="url(#paint10_linear_2079_14077)"
        />
        <path
          d="M118.186 110.754C119.217 110.171 119.666 109.173 119.051 108.425C118.502 107.743 114.495 111.885 113.198 114.796C115.277 112.55 117.156 111.336 118.186 110.754Z"
          fill="url(#paint11_linear_2079_14077)"
        />
        <path
          d="M87.6754 123.462C90.2859 127.721 102.557 132.645 109.84 120.801C109.84 120.801 98.3501 127.604 87.6754 123.462Z"
          fill="#F0C499"
        />
        <path
          d="M96.5544 114.663C85.6636 115.777 79.1291 114.862 76.502 114.629V114.663C76.5851 114.762 76.635 114.812 76.7181 114.912C78.1481 115.361 79.7609 115.578 81.9391 116.792C83.4355 117.624 85.5472 124.161 99.0485 122.464C106.431 121.533 110.604 118.489 114.379 112.933C115.559 111.203 116.806 110.554 117.804 109.805C117.804 109.672 117.871 109.622 117.887 109.489C115.027 110.67 109.308 113.365 96.5544 114.679V114.663Z"
          fill="#961B27"
        />
        <path
          d="M108.875 114.446C101.476 117.025 91.1338 118.006 87.3096 117.757C85.0316 117.041 85.5637 119.786 85.5637 119.786C90.8844 124.111 94.5923 122.764 98.4332 122.414C102.224 122.082 107.678 119.903 110.687 116.542C110.904 115.611 109.923 114.097 108.892 114.446H108.875Z"
          fill="url(#paint12_linear_2079_14077)"
        />
        <path
          d="M111.918 111.252C111.918 111.252 109.574 112.75 103.837 114.03C98.0342 115.328 95.5235 115.261 90.236 115.611C87.8084 115.777 86.2787 115.594 83.1694 115.212C83.1694 115.212 83.8179 115.844 84.2668 116.609C84.8488 117.624 87.559 120.95 98.4333 119.57C106.614 118.522 110.588 115.112 110.97 113.265C111.203 112.167 111.901 111.602 111.935 111.236L111.918 111.252Z"
          fill="url(#paint13_linear_2079_14077)"
        />
        <path
          d="M99.7301 121.865C83.0863 123.762 88.0911 116.293 76.718 114.895C76.8012 114.895 79.4116 115.943 82.7205 118.988C86.3618 122.331 89.8037 127.87 99.1149 126.723C107.512 125.675 111.452 119.021 113.747 115.345C116.374 111.136 117.787 109.822 117.854 109.789C109.108 113.298 112.899 119.936 99.7301 121.865Z"
          fill="#F86D78"
        />
        <path
          d="M89.704 121.466C86.9937 120.452 84.3832 117.923 83.8678 117.624C84.5661 118.372 89.1885 126.773 98.533 125.492C109.341 124.011 110.455 116.509 112.134 114.13C111.336 115.195 109.341 118.056 106.614 119.354C104.835 120.219 101.775 121.333 98.3003 121.899C96.0888 122.248 92.2978 122.231 89.704 121.466Z"
          fill="url(#paint14_linear_2079_14077)"
        />
        <path
          d="M106.414 120.485C106.597 120.851 105.699 122.231 103.571 123.096C100.828 124.228 98.4831 123.712 98.3002 123.346C98.1173 122.98 101.842 122.281 102.906 121.782C103.953 121.283 106.231 120.119 106.431 120.468L106.414 120.485Z"
          fill="#FF9AA2"
        />
        <path
          d="M76.9675 115.528C77.6658 115.744 78.1147 115.195 80.825 115.844C80.825 115.844 76.1195 114.047 75.8368 114.214C75.6373 114.33 75.9366 115.212 76.9675 115.544V115.528Z"
          fill="#961B27"
        />
        <path
          d="M117.937 110.437C117.322 110.82 116.54 110.437 114.379 111.718C114.379 111.718 118.319 108.674 118.702 108.874C118.901 108.973 118.852 109.872 117.937 110.437Z"
          fill="#961B27"
        />
        <path
          d="M83.4354 115.461L84.2834 116.676C84.2834 116.676 89.2882 118.672 98.9653 117.524C105.949 116.692 110.937 113.415 110.937 113.415L111.336 112.068L83.4354 115.461Z"
          fill="url(#paint15_linear_2079_14077)"
        />
        <path
          d="M103.671 112.167C100.412 112.284 100.113 112.866 97.7848 113.149C95.5235 113.432 93.628 112.983 90.8512 113.548C87.2431 114.297 82.8203 115.095 77.3998 114.596C77.3998 114.596 79.5114 115.578 89.4712 116.343C92.4308 116.559 95.0746 116.825 98.0508 116.409C101.626 116.01 104.502 114.929 107.212 114.147C111.802 112.816 117.072 109.839 117.089 109.739C114.678 110.737 107.262 112.034 103.671 112.167Z"
          fill="#F86D78"
        />
        <path
          d="M87.1102 115.029C87.16 114.679 91.9487 113.698 92.7468 113.598C93.5449 113.515 96.2551 113.665 97.2029 113.565C98.1507 113.448 101.06 112.949 101.825 112.666C102.607 112.4 108.543 112.234 108.543 112.666C108.543 113.082 100.113 115.095 99.115 115.311C98.1174 115.511 86.9439 116.077 87.0935 115.045L87.1102 115.029Z"
          fill="url(#paint16_linear_2079_14077)"
        />
        <path
          d="M99.6138 131.979C96.4214 132.112 93.8275 133.177 93.8275 134.375C93.8275 135.572 96.3881 136.437 99.5639 136.304C102.74 136.171 105.334 135.107 105.35 133.909C105.35 132.711 102.79 131.846 99.6138 131.979Z"
          fill="url(#paint17_linear_2079_14077)"
        />
        <path
          d="M55.2024 93.3369C55.2024 93.3369 52.8912 116.176 70.4828 126.806C78.7466 131.796 81.3238 135.057 85.7965 138.55C85.7965 138.55 74.7228 130.815 68.1716 127.205C64.9958 125.458 49.3163 116.925 55.2024 93.3369Z"
          fill="#F0C499"
        />
        <path
          d="M87.5757 139.781C87.5757 139.781 99.0651 152.623 113.797 136.171C113.797 136.171 101.892 148.631 87.5757 139.781Z"
          fill="url(#paint18_linear_2079_14077)"
        />
        <path
          d="M138.389 91.5573C138.389 91.5573 135.695 69.8656 130.058 55.7761C123.723 39.9731 115.659 28.0294 102.091 26.5988C102.091 26.5988 65.079 20.1945 56.6823 51.0352C47.82 83.6059 52.3259 99.3756 53.972 106.578C53.972 106.578 54.7868 88.2969 57.9626 82.3084C57.9626 82.3084 68.1883 70.7639 75.2383 56.3583C89.3382 27.4971 106.996 29.7594 113.797 37.6775C124.804 50.5029 131.139 69.4331 138.422 91.5573H138.389Z"
          fill="#F0C499"
        />
        <path
          d="M136.36 82.6243C136.244 96.3146 136.959 106.628 136.959 106.628C142.363 106.013 142.762 100.107 144.275 94.7676C146.602 86.5999 147.55 78.0331 140.168 78.4157C138.721 78.4989 136.377 82.6243 136.377 82.6243H136.36Z"
          fill="#F0C499"
        />
        <path
          d="M139.552 101.738C139.552 101.738 141.847 98.4441 140.351 92.6552C136.826 78.9982 145.522 77.0353 144.607 86.7333C144.607 86.7333 142.878 82.4083 141.914 83.8721C140.733 85.6687 143.676 86.2509 143.044 93.5868C142.712 97.3961 139.536 101.738 139.536 101.738H139.552Z"
          fill="url(#paint19_linear_2079_14077)"
        />
        <path
          d="M116.723 63.012C116.723 63.012 121.662 60.1509 130.358 64.3262C135.412 66.7548 133.966 80.362 133.966 80.362C133.966 80.362 132.951 63.3946 116.723 63.012Z"
          fill="#F7D4AB"
        />
        <path
          d="M132.22 61.415C132.22 61.415 136.077 82.7074 137.74 87.6978L137.69 79.9294C137.69 79.9294 133.733 63.9601 132.22 61.4317V61.415Z"
          fill="url(#paint20_linear_2079_14077)"
        />
        <path
          d="M51.3947 75.0889C51.3947 75.0889 52.5919 91.3243 51.8437 97.5457L49.2831 86.8163L51.3947 75.1055V75.0889Z"
          fill="url(#paint21_linear_2079_14077)"
        />
        <path
          d="M28.7652 52.7985C45.2261 3.41018 85.7135 -3.70946 103.022 9.63155C104.436 14.3724 107.03 21.4255 99.4476 29.0276C78.9961 16.3686 73.8084 56.5081 58.4781 68.169C47.1881 76.7691 43.0812 95.9822 27.9837 119.969C19.5537 90.7589 25.7057 61.9809 28.7485 52.8318L28.7652 52.7985Z"
          fill="url(#paint22_linear_2079_14077)"
        />
        <path
          d="M26.5204 100.806C26.6202 89.9934 26.9361 71.1962 48.2855 40.472C58.5944 25.7836 72.9437 14.4054 85.9462 14.6217C92.2646 14.6217 96.8205 17.4496 99.1815 19.662C101.626 21.8744 103.139 23.5545 103.205 23.5878C102.889 23.4048 96.0224 18.2314 86.7111 21.0427C77.5495 23.6044 67.9057 34.5667 58.5445 47.5916C39.8389 73.4253 26.0715 100.274 26.5204 100.806Z"
          fill="url(#paint23_linear_2079_14077)"
        />
        <path
          d="M150.593 52.0501C150.593 52.2164 142.595 40.805 129.36 29.7263C122.859 24.3201 116.141 20.7935 110.954 20.9432C105.733 20.9931 103.322 23.4218 103.172 23.5882C103.388 23.3386 104.918 20.0949 110.721 18.814C116.507 17.4832 124.671 20.4608 131.372 26.0168C144.956 37.0789 150.626 52.1665 150.593 52.0667V52.0501Z"
          fill="url(#paint24_linear_2079_14077)"
        />
        <path
          d="M102.307 8.86625C102.307 8.86625 116.524 5.27316 133.201 16.9008C144.956 25.1017 153.586 42.2354 156.263 61.0326C156.263 61.0326 149.213 34.4005 132.852 20.6103C123.74 12.9417 108.376 8.76644 102.391 10.3301C102.391 10.3301 85.0151 2.59498 64.3474 11.3448C46.9553 18.714 25.8387 39.4907 23.727 88.2636C23.727 88.2636 21.1332 12.975 78.3309 3.60969C92.8632 1.23093 102.291 8.86625 102.291 8.86625H102.307Z"
          fill="url(#paint25_linear_2079_14077)"
        />
        <path
          d="M137.707 88.646C137.707 88.646 135.263 82.7905 131.073 81.3101C141.83 94.2352 134.016 110.737 127.431 118.672C120.149 127.438 117.289 132.279 116.141 133.743C117.189 132.512 126.932 121.133 129.759 118.439C132.386 115.943 142.146 102.985 137.707 88.646Z"
          fill="url(#paint26_linear_2079_14077)"
        />
        <path
          d="M108.343 65.0748C105.217 67.0044 103.355 71.6122 106.115 70.348C108.875 69.0838 118.985 63.8438 121.296 63.5278C124.871 63.0287 130.391 64.3096 131.887 65.0249C131.887 65.0249 124.904 60.7831 120.897 60.833C116.906 60.8829 110.022 64.0434 108.343 65.0748Z"
          fill="url(#paint27_linear_2079_14077)"
        />
        <path
          d="M75.654 68.801C73.7584 68.2687 65.6776 66.7882 61.8201 67.8195C57.9626 68.8675 52.4091 74.9392 52.4091 74.9392C53.6561 73.8579 58.9103 70.8304 62.1859 70.5476C64.4971 70.348 75.654 72.577 78.6469 73.0594C81.6398 73.5252 79.1956 69.8157 75.654 68.801Z"
          fill="url(#paint28_linear_2079_14077)"
        />
        <path
          d="M118.369 71.1299C105.234 72.6603 103.737 80.8113 104.136 86.65C105.151 86.7498 106.232 86.833 108.243 87.199C110.97 87.7147 114.213 88.6462 118.669 88.0973C122.177 87.6648 129.826 84.9533 129.044 77.3845C128.429 71.396 121.545 71.0301 118.353 71.1465L118.369 71.1299Z"
          fill="url(#paint29_linear_2079_14077)"
        />
        <path
          d="M104.685 86.1173C105.699 83.4225 107.296 78.2491 112.932 76.4526C118.569 74.6394 123.873 76.4859 128.379 80.1621C125.22 84.0546 123.341 86.1672 119.217 87.2651C114.961 88.3963 107.296 86.1839 104.685 86.134V86.1173Z"
          fill="url(#paint30_linear_2079_14077)"
        />
        <path
          d="M105.799 77.9C105.799 77.9 107.445 74.5731 111.552 72.6102C115.659 70.6473 120.016 70.8136 123.424 71.5788C123.424 71.5788 116.357 71.0798 111.918 73.4086C107.479 75.7375 105.816 77.9 105.816 77.9H105.799Z"
          fill="#DBA379"
        />
        <path
          d="M66.5921 77.168C79.7277 75.6376 83.0531 83.223 84.0009 88.9952C83.0365 89.3279 82.0056 89.644 80.1267 90.4757C77.5827 91.5902 74.6397 93.2703 70.1836 93.7361C66.6753 94.1187 58.611 93.2371 57.6134 85.7016C56.8319 79.7297 63.4496 77.8001 66.5921 77.168Z"
          fill="url(#paint31_linear_2079_14077)"
        />
        <path
          d="M67.3237 81.9756C63.9816 83.0069 60.9887 84.9865 59.3925 87.5815L60.4899 89.1451C60.4899 89.1451 62.0861 87.4817 64.6467 86.0511C64.9792 84.4209 65.9436 82.9737 67.3237 81.9756Z"
          fill="#DDDDDD"
        />
        <path
          d="M71.6301 83.9883C74.3237 83.9217 76.6017 84.5206 78.3974 85.3357C77.9485 83.7554 76.8178 82.4745 75.4045 81.6428C74.7893 81.4265 74.1574 81.2768 73.509 81.2269C71.4805 81.0772 69.3522 81.36 67.3236 81.9755C65.9436 82.9735 64.8961 84.4041 64.6134 86.0676C66.4923 85.003 68.9365 84.0548 71.6301 83.9883Z"
          fill="url(#paint32_linear_2079_14077)"
        />
        <path
          d="M80.3928 80.8608C80.3928 80.8608 78.0317 77.9997 73.5756 77.0349C69.1195 76.0701 64.9128 77.2345 61.7869 78.7482C61.7869 78.7482 68.5542 76.6356 73.4093 77.8832C78.2645 79.1142 80.3761 80.8608 80.3761 80.8608H80.3928Z"
          fill="#DBA379"
        />
        <path
          d="M68.737 87.6312C68.4211 84.8532 69.9009 82.2582 72.3285 80.8941C66.9246 80.6779 62.4353 83.4559 58.9768 87.6977C62.851 90.8749 65.2287 93.4034 69.4686 93.0541C70.4829 92.9709 71.2477 92.8711 72.0957 92.6881C70.2667 91.6401 68.9864 89.7937 68.737 87.6312Z"
          fill="url(#paint33_linear_2079_14077)"
        />
        <path
          d="M83.369 88.6125C82.0887 86.7328 78.0482 81.4929 73.376 80.9938C73.0268 80.9605 72.6776 80.9273 72.3285 80.9106C69.9175 82.2747 68.4211 84.8697 68.737 87.6477C68.9864 89.8102 70.2833 91.6566 72.0957 92.7046C76.7347 91.8563 81.8891 89.1115 82.4545 88.8953C83.0198 88.679 83.3523 88.6291 83.3523 88.6291L83.369 88.6125Z"
          fill="url(#paint34_linear_2079_14077)"
        />
        <path
          d="M59.4756 87.4483C59.4756 87.4483 64.6965 82.9569 71.4971 81.0605C69.9009 82.225 70.4329 83.9882 69.5517 85.1859C65.5279 86.2007 62.5516 88.7291 61.421 90.4259L59.4756 87.4316V87.4483Z"
          fill="#DDDDDD"
        />
        <path
          d="M68.87 85.3858C68.87 85.3858 69.1859 83.6558 70.4496 82.3583C70.4496 82.3583 75.2715 80.9111 78.7133 83.3231C82.1718 85.7352 83.3523 88.613 83.3523 88.613C83.3523 88.613 77.3499 82.9405 68.87 85.3858Z"
          fill="url(#paint35_linear_2079_14077)"
        />
        <path
          d="M71.4971 85.6684C71.6467 87.9973 73.7916 89.7439 76.2857 89.5776C78.7798 89.3946 80.6753 87.3652 80.5257 85.0363C80.3594 82.7075 78.2311 80.9608 75.737 81.1272C73.2429 81.2935 71.3474 83.3396 71.5137 85.6684H71.4971Z"
          fill="url(#paint36_radial_2079_14077)"
        />
        <path
          d="M76.3191 85.6021C76.1029 86.2508 75.4212 86.5669 74.806 86.3506C74.1908 86.1177 73.8749 85.4191 74.091 84.7703C74.3238 84.1216 74.9889 83.8055 75.6041 84.0218C76.2193 84.2547 76.5186 84.9533 76.3024 85.5854L76.3191 85.6021Z"
          fill="white"
        />
        <path
          d="M104.685 86.1343C104.685 86.1343 110.937 79.181 117.022 79.8797L118.802 76.3864C118.802 76.3864 105.716 75.904 104.685 86.1177V86.1343Z"
          fill="#DDDDDD"
        />
        <path
          d="M117.605 87.5317C118.768 87.4485 120.431 87.0659 121.878 86.2674C124.388 84.8868 126.716 82.8074 127.398 82.0589C127.082 81.3436 126.35 76.9354 126.317 76.769C124.804 75.3052 122.593 74.5067 120.232 74.7729C116.208 75.2387 113.298 78.682 113.747 82.4581C114.013 84.787 115.576 86.517 117.621 87.5317H117.605Z"
          fill="url(#paint37_linear_2079_14077)"
        />
        <path
          d="M113.88 80.1292C113.88 80.1292 114.213 78.3327 115.593 77.0518C115.593 77.0518 124.388 75.5214 127.598 79.7466C127.598 79.7466 127.298 81.1772 126.4 82.142C126.4 82.142 120.614 78.3992 113.863 80.1292H113.88Z"
          fill="url(#paint38_linear_2079_14077)"
        />
        <path
          d="M125.552 79.4304C125.935 81.7426 124.255 83.9384 121.795 84.3376C119.334 84.7369 117.022 83.1898 116.64 80.8942C116.258 78.582 117.937 76.3862 120.414 75.987C122.875 75.5711 125.186 77.1182 125.569 79.4304H125.552Z"
          fill="url(#paint39_radial_2079_14077)"
        />
        <path
          d="M121.329 80.4117C121.113 81.0438 120.448 81.3599 119.849 81.1436C119.251 80.9108 118.935 80.2287 119.151 79.5966C119.367 78.9645 120.032 78.6484 120.631 78.8647C121.229 79.0809 121.545 79.7796 121.312 80.4117H121.329Z"
          fill="white"
        />
        <path
          d="M118.669 83.9219C118.369 84.737 117.455 85.1695 116.623 84.8701C115.792 84.5706 115.36 83.6724 115.659 82.8406C115.958 82.0255 116.873 81.593 117.704 81.8925C118.536 82.1919 118.968 83.1068 118.669 83.9219Z"
          fill="white"
        />
        <path
          d="M73.7584 89.0951C73.4591 89.9269 72.5114 90.3594 71.6634 90.0599C70.8154 89.7439 70.3831 88.829 70.6824 87.9972C70.9983 87.1655 71.9294 86.733 72.7774 87.0324C73.6254 87.3319 74.0577 88.2634 73.7584 89.0951Z"
          fill="white"
        />
        <path
          d="M58.2452 88.5963C58.1454 88.3634 58.0956 88.1971 58.0789 88.1305C54.5706 87.3487 54.6038 82.6577 54.554 82.4914C54.6371 82.5247 58.4614 83.8887 62.3189 81.3436C73.4591 75.2719 80.4426 80.961 83.369 88.613C83.369 88.613 77.8155 81.9092 71.5803 82.2918C67.2738 82.5579 63.0671 84.2547 59.8082 87.7313C62.5517 91.0583 64.8296 91.8567 69.4852 93.0544C66.2097 93.3206 62.8676 92.1062 60.7393 90.9085C57.4471 89.0621 56.1668 89.977 56.1502 89.9604C56.4993 89.5112 56.7986 88.6629 58.2618 88.5963H58.2452Z"
          fill="url(#paint40_linear_2079_14077)"
        />
        <path
          d="M83.369 88.6126C83.369 88.6126 77.882 82.1417 72.1124 82.2748C66.3427 82.3913 62.0695 85.4853 59.8082 87.731C59.8082 87.731 62.1527 81.6926 72.3452 81.5429C79.9605 81.4265 83.369 88.6126 83.369 88.6126Z"
          fill="#391B13"
        />
        <path
          d="M129.127 80.3289C129.16 80.0793 129.177 79.8964 129.177 79.8298C132.419 78.2662 131.305 73.7083 131.322 73.5253C131.255 73.5752 127.847 75.7876 123.507 74.2073C111.269 70.8637 105.782 78 104.685 86.1177C104.685 86.1177 108.543 78.3161 114.695 77.2515C118.951 76.5195 123.441 77.2015 127.415 79.8465C125.519 83.7223 123.424 85.0365 119.167 87.2822C122.426 86.7998 125.436 84.8202 127.232 83.1568C130.008 80.6117 131.472 81.2105 131.472 81.1772C131.023 80.8113 130.557 80.0627 129.11 80.3289H129.127Z"
          fill="url(#paint41_linear_2079_14077)"
        />
        <path
          d="M104.685 86.1174C104.685 86.1174 108.543 78.5653 114.179 77.3509C119.832 76.1366 124.688 78.166 127.415 79.8295C127.415 79.8295 123.74 74.4898 113.78 76.6855C106.348 78.3324 104.685 86.1008 104.685 86.1008V86.1174Z"
          fill="#391B13"
        />
        <path
          d="M87.9748 105.946C87.9748 105.946 95.5235 109.822 97.0532 109.439C98.5996 109.057 104.602 104.05 104.602 104.05L87.9581 105.946H87.9748Z"
          fill="url(#paint42_linear_2079_14077)"
        />
        <path
          d="M95.5235 98.0447C91.1173 98.6768 90.0033 99.9411 90.4689 101.072C91.5829 103.85 93.5283 104.166 96.2884 103.85C99.1982 103.501 101.376 102.603 101.476 99.4587C101.509 98.5271 99.9464 97.4126 95.5402 98.0281L95.5235 98.0447Z"
          fill="url(#paint43_linear_2079_14077)"
        />
        <path
          d="M99.3811 105.414C99.3811 105.414 103.588 101.438 104.585 102.237C107.512 104.616 99.3811 105.414 99.3811 105.414Z"
          fill="url(#paint44_linear_2079_14077)"
        />
        <path
          d="M93.9273 106.029C93.9273 106.029 88.7064 103.584 87.8418 104.515C85.3976 107.127 93.9273 106.029 93.9273 106.029Z"
          fill="url(#paint45_linear_2079_14077)"
        />
        <path
          d="M87.9748 105.946C87.9748 105.946 87.3596 104.815 89.2219 104.732C90.5687 104.665 91.9487 107.377 96.6875 106.695C101.742 105.963 102.141 103.334 103.438 103.118C104.619 102.919 104.619 104.033 104.619 104.033C104.619 104.033 106.88 102.519 105.849 101.505C103.97 99.6747 103.937 103.883 96.3549 104.649C88.1245 105.48 86.7278 101.554 85.8964 104.266C85.5639 105.38 86.4617 105.597 87.9748 105.929V105.946Z"
          fill="#F0C499"
        />
        <path
          d="M157.227 92.0894C157.227 92.0894 161.367 113.681 158.94 134.541C156.529 155.318 140.45 167.977 140.45 167.977C140.45 167.977 151.391 155.285 155.182 130.931C156.695 121.2 157.211 92.0728 157.211 92.0728L157.227 92.0894Z"
          fill="url(#paint46_linear_2079_14077)"
        />
        <path
          d="M163.579 139.598C176.731 77.0352 136.742 42.3851 126.882 34.0179C118.004 26.499 106.813 17.6494 99.4309 29.0108C99.4309 29.0108 106.364 25.9334 114.761 33.2194C158.74 76.7524 156.313 104.865 147.999 138.484C139.586 172.468 118.087 178.972 139.27 219.777C131.139 170.139 154.866 180.985 163.562 139.598H163.579Z"
          fill="url(#paint47_linear_2079_14077)"
        />
        <path
          d="M73.6088 141.378C73.6088 141.378 95.7397 134.209 122.792 141.378C122.792 141.378 127.797 145.105 129.443 155.401C131.073 165.698 98.8822 171.803 98.8822 171.803C98.8822 171.803 67.5897 160.724 66.5755 156.948C64.8462 150.577 73.6088 141.378 73.6088 141.378Z"
          fill="#252A3E"
        />
        <path
          d="M71.2644 145.354C71.2644 145.354 76.6184 139.781 98.4167 139.931C120.232 140.081 124.122 144.073 125.12 144.672C125.12 144.672 124.339 142.992 122.792 141.378C121.429 140.846 118.602 138.051 98.4333 138.184C81.008 138.301 76.2027 140.264 73.6088 141.378C72.5613 142.393 71.2644 145.354 71.2644 145.354Z"
          fill="url(#paint48_linear_2079_14077)"
        />
        <path
          d="M173.272 170.04C170.163 163.569 155.265 159.51 153.436 159.344C147.035 158.728 121.129 156.699 118.469 148.148C115.144 137.519 118.103 123.562 118.103 123.562L79.1789 121.882C83.6682 129.368 83.502 137.353 83.3191 145.437C83.1195 154.32 56.2499 160.575 47.0218 161.589C41.9671 162.138 24.1759 168.01 21.7317 176.71C18.2732 188.92 18.5559 192.996 19.5702 209.015L180.289 193.611C179.308 184.695 179.192 182.416 173.272 170.073V170.04Z"
          fill="#F7D4AB"
        />
        <path
          d="M138.089 157.813C139.469 157.248 140.833 156.416 142.229 155.584C126.716 153.571 122.027 150.927 120.913 150.161C119.716 149.346 118.569 145.47 118.137 140.946C117.954 138.983 117.654 134.042 117.588 131.464C117.039 132.129 83.0031 130.882 81.6896 128.952L77.7323 128.985C77.7323 128.985 78.8962 141.511 77.0506 150.494C76.6681 152.324 65.6443 154.902 58.3948 156.416C58.9102 156.815 60.7725 158.063 61.1882 158.296C67.3901 158.911 72.7108 159.643 75.2382 159.194C78.3309 158.662 83.5352 156.3 87.3096 154.57C107.728 145.204 114.096 142.11 114.096 142.11C115.06 150.81 121.479 156.566 121.146 156.483C126.284 157.73 132.536 157.963 138.089 157.813Z"
          fill="url(#paint49_linear_2079_14077)"
        />
        <path
          d="M105.583 170.472C105.583 170.472 103.455 164.617 96.3049 166.896C96.3049 166.896 103.654 168.193 105.583 170.472Z"
          fill="url(#paint50_linear_2079_14077)"
        />
        <path
          d="M105.832 165.465C105.832 165.465 107.878 160.159 110.737 159.443C113.597 158.728 145.206 157.68 148.914 156.948C148.914 156.948 134.847 160.026 126.716 160.974C118.585 161.922 112.201 160.508 105.832 165.465Z"
          fill="url(#paint51_linear_2079_14077)"
        />
        <path
          d="M95.723 165.415C95.723 165.415 93.7444 161.34 90.2361 160.159C86.7111 158.978 59.8414 157.314 55.0195 157.78C55.0195 157.78 88.9225 161.173 95.7397 165.415H95.723Z"
          fill="url(#paint52_linear_2079_14077)"
        />
        <path
          d="M77.8487 144.722C77.8487 144.722 78.0316 129.118 76.7513 123.629L117.771 135.44C117.771 135.44 101.293 152.174 77.8487 144.722Z"
          fill="url(#paint53_linear_2079_14077)"
        />
        <path
          d="M24.3755 168.293C24.3755 168.293 27.2187 166.746 29.3138 165.998C29.3138 165.998 43.3804 237.011 44.0455 240.604C44.0788 240.82 41.1856 259.618 41.1856 259.618C41.1856 259.618 38.7747 245.644 38.5419 243.715C37.5942 235.946 33.2212 200.564 24.3755 168.293Z"
          fill="url(#paint54_linear_2079_14077)"
        />
        <path
          d="M147.866 318.288C151.923 315.81 150.277 309.172 150.277 301.221C150.277 284.786 154.234 244.563 156.728 226.648C155.049 205.156 171.044 165.848 171.044 165.848L139.735 154.886C96.8204 198.884 60.6894 155.318 60.6894 155.318L26.67 166.646C37.2782 207.169 41.0526 239.224 41.0526 242.501C41.0526 245.778 49.7985 251.3 47.1881 279.696C46.789 284.054 32.7889 309.139 37.0288 312.133C74.8891 328.502 106.298 322.364 147.866 318.272V318.288Z"
          fill="#27AE60"
        />
        <path
          d="M37.0288 312.15C37.0288 312.15 61.3212 320.866 92.913 321.199C119.284 321.465 148.914 317.573 148.914 317.573C148.914 317.573 154.899 316.159 131.704 316.624C105.117 317.173 106.597 316.142 106.597 316.142C106.597 316.142 115.16 310.453 120.814 294.101C120.814 294.101 111.702 302.685 99.6803 308.74C92.7966 312.216 84.9486 314.861 77.3 314.578C76.5352 314.545 64.3474 307.875 62.2523 298.36C62.2523 298.36 60.3901 307.159 62.7345 311.318C62.7345 311.318 33.2212 309.771 37.0288 312.15Z"
          fill="#27AE60"
        />
        <path
          d="M60.6894 155.318C60.6894 155.318 77.2834 174.165 99.3145 174.548C119.118 174.881 134.714 162.538 139.735 154.886L146.469 156.549C146.469 156.549 134.598 180.42 99.3311 179.505C69.0529 178.723 54.8033 157.298 54.8033 157.298L60.6894 155.335V155.318Z"
          fill="url(#paint55_linear_2079_14077)"
        />
        <path
          d="M53.0575 239.107C63.9816 241.552 143.743 242.866 148.648 240.405C153.553 237.959 149.363 182.167 149.363 182.167H41.036C41.036 182.167 46.4897 237.643 53.0575 239.107Z"
          fill="#34B769"
        />
        <path
          d="M74.3903 299.308V307.658L122.925 301.57L122.343 291.14L74.3903 299.308Z"
          fill="#36C670"
        />
        <path
          d="M211.765 743.337C211.765 743.337 214.907 757.593 215.057 760.637C215.206 763.681 216.553 785.539 216.553 785.539H191.114C191.114 785.539 189.717 764.446 187.622 753.983L211.765 743.337Z"
          fill="url(#paint56_linear_2079_14077)"
        />
        <path
          d="M57.7963 779.7V755.048L79.5447 751.372C79.5447 751.372 84.0507 762.217 84.2502 765.544C84.4497 768.871 83.9011 782.212 83.9011 782.212L57.7963 779.717V779.7Z"
          fill="url(#paint57_linear_2079_14077)"
        />
        <path
          d="M91.1006 810.724V824.564C91.1006 826.361 88.407 830.436 82.7371 830.436C81.3571 830.436 56.4993 831.368 53.8722 830.436C51.2451 829.505 49.0337 826.228 49.0337 824.564V811.938L91.084 810.724H91.1006Z"
          fill="url(#paint58_linear_2079_14077)"
        />
        <path
          d="M57.9293 779.002C57.9293 779.002 54.2547 794.505 53.8722 797.15C53.5064 799.795 49.4494 807.697 49.0836 812.587C48.7178 817.478 49.4161 823.184 57.6965 822.718C65.4282 822.285 80.5756 822.485 84.4664 821.021C88.3405 819.541 91.4332 815.432 91.0674 809.094C90.7016 802.756 84.7823 797.949 84.2336 776.041C84.2336 776.041 81.8393 769.42 70.0672 769.87C58.2951 770.335 57.9293 779.019 57.9293 779.019V779.002Z"
          fill="url(#paint59_linear_2079_14077)"
        />
        <path
          d="M60.0576 777.272C60.5897 776.523 60.4068 775.043 63.0006 773.33C66.8415 770.801 76.6184 772.182 77.749 772.781C78.9296 773.396 82.0056 775.625 82.4712 775.991C82.9367 776.357 78.2478 795.221 80.0436 803.921C80.3429 805.351 56.5493 805.035 56.4495 804.337C56.3498 803.621 61.6372 783.077 61.737 781.447C61.8367 779.817 60.1574 777.688 60.0576 777.289V777.272Z"
          fill="url(#paint60_linear_2079_14077)"
        />
        <path
          d="M62.1028 778.819C62.1028 778.819 63.2002 778.02 63.7156 778.32C64.2144 778.619 66.5256 783.011 66.5256 783.011C66.5256 783.011 61.3545 797.583 56.1835 805.917C56.2832 803.904 58.4947 797.383 58.5944 796.867C58.6942 796.368 62.1028 778.819 62.1028 778.819Z"
          fill="url(#paint61_linear_2079_14077)"
        />
        <path
          d="M57.7962 779.7C57.7962 779.7 62.8509 778.004 63.25 777.704C63.649 777.405 65.4115 780.582 65.4115 782.794C65.4115 785.007 56.1667 805.9 56.1667 805.9L57.7796 779.7H57.7962Z"
          fill="url(#paint62_linear_2079_14077)"
        />
        <path
          d="M76.7514 777.704L75.9865 777.97C75.9865 777.97 75.3713 782.545 75.5542 783.21C75.9533 784.657 76.7847 798.98 79.578 806.083L76.7514 777.721V777.704Z"
          fill="url(#paint63_linear_2079_14077)"
        />
        <path
          d="M84.2336 778.32C84.2336 778.32 76.3522 777.006 76.053 777.205C75.7537 777.405 76.452 781.597 76.452 782.695C76.452 783.793 78.6634 806.299 80.1765 807.414C81.673 808.512 84.2336 778.32 84.2336 778.32Z"
          fill="url(#paint64_linear_2079_14077)"
        />
        <path
          d="M59.7583 796.335C64.9626 799.213 71.148 799.529 76.1694 802.739C77.483 803.571 78.6968 801.492 77.3832 800.66C72.3285 797.45 66.1598 797.134 60.9721 794.256C59.6087 793.507 58.3949 795.586 59.7583 796.335Z"
          fill="url(#paint65_linear_2079_14077)"
        />
        <path
          d="M58.5944 803.355C61.8034 802.024 64.9792 800.544 68.2548 799.429C70.9817 798.498 73.958 798.415 76.5352 797.084C77.9152 796.369 76.7015 794.289 75.3214 795.005C72.661 796.385 69.2691 796.468 66.4258 797.55C63.5659 798.631 60.7725 799.878 57.9459 801.043C56.5326 801.625 57.1478 803.954 58.5944 803.372V803.355Z"
          fill="url(#paint66_linear_2079_14077)"
        />
        <path
          d="M63.0838 790.047C66.4092 792.759 69.5352 794.988 73.825 795.952C75.3381 796.285 75.9865 793.973 74.4735 793.624C70.5993 792.759 67.8226 790.796 64.7964 788.334C63.5992 787.369 61.8866 789.049 63.1004 790.031L63.0838 790.047Z"
          fill="url(#paint67_linear_2079_14077)"
        />
        <path
          d="M62.4686 795.986C64.6467 794.671 67.1076 794.239 69.452 793.341C71.5304 792.559 73.2264 791.062 75.3048 790.313C76.7514 789.797 76.1195 787.452 74.6563 787.984C72.2953 788.833 70.4164 790.463 68.0221 791.278C65.6943 792.076 63.3664 792.609 61.2382 793.89C59.908 794.688 61.1218 796.767 62.4519 795.969L62.4686 795.986Z"
          fill="url(#paint68_linear_2079_14077)"
        />
        <path
          d="M65.2287 783.926C66.7085 785.024 68.1551 785.789 69.8677 786.454C71.5969 787.119 73.0269 788.4 74.8392 788.833C76.3523 789.182 76.9842 786.87 75.4877 786.504C73.9247 786.138 72.6278 784.974 71.1314 784.358C69.4188 783.659 67.9556 782.944 66.4591 781.83C65.2121 780.898 64.0149 782.994 65.2453 783.909L65.2287 783.926Z"
          fill="url(#paint69_linear_2079_14077)"
        />
        <path
          d="M64.2311 789.082C68.0221 788.118 71.5471 786.487 74.7229 784.192C75.9699 783.294 74.7727 781.198 73.5091 782.113C70.4829 784.308 67.2073 785.855 63.5992 786.77C62.1028 787.153 62.7346 789.482 64.2477 789.099L64.2311 789.082Z"
          fill="url(#paint70_linear_2079_14077)"
        />
        <path
          d="M56.0837 802.689C60.0409 803.754 64.0481 804.835 68.1883 804.819C72.262 804.802 76.3024 803.737 80.2763 802.956C81.7894 802.656 81.1575 800.327 79.6278 800.627C75.8701 801.359 72.0292 802.39 68.1717 802.407C64.2476 802.423 60.4566 801.375 56.7155 800.361C55.219 799.961 54.5706 802.274 56.067 802.689H56.0837Z"
          fill="#1C5389"
        />
        <path
          d="M59.193 796.002C62.984 796.668 66.8083 797.316 70.6658 797.05C73.2264 796.867 76.6849 797.483 78.9296 796.219C80.2764 795.453 79.0626 793.374 77.7158 794.139C76.6184 794.755 75.4046 794.322 74.2407 794.322C72.8107 794.322 71.3974 794.605 69.9675 794.688C66.5423 794.871 63.1835 794.289 59.8248 793.69C58.3118 793.424 57.6633 795.753 59.1764 796.019L59.193 796.002Z"
          fill="#1C5389"
        />
        <path
          d="M61.9698 788.966C67.2073 790.497 72.5114 791.528 77.5994 788.999C78.9961 788.317 77.7657 786.221 76.3856 786.92C71.9295 789.132 67.1408 787.968 62.6016 786.654C61.1051 786.221 60.4733 788.55 61.9531 788.983L61.9698 788.966Z"
          fill="#1C5389"
        />
        <path
          d="M76.3357 781.064C76.3357 781.064 75.5542 777.604 76.053 777.205C76.5518 776.806 84.2336 778.32 84.2336 778.32C84.2336 778.32 77.1504 777.505 76.7514 777.704C76.3523 777.921 76.3357 781.064 76.3357 781.064Z"
          fill="url(#paint71_linear_2079_14077)"
        />
        <path
          d="M57.7963 779.7C57.7963 779.7 63.0172 777.505 63.383 777.688C64.0315 778.004 64.7298 779.833 64.7298 779.833C64.7298 779.833 62.8676 778.336 62.4186 778.503C61.9697 778.669 57.8129 779.7 57.8129 779.7H57.7963Z"
          fill="url(#paint72_linear_2079_14077)"
        />
        <path
          d="M63.7987 783.759C66.2263 783.46 68.6705 783.327 71.1147 783.41C73.1931 783.476 75.1385 783.626 77.1504 783.011C78.6302 782.561 77.9984 780.233 76.502 780.682C74.3903 781.314 72.2953 780.998 70.1171 780.965C67.9888 780.931 65.8771 781.081 63.7655 781.347C62.2524 781.53 62.2191 783.942 63.7655 783.759H63.7987Z"
          fill="#1C5389"
        />
        <path
          d="M49.1833 825.412C49.1833 825.412 50.3639 829.588 53.5729 830.403C55.801 830.968 56.516 831.035 66.5089 830.819C76.5019 830.602 83.5851 830.835 86.1956 829.987C88.8061 829.139 90.8512 827.359 91.084 825.046C91.084 825.046 89.7538 828.49 84.5329 829.105C80.3761 829.588 57.7963 830.087 54.3711 829.488C52.3924 829.138 49.9316 827.026 49.1833 825.396V825.412Z"
          fill="url(#paint73_linear_2079_14077)"
        />
        <path
          d="M49.0836 814.899C49.0836 814.899 49.1169 820.871 53.5231 821.387C57.9293 821.903 83.7016 822.601 86.3287 819.79C88.9558 816.979 91.1007 814.716 91.0841 809.792C91.0841 809.792 92.1316 819.79 84.932 821.919C79.7776 823.45 57.1645 823.383 53.3236 822.784C47.8033 821.919 49.067 814.899 49.067 814.899H49.0836Z"
          fill="url(#paint74_linear_2079_14077)"
        />
        <path
          d="M91.4998 412.939C91.4998 412.939 94.1934 408.997 101.393 448.338C108.593 487.679 117.139 566.943 126.583 597.734C136.028 628.525 144.059 641.832 167.071 697.525C172.591 710.883 187.605 757.393 187.605 757.393C187.605 757.393 206.627 759.756 213.826 748.91C213.826 748.91 204.565 679.41 198.413 658.218C187.622 621.122 174.57 598.848 170.961 580.6C167.37 562.352 171.743 453.262 169.049 404.223L91.4998 412.939Z"
          fill="#2D5481"
        />
        <path
          d="M91.4998 412.939C91.4998 412.939 92.0152 487.196 91.8489 514.111C91.6827 541.009 89.0888 578.171 86.4783 593.043C83.8679 607.914 80.6255 616.83 80.6255 621.288C80.6255 625.746 83.236 669.612 83.8845 682.986C84.5329 696.361 83.8845 761.785 83.8845 761.785C83.8845 761.785 68.2715 767.723 56.5493 761.785C56.5493 761.785 38.5918 671.093 38.3258 662.176C37.7106 641.999 42.8817 596.752 42.2332 585.607C41.5848 574.462 19.3042 453.561 16.7104 430.522C14.0999 407.483 14.9146 390.565 17.5085 367.061L91.4998 412.939Z"
          fill="#2D5481"
        />
        <path
          d="M168.983 402.393C166.472 376.626 159.921 360.939 151.74 336.17L147.983 317.572C147.983 317.572 85.065 327.57 37.1286 312.333C37.1286 312.333 11.2067 356.764 11.6557 397.136C11.8718 416.948 26.8031 488.61 33.8697 522.412C55.0528 522.878 67.9057 525.889 87.1101 534.855C87.0602 501.452 90.685 425.648 91.2503 413.089C97.2029 390.915 104.502 474.803 110.87 508.023C133.45 501.402 144.358 490.64 167.769 487.263C167.786 474.454 169.681 409.446 168.983 402.393Z"
          fill="#2D5481"
        />
        <path
          d="M52.6918 333.359C52.6918 333.359 91.1007 338.749 92.2147 339.131C93.3288 339.514 93.3953 347.948 90.6351 347.881C87.5757 347.814 52.4423 343.839 50.3307 344.138C50.3307 344.138 53.4566 338.782 52.6918 333.359Z"
          fill="url(#paint75_linear_2079_14077)"
        />
        <path
          d="M93.7278 337.068L123.208 336.952C123.208 336.952 122.925 344.387 124.023 349.195L92.8466 348.596L93.7278 337.068Z"
          fill="url(#paint76_linear_2079_14077)"
        />
        <path
          d="M89.8537 326.938C91.4665 328.551 91.4665 331.18 89.8537 332.81C88.2408 334.44 85.6303 334.423 84.0175 332.81C82.4047 331.196 82.4047 328.568 84.0175 326.938C85.6303 325.324 88.2408 325.324 89.8537 326.938Z"
          fill="url(#paint77_linear_2079_14077)"
        />
        <path
          d="M89.7872 329.366C89.7872 331.296 88.2242 332.86 86.2954 332.86C84.3667 332.86 82.8037 331.296 82.8037 329.366C82.8037 327.437 84.3667 325.856 86.2954 325.856C88.2242 325.856 89.7872 327.42 89.7872 329.366Z"
          fill="url(#paint78_linear_2079_14077)"
        />
        <path
          d="M83.4023 329.366C83.4023 329.366 83.4522 332.144 85.9297 332.311C88.4071 332.477 90.286 329.649 88.457 327.354C88.457 327.354 88.9725 330.431 86.944 331.013C85.1648 331.529 83.7681 330.631 83.419 329.35L83.4023 329.366Z"
          fill="#6E89A8"
        />
        <path
          d="M123.208 336.952C123.208 336.952 122.376 350.742 131.538 356.597C140.683 362.453 160.42 362.053 160.42 362.053L151.757 336.17L123.224 336.952H123.208Z"
          fill="url(#paint79_linear_2079_14077)"
        />
        <path
          d="M169.482 414.02C169.482 414.02 142.429 424.899 133.284 420.541C124.139 416.183 114.063 320.567 114.063 320.567C114.063 320.567 131.156 320.251 147.55 316.458L169.149 393.743L169.482 414.02Z"
          fill="url(#paint80_linear_2079_14077)"
        />
        <path
          d="M30.2616 325.707C38.9244 330.98 53.3236 333.259 53.3236 333.259C53.3236 333.259 51.0789 350.892 38.6085 352.505C26.138 354.119 19.6866 352.056 19.6866 352.056C19.6866 352.056 25.1736 335.605 30.2616 325.707Z"
          fill="url(#paint81_linear_2079_14077)"
        />
        <path
          d="M104.835 398.7C104.835 398.7 146.752 389.717 166.106 396.803L104.835 398.7Z"
          fill="url(#paint82_linear_2079_14077)"
        />
        <path
          d="M90.8512 545.684C89.5377 537.4 62.1359 400.712 52.7582 388.952C52.7582 388.952 67.1574 402.392 108.376 399.548C108.376 399.548 108.277 457.204 110.887 510.568C110.887 510.568 96.97 409.346 91.5163 412.939C92.4807 431.819 92.4807 521.147 90.8512 545.684Z"
          fill="url(#paint83_linear_2079_14077)"
        />
        <path
          d="M91.8821 507.091C91.8821 507.091 92.6303 431.57 91.4997 412.939C96.305 411.276 107.445 486.997 107.445 486.997C107.445 486.997 101.609 423.585 101.077 412.723C100.944 410.178 101.825 407.732 101.26 407.732C100.695 407.732 85.73 407.25 73.9247 402.376C73.9247 402.376 81.2906 411.991 82.2383 413.854C83.1694 415.734 91.8987 507.058 91.8987 507.058L91.8821 507.091Z"
          fill="url(#paint84_linear_2079_14077)"
        />
        <path
          d="M12.5369 409.113C12.5369 409.113 53.4399 424.899 62.5849 420.541C71.7299 416.183 81.8559 320.084 81.8559 320.084C81.8559 320.084 64.497 319.169 48.3187 316.475L11.6556 398.949L12.5369 409.113Z"
          fill="url(#paint85_linear_2079_14077)"
        />
        <path
          d="M93.0959 320.65C93.0959 320.65 94.2931 330.281 94.1434 331.313C93.9938 332.344 93.8275 338 92.5638 338.2L91.3002 338.399C91.3002 338.399 90.2028 351.424 90.2028 364.748C90.2028 378.073 93.0959 390.382 93.3453 392.878C93.5282 388.037 92.8797 366.329 93.0294 364.998C93.179 363.684 93.3453 340.262 94.7586 339.314C96.1719 338.366 96.0223 335.555 96.0223 335.555L96.2717 320.75L93.0793 320.633L93.0959 320.65Z"
          fill="url(#paint86_linear_2079_14077)"
        />
        <path
          d="M31.9243 327.038C31.9243 327.038 50.1478 332.777 53.3236 333.259C56.4994 333.741 69.6017 335.006 84.1173 337.833C84.1173 337.833 56.4828 336.702 53.3236 336.17C50.1478 335.621 31.9243 327.038 31.9243 327.038Z"
          fill="url(#paint87_linear_2079_14077)"
        />
        <path
          d="M92.9629 337.068C92.9629 337.068 122.11 335.971 122.609 336.17C123.125 336.37 145.206 334.457 151.74 336.17C151.74 336.17 126.766 338.532 122.609 338.682C118.452 338.815 92.9629 337.068 92.9629 337.068Z"
          fill="url(#paint88_linear_2079_14077)"
        />
        <path
          d="M123.125 338.017C123.125 338.017 123.69 346.949 127.614 351.773C131.538 356.598 139.636 361.721 160.403 362.054C160.403 362.054 142.895 367.044 130.54 359.791C120.182 353.72 123.108 338.017 123.108 338.017H123.125Z"
          fill="url(#paint89_linear_2079_14077)"
        />
        <path
          d="M53.0409 334.024C53.0409 334.024 51.8105 342.058 47.9031 346.883C43.9791 351.707 31.9576 353.237 19.6866 352.056C19.6866 352.056 34.2022 358.926 45.0765 352.804C55.5516 346.932 53.0409 334.024 53.0409 334.024Z"
          fill="url(#paint90_linear_2079_14077)"
        />
        <path
          d="M79.6777 320.001C79.6777 320.001 93.1458 320.334 94.6423 320.766C96.1387 321.199 95.457 331.529 95.3905 333.176C95.324 334.822 95.3905 340.395 92.9795 340.012C90.552 339.63 84.1338 337.817 84.1338 337.817C84.1338 337.817 90.5852 338.066 92.0983 337.085C93.6114 336.103 93.7943 323.062 92.8631 323.078C91.932 323.095 79.6777 320.001 79.6777 320.001Z"
          fill="url(#paint91_linear_2079_14077)"
        />
        <path
          d="M91.1838 340.046C91.1838 340.046 90.4688 356.414 90.6517 358.56C90.8346 360.706 93.4451 394.075 93.4451 394.075C93.4451 394.075 91.35 380.867 90.6184 379.121C89.8868 377.374 88.4902 356.78 88.3571 355C88.2241 353.221 91.1838 340.046 91.1838 340.046Z"
          fill="url(#paint92_linear_2079_14077)"
        />
        <path
          d="M107.262 320.75C107.262 320.75 108.942 324.958 108.942 326.256C108.942 327.553 110.438 345.352 110.438 346.018C110.438 346.683 111.369 348.596 111.369 348.596L112.683 324.226L110.621 320.716L107.279 320.75H107.262Z"
          fill="url(#paint93_linear_2079_14077)"
        />
        <path
          d="M115.709 336.17H120.381L118.22 320.384L116.524 320.45L115.709 336.17Z"
          fill="url(#paint94_linear_2079_14077)"
        />
        <path
          d="M57.181 317.207L58.611 317.456L58.5777 334.207L52.8247 333.259L57.181 317.207Z"
          fill="url(#paint95_linear_2079_14077)"
        />
        <path
          d="M64.68 318.404L67.1408 318.737L63.433 332.128L63.3997 340.046L61.3878 344.138L58.5446 340.778L64.68 318.404Z"
          fill="url(#paint96_linear_2079_14077)"
        />
        <path
          d="M65.0125 318.454C65.0125 318.454 62.7845 323.245 62.6515 324.626C62.5185 326.006 60.1241 334.556 61.3878 344.138L54.8034 343.789C54.8034 343.789 54.6704 334.307 55.6514 330.381C56.6324 326.439 58.5778 317.473 58.5778 317.473L65.0125 318.471V318.454Z"
          fill="#2D5481"
        />
        <path
          d="M64.9793 318.471C64.9793 318.471 61.2216 330.897 61.2881 333.259C61.3546 335.621 61.4045 344.138 61.4045 344.138L54.8201 343.789L58.5612 340.778C58.5612 340.778 60.0078 330.83 59.9413 329.949C59.8747 329.067 64.9959 318.471 64.9959 318.471H64.9793Z"
          fill="url(#paint97_linear_2079_14077)"
        />
        <path
          d="M109.54 320.716L116.524 320.45C116.524 320.45 118.186 328.485 118.336 333.808C118.519 340.079 118.918 347.531 118.918 348.563C118.469 348.347 111.353 348.563 111.353 348.563C111.353 348.563 112.201 334.141 111.918 330.065C111.635 325.99 109.54 320.7 109.54 320.7V320.716Z"
          fill="#2D5481"
        />
        <path
          d="M109.54 320.716C109.54 320.716 112.051 332.277 111.669 338.016C111.253 344.304 111.353 348.596 111.353 348.596H118.918C118.918 348.596 112.849 348.38 113.032 346.134C113.215 343.888 115.327 327.769 109.54 320.733V320.716Z"
          fill="url(#paint98_linear_2079_14077)"
        />
        <path
          d="M80.3429 339.131C80.3429 339.131 80.7585 371.835 80.6089 374.313C80.4592 376.808 79.9771 390.183 91.7824 393.06C91.7824 393.06 79.4949 393.676 78.5637 382.714C77.616 371.751 80.3429 339.131 80.3429 339.131Z"
          fill="url(#paint99_linear_2079_14077)"
        />
        <path
          d="M166.821 404.239C166.821 404.239 154.667 397.818 126.118 403.474L166.821 404.239Z"
          fill="url(#paint100_linear_2079_14077)"
        />
        <path
          d="M78.1148 567.525C77.9485 562.102 66.2263 474.837 59.7915 428.859C46.4232 426.679 33.3043 423.153 21.9147 415.501C28.6986 454.16 49.0171 566.81 50.9459 574.745C56.1834 596.436 78.6801 586.672 78.1148 567.509V567.525Z"
          fill="url(#paint101_linear_2079_14077)"
        />
        <path
          d="M82.7372 597.102C82.7372 597.102 78.996 609.012 50.6798 612.439C50.6798 612.439 89.7871 610.326 82.7372 597.102Z"
          fill="url(#paint102_linear_2079_14077)"
        />
        <path
          d="M55.801 616.98C55.801 616.98 68.9199 616.198 78.0649 607.881C78.0649 607.881 83.369 616.731 55.801 616.98Z"
          fill="url(#paint103_linear_2079_14077)"
        />
        <path
          d="M115.277 442.881C116.574 425.232 154.201 422.604 160.47 436.394C166.738 450.201 161.65 557.511 157.427 576.175C151.258 603.406 130.507 595.172 129.31 582.579C126.118 548.711 112.517 480.21 115.277 442.881Z"
          fill="url(#paint104_linear_2079_14077)"
        />
        <path
          d="M146.918 612.372C146.918 612.372 165.524 606.949 173.189 597.268C173.189 597.268 174.902 608.713 146.918 612.372Z"
          fill="url(#paint105_linear_2079_14077)"
        />
        <path
          d="M169.182 588.634C169.182 588.634 166.721 596.952 152.089 600.894C152.089 600.894 172.325 598.781 169.182 588.634Z"
          fill="url(#paint106_linear_2079_14077)"
        />
        <path
          d="M133.866 619.026C133.866 619.026 150.626 647.371 155.182 651.264C159.738 655.156 192.394 753.401 192.394 753.401L186.125 752.303C186.125 752.303 154.933 668.115 151.341 660.43C147.75 652.744 136.759 626.977 133.866 619.026Z"
          fill="url(#paint107_linear_2079_14077)"
        />
        <path
          d="M80.6255 621.289C80.6255 621.289 87.0603 713.994 83.8845 761.785L79.578 763.066C79.578 763.066 77.882 665.72 75.9699 646.44C75.2383 638.988 80.6255 621.289 80.6255 621.289Z"
          fill="url(#paint108_linear_2079_14077)"
        />
        <path
          d="M84.35 752.819C84.35 752.819 86.894 758.558 78.6303 762.201C70.3665 765.844 61.4377 764.946 59.908 764.147C58.3783 763.349 56.3331 760.754 55.6348 757.194C55.6348 757.194 56.0837 760.754 61.4377 761.369C66.7917 762.001 75.5043 760.537 78.8797 758.508C81.6065 756.878 83.9842 754.898 84.35 752.802V752.819Z"
          fill="url(#paint109_linear_2079_14077)"
        />
        <path
          d="M55.6847 757.41C55.6847 757.41 56.9982 763.016 63.4662 763.016C69.9342 763.016 83.5187 761.502 84.3501 752.819C84.3501 752.819 85.8632 755.248 86.0627 760.504C86.2788 765.761 73.6587 770.069 67.806 769.836C62.7845 769.637 54.0552 769.836 55.6681 757.41H55.6847Z"
          fill="url(#paint110_linear_2079_14077)"
        />
        <path
          d="M184.446 747.745C184.446 747.745 185.51 756.578 193.392 755.248C201.273 753.933 212.263 748.677 212.845 741.74C212.845 741.74 210.8 748.91 193.325 751.305C187.422 752.12 184.446 747.745 184.446 747.745Z"
          fill="url(#paint111_linear_2079_14077)"
        />
        <path
          d="M184.446 747.745C184.446 747.745 185.992 752.935 192.411 753.401C198.829 753.85 212.047 747.779 212.845 741.74C212.845 741.74 216.354 749.758 213.577 752.952C210.8 756.146 202.869 761.918 193.691 760.704C186.79 759.789 184.912 754.765 184.712 752.486C184.496 750.207 184.446 747.745 184.446 747.745Z"
          fill="url(#paint112_linear_2079_14077)"
        />
        <path
          d="M184.197 806.299C184.197 806.299 182.916 818.742 182.916 820.838C182.916 822.934 186.325 829.704 191.895 830.702C196.634 831.55 215.389 831.999 220.128 829.521C222.656 828.207 224.983 823.266 224.983 821.32C224.983 817.561 224.983 811.955 224.983 811.955L184.197 806.282V806.299Z"
          fill="url(#paint113_linear_2079_14077)"
        />
        <path
          d="M216.088 769.52C216.088 769.52 219.762 787.602 220.145 790.679C220.511 793.757 224.568 802.972 224.933 808.678C225.299 814.384 224.268 819.075 216.237 821.187C207.425 823.499 194.672 822.235 189.783 819.341C186.125 817.162 184.08 812.321 184.446 804.935C184.812 797.549 189.235 791.594 189.783 766.043C189.783 766.043 192.178 758.308 203.95 758.857C215.722 759.406 216.088 769.52 216.088 769.52Z"
          fill="url(#paint114_linear_2079_14077)"
        />
        <path
          d="M213.959 767.524C213.427 766.659 213.61 764.912 211.016 762.916C207.176 759.972 197.399 761.569 196.268 762.267C195.087 762.999 192.011 765.594 191.546 766.01C191.08 766.426 195.769 788.45 193.973 798.581C193.674 800.244 217.468 799.895 217.568 799.063C217.667 798.231 212.38 774.261 212.28 772.364C212.18 770.468 213.86 767.973 213.959 767.507V767.524Z"
          fill="url(#paint115_linear_2079_14077)"
        />
        <path
          d="M211.482 769.42C211.482 769.42 210.301 768.438 209.769 768.804C209.237 769.17 206.776 774.56 206.776 774.56C206.776 774.56 212.28 792.426 217.784 802.639C217.667 800.161 215.323 792.176 215.223 791.561C215.123 790.945 211.482 769.42 211.482 769.42Z"
          fill="url(#paint116_linear_2079_14077)"
        />
        <path
          d="M216.071 770.501C216.071 770.501 210.684 768.422 210.268 768.056C209.836 767.69 207.974 771.582 207.974 774.294C207.974 777.005 217.8 802.623 217.8 802.623L216.088 770.484L216.071 770.501Z"
          fill="url(#paint117_linear_2079_14077)"
        />
        <path
          d="M195.886 768.056L196.7 768.372C196.7 768.372 197.365 773.978 197.166 774.793C196.75 776.556 195.852 794.139 192.876 802.839L195.886 768.056Z"
          fill="url(#paint118_linear_2079_14077)"
        />
        <path
          d="M189.734 768.405C189.734 768.405 196.318 767.191 196.634 767.441C196.95 767.69 196.202 772.814 196.202 774.178C196.202 775.542 193.84 803.122 192.244 804.486C190.648 805.833 189.717 768.422 189.717 768.422L189.734 768.405Z"
          fill="url(#paint119_linear_2079_14077)"
        />
        <path
          d="M213.976 790.879C208.456 794.405 201.871 794.804 196.517 798.73C195.121 799.762 193.841 797.2 195.221 796.185C200.591 792.243 207.159 791.86 212.679 788.334C214.126 787.419 215.423 789.964 213.976 790.879Z"
          fill="url(#paint120_linear_2079_14077)"
        />
        <path
          d="M215.206 799.495C211.798 797.865 208.406 796.052 204.931 794.671C202.021 793.523 198.878 793.424 196.135 791.793C194.672 790.912 195.969 788.367 197.432 789.248C200.275 790.945 203.867 791.045 206.893 792.376C209.936 793.706 212.912 795.22 215.905 796.651C217.401 797.366 216.753 800.227 215.223 799.495H215.206Z"
          fill="url(#paint121_linear_2079_14077)"
        />
        <path
          d="M210.434 783.177C206.893 786.504 203.567 789.232 199.012 790.413C197.399 790.829 196.717 787.984 198.33 787.569C202.453 786.504 205.413 784.109 208.622 781.098C209.886 779.9 211.715 781.979 210.434 783.194V783.177Z"
          fill="url(#paint122_linear_2079_14077)"
        />
        <path
          d="M211.1 790.463C208.772 788.849 206.178 788.317 203.667 787.236C201.456 786.271 199.643 784.441 197.449 783.526C195.902 782.877 196.567 780.033 198.13 780.681C200.641 781.729 202.636 783.726 205.18 784.724C207.658 785.688 210.135 786.354 212.396 787.918C213.81 788.899 212.529 791.461 211.1 790.463Z"
          fill="url(#paint123_linear_2079_14077)"
        />
        <path
          d="M208.157 775.674C206.577 777.022 205.047 777.97 203.235 778.785C201.406 779.617 199.876 781.164 197.947 781.696C196.351 782.129 195.653 779.284 197.266 778.852C198.928 778.402 200.308 776.972 201.905 776.223C203.734 775.375 205.28 774.493 206.876 773.129C208.206 771.998 209.487 774.56 208.173 775.674H208.157Z"
          fill="url(#paint124_linear_2079_14077)"
        />
        <path
          d="M209.221 782.012C205.18 780.831 201.439 778.835 198.064 776.007C196.734 774.893 198.014 772.331 199.361 773.462C202.57 776.157 206.062 778.037 209.902 779.168C211.499 779.634 210.817 782.478 209.221 782.012Z"
          fill="url(#paint125_linear_2079_14077)"
        />
        <path
          d="M217.867 798.68C213.66 799.994 209.387 801.325 204.997 801.292C200.674 801.275 196.368 799.961 192.145 799.013C190.532 798.647 191.213 795.803 192.826 796.168C196.833 797.067 200.907 798.331 204.997 798.348C209.171 798.364 213.195 797.083 217.185 795.836C218.781 795.337 219.463 798.181 217.867 798.68Z"
          fill="#1C5389"
        />
        <path
          d="M214.558 790.479C210.518 791.294 206.461 792.093 202.354 791.76C199.627 791.527 195.952 792.292 193.558 790.745C192.128 789.814 193.408 787.252 194.855 788.2C196.035 788.966 197.299 788.417 198.546 788.417C200.059 788.417 201.572 788.766 203.085 788.849C206.727 789.065 210.302 788.367 213.86 787.635C215.473 787.302 216.171 790.163 214.541 790.479H214.558Z"
          fill="#1C5389"
        />
        <path
          d="M211.615 781.863C206.045 783.726 200.408 784.99 194.988 781.896C193.508 781.048 194.805 778.503 196.285 779.351C201.023 782.062 206.111 780.632 210.933 779.018C212.513 778.486 213.195 781.33 211.615 781.863Z"
          fill="#1C5389"
        />
        <path
          d="M196.335 772.182C196.335 772.182 197.166 767.94 196.634 767.457C196.102 766.958 189.734 768.422 189.734 768.422C189.734 768.422 195.453 767.807 195.886 768.056C196.318 768.306 196.335 772.182 196.335 772.182Z"
          fill="url(#paint126_linear_2079_14077)"
        />
        <path
          d="M216.071 770.501C216.071 770.501 210.518 767.806 210.135 768.023C209.453 768.405 208.705 770.651 208.705 770.651C208.705 770.651 210.684 768.821 211.166 769.021C211.648 769.22 216.071 770.485 216.071 770.485V770.501Z"
          fill="url(#paint127_linear_2079_14077)"
        />
        <path
          d="M209.67 775.475C207.076 775.109 204.499 774.943 201.888 775.042C199.677 775.126 197.615 775.309 195.47 774.56C193.89 774.011 194.572 771.167 196.152 771.715C198.396 772.497 200.641 772.098 202.936 772.065C205.197 772.032 207.442 772.214 209.686 772.531C211.299 772.763 211.316 775.724 209.686 775.492L209.67 775.475Z"
          fill="#1C5389"
        />
        <path
          d="M225 821.337C225 821.337 224.717 828.207 220.112 830.004C213.012 832.782 193.275 832.549 188.22 830.004C185.71 828.739 182.933 826.261 182.933 821.104C182.933 821.104 184.163 828.091 189.783 829.405C199.46 831.65 212.596 831.052 219.28 828.989C222.473 828.007 224.468 823.882 225 821.32V821.337Z"
          fill="url(#paint128_linear_2079_14077)"
        />
        <path
          d="M224.933 811.389C224.933 811.389 224.451 816.879 220.494 818.941C214.026 822.302 193.425 823.233 187.688 817.078C184.828 814.018 184.429 810.657 184.446 804.918C184.446 804.918 181.919 815.348 188.353 819.474C194.09 823.15 212.663 824.165 220.693 820.572C225.831 818.276 224.95 811.389 224.95 811.389H224.933Z"
          fill="url(#paint129_linear_2079_14077)"
        />
        <path
          d="M69.2857 148.082C69.2857 148.082 66.3759 154.22 70.8986 184.262C78.5305 235.015 78.8131 294.817 71.9627 330.581C63.9816 372.218 60.7393 412.407 52.01 412.374C43.2641 412.341 6.41806 404.323 6.76724 401.129C7.13304 397.935 16.5108 348.58 33.8863 310.919C42.8484 291.49 43.7462 271.445 42.9315 265.44L38.7082 234.233L20.1522 166.364L69.2857 148.082Z"
          fill="#2F354E"
        />
        <path
          d="M43.713 238.824L76.3191 241.719L69.2857 178.457L41.0361 182.167C41.0361 182.167 39.2071 229.925 43.6964 238.841L43.713 238.824Z"
          fill="url(#paint130_linear_2079_14077)"
        />
        <path
          d="M126.583 148.082C126.583 148.082 129.493 154.22 124.97 184.262C117.339 235.015 117.056 294.817 123.906 330.581C131.887 372.218 135.13 412.407 143.859 412.374C152.605 412.341 181.736 404.323 181.387 401.129C181.021 397.935 171.045 366.096 158.873 316.442C153.785 295.648 152.106 271.445 152.921 265.423C153.736 259.418 159.256 233.002 159.256 233.002L175.7 166.347L126.567 148.065L126.583 148.082Z"
          fill="#2F354E"
        />
        <path
          d="M152.173 238.824L119.567 241.719L126.6 178.457L154.85 182.167C154.85 182.167 156.679 229.925 152.189 238.841L152.173 238.824Z"
          fill="url(#paint131_linear_2079_14077)"
        />
        <path
          d="M125.436 266.637C125.436 266.637 130.773 265.955 131.172 265.672C131.555 265.39 141.165 249.004 141.165 249.004L143.643 258.653C143.643 258.653 132.785 272.443 131.156 272.376C129.526 272.293 124.239 271.661 124.239 271.661L125.419 266.654L125.436 266.637Z"
          fill="url(#paint132_linear_2079_14077)"
        />
        <path
          d="M124.255 291.723V301.57C124.255 301.57 137.158 298.875 138.804 298.327C140.45 297.778 149.612 288.479 149.612 288.479L155.049 296.164L153.403 282.141L150.227 275.088L124.272 291.706L124.255 291.723Z"
          fill="url(#paint133_linear_2079_14077)"
        />
        <path
          d="M159.256 211.011L158.89 218.862L145.089 223.254L127.664 227.096L128.013 219.378C128.013 219.378 156.346 212.641 159.239 211.011H159.256Z"
          fill="url(#paint134_linear_2079_14077)"
        />
        <path
          d="M127.681 227.097C127.681 227.097 147.633 225.749 158.89 219.062C158.89 219.062 132.719 226.098 127.681 227.097Z"
          fill="url(#paint135_linear_2079_14077)"
        />
        <path
          d="M128.03 219.378C128.03 219.378 144.94 216.667 159.256 211.011L128.03 219.378Z"
          fill="url(#paint136_linear_2079_14077)"
        />
        <path
          d="M37.8934 299.574C37.8934 299.574 34.9171 308.656 33.8862 310.919C33.8862 310.919 62.402 309.455 72.7275 307.658L73.5921 297.578L37.8768 299.574H37.8934Z"
          fill="url(#paint137_linear_2079_14077)"
        />
        <path
          d="M195.337 270.862C195.337 270.862 189.418 244.297 185.277 216.367C181.935 193.777 182.534 169.208 175.7 166.38C164.011 188.321 160.486 228.91 160.486 228.91C160.486 228.91 179.574 269.398 195.32 270.879L195.337 270.862Z"
          fill="#2F354E"
        />
        <path
          d="M48.2356 262.212L48.9506 266.837L70.2335 268.9V264.591L48.2356 262.212Z"
          fill="url(#paint138_linear_2079_14077)"
        />
        <path
          d="M192.011 269.482C192.011 269.482 187.323 253.612 184.862 248.705C182.999 245.029 140.65 180.303 140.65 180.303L124.804 185.477L192.011 269.482Z"
          fill="url(#paint139_linear_2079_14077)"
        />
        <path
          d="M154.983 268.151L146.303 229.16L135.811 212.059C135.495 217.083 132.004 227.746 130.457 232.27C136.31 250.086 146.469 277.966 146.469 277.966L154.983 268.151Z"
          fill="url(#paint140_linear_2079_14077)"
        />
        <path
          d="M59.9079 153.555L49.2166 182.166L62.3687 184.395L51.9933 190.218C51.9933 190.218 67.3735 238.258 67.9721 249.537C68.554 260.815 73.5256 314.229 69.6182 340.612C69.6182 340.612 79.0126 234.25 67.9721 184.113L59.9079 153.538V153.555Z"
          fill="url(#paint141_linear_2079_14077)"
        />
        <path
          d="M73.6088 141.378C73.6088 141.378 62.402 144.938 58.5944 160.242C54.7867 175.546 52.9078 180.686 52.9078 180.686L64.8961 183.214L55.0195 187.24C55.0195 187.24 67.7227 231.987 70.3498 247.807C75.3879 278.132 69.6182 340.645 69.6182 340.645C69.6182 340.645 80.4259 281.126 77.7822 244.696C75.3546 211.26 71.0981 163.402 69.7014 158.013C67.4401 149.313 73.6254 141.395 73.6254 141.395L73.6088 141.378Z"
          fill="url(#paint142_linear_2079_14077)"
        />
        <path
          d="M135.961 153.555L146.652 182.166L133.5 184.395L143.876 190.218C143.876 190.218 128.495 238.258 127.897 249.537C127.298 260.815 122.343 314.229 126.251 340.612C126.251 340.612 116.856 234.25 127.897 184.113L135.961 153.538V153.555Z"
          fill="url(#paint143_linear_2079_14077)"
        />
        <path
          d="M122.792 141.378C122.792 141.378 133.484 144.938 137.291 160.242C141.099 175.546 142.978 180.686 142.978 180.686L130.989 183.214L140.866 187.24C140.866 187.24 128.163 231.987 125.536 247.807C120.498 278.132 126.267 340.645 126.267 340.645C126.267 340.645 115.46 281.126 118.103 244.696C120.531 211.26 124.788 163.402 126.184 158.013C128.446 149.313 122.792 141.395 122.792 141.395V141.378Z"
          fill="url(#paint144_linear_2079_14077)"
        />
        <path
          d="M58.7939 323.744C58.5278 326.139 60.0076 328.269 62.1027 328.501C64.1977 328.734 66.1265 326.988 66.3925 324.592C66.6586 322.197 65.1787 320.068 63.0837 319.835C60.9887 319.602 59.0599 321.349 58.7939 323.744Z"
          fill="url(#paint145_linear_2079_14077)"
        />
        <path
          d="M65.3949 324.11C65.3949 326.023 64.0148 327.587 62.3188 327.587C60.6229 327.587 59.2428 326.04 59.2428 324.11C59.2428 322.18 60.6229 320.633 62.3188 320.633C64.0148 320.633 65.3949 322.18 65.3949 324.11Z"
          fill="url(#paint146_linear_2079_14077)"
        />
        <path
          d="M122.643 190.301C122.127 195.275 121.279 205.704 120.814 210.379C124.305 219.561 127.032 224.419 129.194 231.022C130.757 226.498 133.484 214.105 135.13 207.767L122.643 190.317V190.301Z"
          fill="url(#paint147_linear_2079_14077)"
        />
        <path
          d="M161.999 210.179C171.128 230.773 182.534 255.359 182.534 255.359L180.456 268.218L160.636 249.87L161.999 210.179Z"
          fill="url(#paint148_linear_2079_14077)"
        />
        <path
          d="M160.636 249.869C160.636 249.869 157.31 283.887 157.061 289.427C157.061 289.427 154.384 245.711 154.384 242.4C154.384 239.09 160.154 201.662 161.351 199.1C162.548 196.539 161.567 232.47 162.465 234.948L160.636 249.853V249.869Z"
          fill="url(#paint149_linear_2079_14077)"
        />
        <path
          d="M112.134 270.097C112.134 270.097 131.671 270.097 132.02 270.097C132.37 270.097 141.315 255.409 141.315 255.409L148.997 279.695C148.997 279.695 140.018 289.294 135.412 289.743C130.79 290.192 110.006 292.504 110.006 292.504L112.151 270.114L112.134 270.097Z"
          fill="#F7D4AB"
        />
        <path
          d="M141.165 255.692C141.165 255.692 132.902 269 130.574 269.482C129.975 269.615 114.678 270.048 114.678 270.048C114.678 270.048 129.892 272.925 132.203 272.277C134.514 271.628 141.149 255.692 141.149 255.692H141.165Z"
          fill="url(#paint150_linear_2079_14077)"
        />
        <path
          d="M98.4499 293.602C115.493 290.941 130.457 289.194 135.545 287.464C140.633 285.734 150.477 277.184 151.275 275.088L152.804 278.881C152.804 278.881 145.222 288.762 137.175 290.924C129.676 291.49 122.875 293.07 116.673 294.467C112.5 295.399 106.315 296.297 99.1317 297.428C86.2788 299.458 84.2835 295.815 98.4499 293.602Z"
          fill="url(#paint151_linear_2079_14077)"
        />
        <path
          d="M126.517 269.681L127.564 269.631L133.949 291.24L126.517 292.371V269.681Z"
          fill="url(#paint152_linear_2079_14077)"
        />
        <path
          d="M20.1522 166.363C20.1522 166.363 43.2142 181.95 43.5634 189.12C43.9292 196.272 45.8247 227.03 45.8247 230.972C45.8247 234.915 46.5396 251.732 46.5396 264.608C46.5396 264.608 78.4972 271.944 126.517 269.698C127.315 275.287 131.156 296.713 131.156 296.713C131.156 296.713 92.8798 302.418 67.1242 303.566C42.3163 304.664 20.069 303.982 16.4942 302.202C12.9193 300.406 10.7744 269.997 11.1236 262.844C11.4894 255.691 13.9835 228.86 15.0642 221.341C16.145 213.822 10.4252 179.571 20.1522 166.413V166.363Z"
          fill="#2F354E"
        />
        <path
          d="M43.713 204.074C43.713 204.074 48.8176 227.812 49.3164 229.076C49.5991 229.775 46.8389 250.252 51.1786 271.045C52.9245 279.446 46.8389 271.594 46.8389 271.594L43.713 204.091V204.074Z"
          fill="url(#paint153_linear_2079_14077)"
        />
        <path
          d="M155.265 158.761L175.717 166.363C175.717 166.363 181.403 165.565 184.18 194.908C187.456 229.459 189.368 241.003 189.368 241.003C189.368 241.003 173.106 181.767 171.577 178.457C167.653 169.99 155.249 158.745 155.249 158.745L155.265 158.761Z"
          fill="url(#paint154_linear_2079_14077)"
        />
        <path
          d="M23.6938 165.049C23.6938 165.049 21.5322 165.498 20.1522 166.363C18.3398 167.511 13.8671 176.943 14.3825 196.605C14.8813 215.519 15.0808 221.291 15.0808 221.291C15.0808 221.291 25.6059 197.537 25.6225 183.913C25.6225 170.273 23.7104 165.049 23.7104 165.049H23.6938Z"
          fill="url(#paint155_linear_2079_14077)"
        />
        <path
          d="M143.41 251.084L139.935 257.621L148.348 283.788C148.348 283.788 152.206 280.394 152.854 278.298L143.427 251.084H143.41Z"
          fill="url(#paint156_linear_2079_14077)"
        />
        <path
          d="M125.436 212.076C125.436 212.076 132.286 197.038 144.857 194.909C144.857 194.909 138.671 213.789 125.436 212.076Z"
          fill="url(#paint157_linear_2079_14077)"
        />
        <path
          d="M11.1402 262.812C11.1402 262.812 9.71027 298.942 16.5108 302.169C23.3114 305.396 47.3544 304.831 59.2429 303.833C59.2429 303.833 39.4065 299.308 29.0311 293.935C18.6557 288.562 11.1568 272.46 11.1568 262.812H11.1402Z"
          fill="url(#paint158_linear_2079_14077)"
        />
        <path
          d="M46.5396 264.591L44.428 219.045C44.428 219.045 41.9672 258.386 31.625 268.717C31.625 268.717 44.3448 265.04 46.5396 264.575V264.591Z"
          fill="url(#paint159_linear_2079_14077)"
        />
        <path
          d="M126.517 269.682C126.517 269.682 60.8225 268.683 46.5396 264.591C46.5396 264.591 22.5964 267.685 29.8459 272.925C37.0954 278.165 112.483 275.287 112.483 275.287L126.517 269.682Z"
          fill="url(#paint160_linear_2079_14077)"
        />
        <path
          d="M177.296 248.023L138.472 185.76C138.472 185.76 128.013 157.747 127.564 156.832C128.445 159.993 135.279 184.196 137.241 187.39C137.624 188.005 170.23 242.318 177.296 248.023Z"
          fill="url(#paint161_linear_2079_14077)"
        />
        <path
          d="M140.716 176.544C140.716 176.544 139.436 166.996 139.054 166.014C138.671 165.05 124.189 139.931 123.624 138.484C123.058 137.02 120.747 131.048 119.101 132.396C117.471 133.743 116.69 138.284 118.935 142.077C118.935 142.077 122.26 150.727 124.289 152.94L129.16 168.144L140.7 176.561L140.716 176.544Z"
          fill="#F7D4AB"
        />
        <path
          d="M119.566 131.913C119.566 131.913 118.569 132.495 117.704 134.491C116.008 138.384 121.229 149.579 124.122 152.906C124.122 152.906 127.614 165.316 128.894 167.644L130.823 167.495C130.823 167.495 126.068 153.971 125.652 152.607C125.236 151.226 115.526 137.037 119.566 131.913Z"
          fill="url(#paint162_linear_2079_14077)"
        />
        <path
          d="M114.213 193.462C114.512 192.48 117.322 192.58 119.367 193.944C120.315 194.559 118.802 195.707 118.802 195.707C118.802 195.707 116.091 195.075 114.213 193.478V193.462Z"
          fill="url(#paint163_linear_2079_14077)"
        />
        <path
          d="M114.612 167.977C114.612 167.977 110.006 166.829 108.692 166.846C107.362 166.879 106.215 169.058 107.296 170.323C108.376 171.587 112.201 172.385 112.201 172.385L114.612 167.977Z"
          fill="url(#paint164_linear_2079_14077)"
        />
        <path
          d="M123.374 172.685C132.968 181.551 143.56 200.099 143.56 200.099C143.56 200.099 139.918 172.552 139.004 170.672C138.073 168.792 126.916 165.299 125.752 165.249C125.02 165.216 114.395 163.735 113.015 164.251C111.652 164.767 107.678 172.718 107.528 173.883C107.395 175.047 109.623 180.32 110.604 180.32C111.585 180.32 114.046 175.646 113.348 173.866C113.348 173.866 115.692 171.62 116.191 170.057C117.737 171.903 121.961 172.485 123.357 172.685H123.374Z"
          fill="url(#paint165_linear_2079_14077)"
        />
        <path
          d="M119.882 131.63C118.452 132.08 119.866 134.791 121.795 137.453C122.676 138.667 123.407 138.001 123.407 138.001C123.407 138.001 122.16 134.059 119.882 131.63Z"
          fill="#FFDEBD"
        />
        <path
          d="M108.36 176.777C108.36 176.777 107.412 174.831 107.545 173.783C107.661 172.735 111.668 164.434 113.165 164.118C114.661 163.819 122.426 164.85 122.426 164.85C122.426 164.85 114.961 164.767 113.514 165.099C112.068 165.432 108.543 172.618 108.277 173.783C108.077 174.648 108.36 176.76 108.36 176.76V176.777Z"
          fill="#F7D4AB"
        />
        <path
          d="M112.201 173.566C112.201 173.566 114.745 171.62 115.908 168.742C115.908 168.742 134.115 170.372 134.348 174.115L122.027 172.818C122.027 172.818 117.405 171.57 116.407 170.472C116.407 170.472 114.345 173.533 113.165 173.982L112.201 173.566Z"
          fill="url(#paint166_linear_2079_14077)"
        />
        <path
          d="M144.823 203.326C144.823 203.326 136.959 176.444 136.593 176.028C136.227 175.613 133.633 171.936 129.244 171.72C124.854 171.504 111.901 172.984 110.771 173.6C109.64 174.199 108.759 179.538 108.775 180.087C108.792 180.636 108.775 187.556 109.224 188.687C109.657 189.819 110.854 189.486 111.635 188.471C112.417 187.44 114.429 179.572 114.429 179.572C114.429 179.572 119.966 178.64 121.08 178.906C121.961 179.123 128.961 179.073 128.961 179.073L144.823 203.343V203.326Z"
          fill="url(#paint167_linear_2079_14077)"
        />
        <path
          d="M144.042 200.681C142.146 188.055 139.951 170.09 139.486 168.243C139.103 166.78 138.987 173.317 138.255 177.842C137.524 182.35 142.396 198.602 144.042 200.681Z"
          fill="url(#paint168_linear_2079_14077)"
        />
        <path
          d="M108.859 183.381C110.139 182.965 111.17 185.111 111.087 188.122C111.053 189.502 110.122 190.118 109.474 190.001C108.975 189.918 108.726 187.024 108.875 183.381H108.859Z"
          fill="url(#paint169_linear_2079_14077)"
        />
        <path
          d="M108.892 179.139C108.892 179.139 109.673 174.232 110.521 173.566C111.369 172.901 121.961 172.053 121.961 172.053C121.961 172.053 112.483 173.433 111.502 174.182C110.521 174.931 108.908 179.122 108.908 179.122L108.892 179.139Z"
          fill="#F7D4AB"
        />
        <path
          d="M112.899 186.076C112.899 186.076 114.628 182.15 114.545 179.805C114.545 179.805 129.426 180.287 130.806 179.838C130.806 179.838 131.072 175.38 113.265 178.657C113.265 178.657 111.851 185.111 112.899 186.093V186.076Z"
          fill="url(#paint170_linear_2079_14077)"
        />
        <path
          d="M141.465 239.057C141.465 239.057 132.403 214.238 131.738 213.556C131.089 212.874 126.716 209.68 126.4 208.882C126.084 208.067 123.856 195.208 122.975 189.419C122.975 189.419 120.298 187.074 119.583 186.242C119.583 186.242 117.289 186.908 116.374 187.057C116.374 187.057 117.056 188.837 117.039 189.769C117.039 189.769 120.414 192.397 120.265 193.561C120.132 194.726 117.006 196.256 113.248 192.646C109.507 189.037 109.258 182.849 109.574 182.167C109.906 181.485 119.001 178.657 123.707 178.208C128.429 177.742 130.873 178.507 131.189 179.305C131.505 180.121 144.175 201.679 144.79 203.309C145.422 204.923 155.149 222.955 155.149 222.955L141.431 239.024L141.465 239.057Z"
          fill="#F7D4AB"
        />
        <path
          d="M122.992 189.436C122.992 189.436 125.137 208.849 127.232 210.446C131.422 213.639 132.536 213.14 134.215 211.46C135.894 209.78 124.272 190.284 122.992 189.436Z"
          fill="url(#paint171_linear_2079_14077)"
        />
        <path
          d="M115.626 179.954C115.626 179.954 109.64 181.135 109.324 182.383C109.008 183.614 110.255 188.937 111.685 190.717C111.685 190.717 109.573 184.462 110.172 182.865C110.771 181.268 115.626 179.938 115.626 179.938V179.954Z"
          fill="#FFD9B3"
        />
        <path
          d="M119.899 194.227C119.899 194.227 121.312 193.362 118.702 191.066C117.588 190.085 117.089 189.719 117.089 189.719C117.089 189.719 116.89 187.955 116.374 187.074C116.374 187.074 118.419 186.891 119.583 186.259C119.583 186.259 121.728 188.97 122.975 189.436C122.975 189.436 119.716 186.375 119.849 184.662C119.849 184.662 116.557 186.308 115.227 186.109C115.227 186.109 116.407 189.253 115.759 189.935C115.759 189.935 120.232 192.58 119.882 194.227H119.899Z"
          fill="url(#paint172_linear_2079_14077)"
        />
        <path
          d="M121.129 180.104C122.776 178.906 124.821 178.407 127.049 178.54C129.277 178.673 131.139 179.605 127.814 181.767C124.488 183.93 119.483 181.301 121.129 180.104Z"
          fill="url(#paint173_linear_2079_14077)"
        />
        <path
          d="M127.614 172.202C130.474 172.036 134.232 171.653 133.733 173.566C133.251 175.363 130.258 177.06 127.365 176.012C124.471 174.964 123.956 172.418 127.614 172.202Z"
          fill="url(#paint174_linear_2079_14077)"
        />
        <path
          d="M130.025 166.73C130.025 166.73 137.225 167.445 136.626 169.341C136.027 171.238 132.818 171.637 129.077 170.306C125.336 168.975 127.315 166.513 130.025 166.713V166.73Z"
          fill="url(#paint175_linear_2079_14077)"
        />
        <path
          d="M124.704 140.546C124.704 140.546 128.279 148.997 128.911 150.128C129.543 151.259 137.258 164.45 139.054 165.981C139.054 165.981 131.804 151.425 130.241 149.479C128.678 147.533 124.721 140.563 124.721 140.563L124.704 140.546Z"
          fill="url(#paint176_linear_2079_14077)"
        />
        <path
          d="M127.864 208.632L131.156 215.203L145.239 196.622L143.759 194.26C143.759 194.26 133.134 207.401 127.864 208.632Z"
          fill="url(#paint177_linear_2079_14077)"
        />
        <path
          d="M195.337 270.863C181.004 256.973 144.857 194.909 144.857 194.909C144.857 194.909 135.196 211.477 125.436 212.076C125.436 212.076 147.999 269.449 153.536 286.566C156.728 296.413 175.018 311.568 183.216 309.106C195.669 305.38 195.337 270.863 195.337 270.863Z"
          fill="#2F354E"
        />
        <path
          d="M151.192 215.103C160.802 231.788 172.774 252.415 180.938 271.894C188.171 289.161 189.501 303.383 182.534 306.461C178.211 308.374 168.135 301.304 158.341 276.269C152.771 262.029 145.688 240.754 138.788 222.173C142.695 219.91 146.852 218.497 151.192 215.086V215.103Z"
          fill="url(#paint178_linear_2079_14077)"
        />
        <path
          d="M144.873 194.909L170.596 236.911L143.477 197.121L144.873 194.909Z"
          fill="url(#paint179_linear_2079_14077)"
        />
        <path
          d="M62.2689 121.749C66.1264 126.224 70.4662 128.103 75.6705 131.381C78.9627 133.46 84.2834 138.134 87.7918 140.297C91.0008 142.26 94.3928 143.757 98.3667 143.457C110.405 142.559 118.536 130.865 121.578 127.022"
          fill="#F7D4AB"
        />
        <path
          d="M76.3356 115.994C75.1883 115.744 74.4567 114.929 74.8225 114.031C75.1385 113.216 80.2098 115.994 82.3048 118.373C79.6611 116.859 77.4995 116.243 76.3356 115.994Z"
          fill="url(#paint180_linear_2079_14077)"
        />
        <path
          d="M117.804 110.887C118.835 110.305 119.284 109.307 118.669 108.558C118.12 107.876 114.113 112.018 112.816 114.929C114.894 112.683 116.773 111.469 117.804 110.887Z"
          fill="url(#paint181_linear_2079_14077)"
        />
        <path
          d="M87.293 123.596C89.9034 127.854 102.174 132.778 109.457 120.934C109.457 120.934 97.9677 127.738 87.293 123.596Z"
          fill="#F0C499"
        />
        <path
          d="M96.1553 114.796C85.2644 115.91 78.7299 114.996 76.1028 114.763V114.796C76.1859 114.896 76.2358 114.946 76.3189 115.045C77.7489 115.495 79.3617 115.711 81.5399 116.925C83.0363 117.757 85.148 124.294 98.6493 122.598C106.032 121.666 110.205 118.622 113.98 113.066C115.16 111.336 116.407 110.687 117.405 109.939C117.405 109.806 117.471 109.756 117.488 109.623C114.628 110.804 108.908 113.498 96.1553 114.813V114.796Z"
          fill="#961B27"
        />
        <path
          d="M108.476 114.58C101.077 117.158 90.7348 118.14 86.9105 117.89C84.6326 117.175 85.1647 119.919 85.1647 119.919C90.4854 124.244 94.1933 122.897 98.0342 122.548C101.825 122.215 107.279 120.036 110.288 116.676C110.505 115.744 109.524 114.23 108.493 114.58H108.476Z"
          fill="url(#paint182_linear_2079_14077)"
        />
        <path
          d="M111.519 111.369C111.519 111.369 109.174 112.866 103.438 114.147C97.6351 115.445 95.1244 115.378 89.837 115.727C87.4094 115.894 85.8797 115.711 82.7704 115.328C82.7704 115.328 83.4188 115.96 83.8678 116.726C84.4497 117.74 87.16 121.067 98.0342 119.687C106.215 118.639 110.189 115.228 110.571 113.382C110.804 112.284 111.502 111.719 111.536 111.353L111.519 111.369Z"
          fill="url(#paint183_linear_2079_14077)"
        />
        <path
          d="M99.3311 121.999C82.6872 123.895 87.692 116.426 76.319 115.029C76.4021 115.029 79.0126 116.077 82.3214 119.121C85.9628 122.464 89.4046 128.004 98.7159 126.856C107.113 125.808 111.053 119.154 113.348 115.478C115.975 111.269 117.388 109.955 117.455 109.922C108.709 113.432 112.5 120.069 99.3311 121.999Z"
          fill="#F86D78"
        />
        <path
          d="M89.3049 121.6C86.5947 120.585 83.9842 118.056 83.4688 117.757C84.1671 118.506 88.7895 126.906 98.134 125.625C108.942 124.145 110.056 116.642 111.735 114.264C110.937 115.328 108.942 118.189 106.215 119.487C104.436 120.352 101.376 121.466 97.9012 122.032C95.6898 122.381 91.8988 122.365 89.3049 121.6Z"
          fill="url(#paint184_linear_2079_14077)"
        />
        <path
          d="M106.032 120.601C106.215 120.967 105.317 122.348 103.189 123.213C100.445 124.344 98.1007 123.829 97.9178 123.463C97.7349 123.097 101.459 122.398 102.524 121.899C103.571 121.4 105.849 120.235 106.048 120.585L106.032 120.601Z"
          fill="#FF9AA2"
        />
        <path
          d="M76.585 115.644C77.2834 115.861 77.7323 115.312 80.4425 115.96C80.4425 115.96 75.737 114.164 75.4544 114.33C75.2548 114.447 75.5541 115.328 76.585 115.661V115.644Z"
          fill="#961B27"
        />
        <path
          d="M117.538 110.571C116.923 110.953 116.141 110.571 113.98 111.851C113.98 111.851 117.92 108.807 118.303 109.007C118.502 109.107 118.452 110.005 117.538 110.571Z"
          fill="#961B27"
        />
        <path
          d="M83.0364 115.578L83.8844 116.792C83.8844 116.792 88.8892 118.789 98.5662 117.641C105.55 116.809 110.538 113.532 110.538 113.532L110.937 112.185L83.0364 115.578Z"
          fill="url(#paint185_linear_2079_14077)"
        />
        <path
          d="M103.272 112.284C100.013 112.401 99.7136 112.983 97.3857 113.266C95.1244 113.548 93.2289 113.099 90.4522 113.665C86.8441 114.413 82.4212 115.212 77.0007 114.713C77.0007 114.713 79.1124 115.694 89.0721 116.459C92.0318 116.676 94.6755 116.942 97.6518 116.526C101.227 116.127 104.103 115.045 106.813 114.264C111.403 112.933 116.673 109.955 116.69 109.855C114.279 110.854 106.863 112.151 103.272 112.284Z"
          fill="#F86D78"
        />
        <path
          d="M86.711 115.162C86.7609 114.813 91.5495 113.831 92.3476 113.731C93.1457 113.648 95.856 113.798 96.8037 113.698C97.7515 113.582 100.661 113.083 101.426 112.8C102.208 112.534 108.144 112.367 108.144 112.8C108.144 113.216 99.7135 115.228 98.7159 115.445C97.7182 115.644 86.5447 116.21 86.6944 115.179L86.711 115.162Z"
          fill="url(#paint186_linear_2079_14077)"
        />
        <path
          d="M99.2148 132.096C96.0223 132.229 93.4285 133.294 93.4285 134.491C93.4285 135.689 95.9891 136.554 99.1649 136.421C102.341 136.288 104.935 135.223 104.951 134.026C104.951 132.828 102.391 131.963 99.2148 132.096Z"
          fill="url(#paint187_linear_2079_14077)"
        />
        <path
          d="M54.8033 93.4702C54.8033 93.4702 52.4922 116.31 70.0838 126.939C78.3475 131.93 80.9248 135.19 85.3975 138.683C85.3975 138.683 74.3237 130.948 67.7726 127.338C64.5968 125.592 48.9173 117.058 54.8033 93.4702Z"
          fill="#F0C499"
        />
        <path
          d="M87.1932 139.914C87.1932 139.914 98.6827 152.756 113.414 136.305C113.414 136.305 101.509 148.764 87.1932 139.914Z"
          fill="url(#paint188_linear_2079_14077)"
        />
        <path
          d="M137.308 88.7793C137.308 88.7793 134.864 82.9238 130.673 81.4434C141.431 94.3685 133.616 110.87 127.032 118.805C119.749 127.571 116.889 132.412 115.742 133.876C116.79 132.645 126.533 121.267 129.36 118.572C131.987 116.077 141.747 103.118 137.308 88.7793Z"
          fill="url(#paint189_linear_2079_14077)"
        />
        <path
          d="M117.089 209.896C116.274 218.064 113.581 227.662 106.032 234.25C108.476 233.252 110.688 231.705 112.666 229.609C116.341 225.716 118.137 221.058 118.02 215.669C117.97 213.623 117.671 211.71 117.106 209.896H117.089Z"
          fill="white"
        />
        <path
          d="M85.1149 200.614C88.9059 199.317 92.1648 197.703 94.7753 195.874C91.1339 196.456 87.9082 198.019 85.1149 200.614Z"
          fill="white"
        />
        <path
          d="M101.293 195.707C103.837 197.321 106.946 198.718 110.488 199.816C107.761 197.537 104.702 196.173 101.293 195.707Z"
          fill="white"
        />
        <path
          d="M79.3785 209.381C78.7134 211.427 78.3975 213.606 78.4307 215.935C78.5305 221.325 80.5091 225.899 84.3334 229.659C86.5615 231.838 89.0389 233.351 91.7658 234.233C83.5187 227.762 80.4426 217.765 79.3618 209.381H79.3785Z"
          fill="white"
        />
        <path
          d="M115.027 209.813C115.277 207.351 115.343 205.023 115.327 203.01C113.182 202.611 111.186 202.095 109.291 201.513C106.049 200.498 103.222 199.25 100.911 197.77C99.963 197.171 99.0818 196.539 98.3169 195.857H97.9345C97.2195 196.556 96.3882 197.188 95.4903 197.82C93.1126 199.483 90.1363 200.947 86.6779 202.128C84.9653 202.71 83.1363 203.243 81.2241 203.675C81.274 205.505 81.407 207.584 81.6897 209.78C82.6541 217.415 85.4641 226.515 92.9796 232.42C94.7088 233.767 96.6542 234.982 98.9155 235.93C101.243 234.849 103.222 233.485 104.935 231.987C111.818 225.982 114.262 217.249 115.011 209.813H115.027ZM103.854 228.178C103.022 228.91 102.141 229.575 101.227 230.174C100.728 230.49 100.212 230.773 99.697 231.039C99.4476 231.172 99.115 231.422 98.8324 231.422C98.583 231.422 98.2172 231.189 98.001 231.089C95.9891 230.158 94.1102 228.877 92.5307 227.33C92.2314 227.03 91.9321 226.731 91.666 226.415C89.2551 223.72 87.609 220.41 86.5947 216.95C86.2788 215.819 85.9961 214.687 85.78 213.54C85.3643 211.277 85.1482 208.948 85.0816 206.653C85.0816 206.487 85.198 206.32 85.3643 206.27C86.6114 205.987 87.8418 205.655 89.0556 205.256C89.9701 204.956 90.8679 204.623 91.7492 204.241C92.5805 203.891 93.3786 203.492 94.1768 203.06C94.2931 202.993 94.4095 202.943 94.5093 202.877C95.2742 202.444 95.9891 201.978 96.6875 201.43C97.0034 201.18 97.3027 200.947 97.5853 200.681C97.7516 200.531 97.8846 200.365 98.1174 200.348C98.2005 200.348 98.3003 200.348 98.3834 200.348C98.4998 200.348 98.583 200.382 98.6495 200.465C99.298 201.03 99.9963 201.529 100.711 201.978C103.92 203.958 107.645 205.073 111.319 205.771C111.486 205.788 111.619 205.971 111.619 206.137C111.619 206.503 111.619 206.869 111.619 207.235C111.602 209.597 111.419 211.959 110.987 214.288C110.771 215.486 110.488 216.7 110.139 217.865C109.324 220.609 108.061 223.238 106.348 225.5C105.616 226.465 104.785 227.363 103.87 228.178H103.854Z"
          fill="white"
        />
        <path
          d="M49.2166 177.459C-7.68188 130.832 41.0692 83.1733 60.2238 54.5118C66.3925 45.2795 75.4211 19.3461 92.2147 21.8912C96.5377 22.5399 99.0485 25.5342 99.3477 25.7338C99.2147 25.634 95.457 24.1036 91.1173 24.9519C86.3619 25.8668 83.5519 28.412 79.1456 35.2155C74.5565 41.9359 71.68 48.3569 66.4092 58.3876C46.1405 96.9468 12.6533 125.658 49.2166 177.475V177.459Z"
          fill="url(#paint190_linear_2079_14077)"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_2079_14077"
          x1="111.437"
          y1="9.77354"
          x2="109.365"
          y2="134.68"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#E0B163" />
          <stop offset={1} stopColor="#A3753D" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_2079_14077"
          x1="61.4479"
          y1="233.348"
          x2="35.9392"
          y2="130.164"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#E0B163" />
          <stop offset={1} stopColor="#A3753D" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_2079_14077"
          x1="128.263"
          y1="272.684"
          x2="127.019"
          y2="85.187"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#E0B163" />
          <stop offset={1} stopColor="#A3753D" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_2079_14077"
          x1="47.2078"
          y1="93.6311"
          x2="52.6832"
          y2="131.731"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#DBA379" />
          <stop offset="0.49" stopColor="#E3B086" />
          <stop offset={1} stopColor="#F0C499" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_2079_14077"
          x1="540.496"
          y1="-2116.72"
          x2="546.151"
          y2="-2146.27"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F7D4AB" />
          <stop offset="0.52" stopColor="#F5D1A8" />
          <stop offset="0.85" stopColor="#F2C99F" />
          <stop offset={1} stopColor="#F0C499" />
        </linearGradient>
        <radialGradient
          id="paint5_radial_2079_14077"
          cx={0}
          cy={0}
          r={1}
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(729.009 -108.349) rotate(161.242) scale(15.2283 16.8255)"
        >
          <stop stopColor="#F9B38E" />
          <stop offset="0.61" stopColor="#F7C59E" />
          <stop offset={1} stopColor="#F7D4AB" />
        </radialGradient>
        <linearGradient
          id="paint6_linear_2079_14077"
          x1="-814.045"
          y1="-1984.72"
          x2="-826.068"
          y2="-2012.68"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F7D4AB" />
          <stop offset="0.52" stopColor="#F5D1A8" />
          <stop offset="0.85" stopColor="#F2C99F" />
          <stop offset={1} stopColor="#F0C499" />
        </linearGradient>
        <radialGradient
          id="paint7_radial_2079_14077"
          cx={0}
          cy={0}
          r={1}
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(-630.287 0.544578) rotate(8.66381) scale(14.5334 14.9737)"
        >
          <stop stopColor="#F9B38E" />
          <stop offset="0.61" stopColor="#F7C59E" />
          <stop offset={1} stopColor="#F7D4AB" />
        </radialGradient>
        <linearGradient
          id="paint8_linear_2079_14077"
          x1="120.189"
          y1="95.9398"
          x2="128.578"
          y2="94.1039"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFD9B3" />
          <stop offset={1} stopColor="#F7D4AB" />
        </linearGradient>
        <linearGradient
          id="paint9_linear_2079_14077"
          x1="56.2659"
          y1="97.9162"
          x2="64.5218"
          y2="97.011"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFD9B3" />
          <stop offset={1} stopColor="#F7D4AB" />
        </linearGradient>
        <linearGradient
          id="paint10_linear_2079_14077"
          x1="86.7823"
          y1="125.548"
          x2="80.6155"
          y2="121.618"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F7D4AB" />
          <stop offset="0.52" stopColor="#F5D1A8" />
          <stop offset="0.85" stopColor="#F2C99F" />
          <stop offset={1} stopColor="#F0C499" />
        </linearGradient>
        <linearGradient
          id="paint11_linear_2079_14077"
          x1="111.585"
          y1="108.452"
          x2="119.405"
          y2="102.418"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F7D4AB" />
          <stop offset="0.52" stopColor="#F5D1A8" />
          <stop offset="0.85" stopColor="#F2C99F" />
          <stop offset={1} stopColor="#F0C499" />
        </linearGradient>
        <linearGradient
          id="paint12_linear_2079_14077"
          x1="122.177"
          y1="105.66"
          x2="97.5559"
          y2="109.713"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#E8D8D7" />
          <stop offset="0.14" stopColor="#EFE4E4" />
          <stop offset="0.39" stopColor="#FAF8F7" />
          <stop offset="0.57" stopColor="white" />
          <stop offset={1} stopColor="#E8D2D1" />
        </linearGradient>
        <linearGradient
          id="paint13_linear_2079_14077"
          x1="801.822"
          y1="21.1743"
          x2="840.187"
          y2="16.4182"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#E8D8D7" />
          <stop offset="0.04" stopColor="#EBDDDC" />
          <stop offset="0.21" stopColor="#F6F0EF" />
          <stop offset="0.38" stopColor="#FCFBFB" />
          <stop offset="0.57" stopColor="white" />
          <stop offset="0.69" stopColor="#FBF7F7" />
          <stop offset="0.87" stopColor="#F1E3E3" />
          <stop offset={1} stopColor="#E8D2D1" />
        </linearGradient>
        <linearGradient
          id="paint14_linear_2079_14077"
          x1="85.3172"
          y1="126.312"
          x2="113.18"
          y2="119.964"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F96E7E" />
          <stop offset="0.03" stopColor="#F9707F" />
          <stop offset="0.25" stopColor="#FD7985" />
          <stop offset="0.53" stopColor="#FF7D88" />
          <stop offset="0.76" stopColor="#FD7985" />
          <stop offset={1} stopColor="#F86D7D" />
        </linearGradient>
        <linearGradient
          id="paint15_linear_2079_14077"
          x1="79.265"
          y1="110.628"
          x2="109.493"
          y2="107.313"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#E8D8D7" />
          <stop offset="0.05" stopColor="#EADCDB" />
          <stop offset="0.31" stopColor="#F6EFEE" />
          <stop offset="0.48" stopColor="#FBF6F6" />
          <stop offset={1} stopColor="#E8D2D1" />
        </linearGradient>
        <linearGradient
          id="paint16_linear_2079_14077"
          x1="93.0207"
          y1="116.224"
          x2="114.61"
          y2="113.528"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F96E7E" />
          <stop offset="0.03" stopColor="#F9707F" />
          <stop offset="0.25" stopColor="#FD7985" />
          <stop offset="0.53" stopColor="#FF7D88" />
          <stop offset="0.76" stopColor="#FD7985" />
          <stop offset={1} stopColor="#F86D7D" />
        </linearGradient>
        <linearGradient
          id="paint17_linear_2079_14077"
          x1="94.4145"
          y1="125.364"
          x2="95.1387"
          y2="131.007"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFD9B3" />
          <stop offset="0.68" stopColor="#FCD7B0" />
          <stop offset={1} stopColor="#F7D4AB" />
        </linearGradient>
        <linearGradient
          id="paint18_linear_2079_14077"
          x1="101.383"
          y1="141.097"
          x2="107.929"
          y2="169.982"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#DBA379" />
          <stop offset="0.49" stopColor="#E3B086" />
          <stop offset={1} stopColor="#F0C499" />
        </linearGradient>
        <linearGradient
          id="paint19_linear_2079_14077"
          x1="151.912"
          y1="207.848"
          x2="155.205"
          y2="246.198"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#DBA379" />
          <stop offset="0.49" stopColor="#E3B086" />
          <stop offset={1} stopColor="#F0C499" />
        </linearGradient>
        <linearGradient
          id="paint20_linear_2079_14077"
          x1="135.069"
          y1="75.6339"
          x2="137.138"
          y2="160.27"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#A3753D" />
          <stop offset={1} stopColor="#E0B163" />
        </linearGradient>
        <linearGradient
          id="paint21_linear_2079_14077"
          x1="57.2071"
          y1="108.742"
          x2="39.1009"
          y2="36.3117"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#A3753D" />
          <stop offset={1} stopColor="#E0B163" />
        </linearGradient>
        <linearGradient
          id="paint22_linear_2079_14077"
          x1="81.462"
          y1="30.8532"
          x2="113.714"
          y2="192.335"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#E0B163" />
          <stop offset={1} stopColor="#A3753D" />
        </linearGradient>
        <linearGradient
          id="paint23_linear_2079_14077"
          x1="25.2152"
          y1="54.5906"
          x2="99.0474"
          y2="57.6321"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#E0B163" />
          <stop offset={1} stopColor="#F4C381" />
        </linearGradient>
        <linearGradient
          id="paint24_linear_2079_14077"
          x1="162.203"
          y1="66.0146"
          x2="109.041"
          y2="20.3087"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#E0B163" />
          <stop offset={1} stopColor="#F4C381" />
        </linearGradient>
        <linearGradient
          id="paint25_linear_2079_14077"
          x1="19.2967"
          y1="49.5649"
          x2="153.185"
          y2="34.2224"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#E0B163" />
          <stop offset={1} stopColor="#F4C381" />
        </linearGradient>
        <linearGradient
          id="paint26_linear_2079_14077"
          x1="107.782"
          y1="102.252"
          x2="127.772"
          y2="103.656"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFD9B3" />
          <stop offset="0.68" stopColor="#FCD7B0" />
          <stop offset={1} stopColor="#F7D4AB" />
        </linearGradient>
        <linearGradient
          id="paint27_linear_2079_14077"
          x1="104.386"
          y1="66.6792"
          x2="131.709"
          y2="63.5482"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#956134" />
          <stop offset="0.34" stopColor="#80552B" />
          <stop offset="0.68" stopColor="#744D26" />
          <stop offset={1} stopColor="#704B25" />
        </linearGradient>
        <linearGradient
          id="paint28_linear_2079_14077"
          x1="52.1214"
          y1="72.5397"
          x2="79.6425"
          y2="69.386"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#704B25" />
          <stop offset="0.32" stopColor="#744D26" />
          <stop offset="0.66" stopColor="#80552B" />
          <stop offset={1} stopColor="#956134" />
        </linearGradient>
        <linearGradient
          id="paint29_linear_2079_14077"
          x1="127.116"
          y1="73.6339"
          x2="104.802"
          y2="87.7897"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#DBA379" />
          <stop offset="0.49" stopColor="#E3B086" />
          <stop offset={1} stopColor="#F0C499" />
        </linearGradient>
        <linearGradient
          id="paint30_linear_2079_14077"
          x1="125.943"
          y1="80.1554"
          x2="108.726"
          y2="83.0687"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" />
          <stop offset="0.47" stopColor="#FCFCFC" />
          <stop offset="0.77" stopColor="#F5F3F3" />
          <stop offset={1} stopColor="#ECE7E7" />
        </linearGradient>
        <linearGradient
          id="paint31_linear_2079_14077"
          x1="59.1613"
          y1="81.5011"
          x2="84.13"
          y2="90.1282"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#DBA379" />
          <stop offset="0.49" stopColor="#E3B086" />
          <stop offset={1} stopColor="#F0C499" />
        </linearGradient>
        <linearGradient
          id="paint32_linear_2079_14077"
          x1="84.3226"
          y1="81.4684"
          x2="68.7242"
          y2="84.371"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#B89457" />
          <stop offset="0.34" stopColor="#907647" />
          <stop offset={1} stopColor="#665535" />
        </linearGradient>
        <linearGradient
          id="paint33_linear_2079_14077"
          x1="390.041"
          y1="-93.3535"
          x2="402.968"
          y2="-100.613"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" />
          <stop offset="0.47" stopColor="#FCFCFC" />
          <stop offset="0.77" stopColor="#F5F3F3" />
          <stop offset={1} stopColor="#ECE7E7" />
        </linearGradient>
        <linearGradient
          id="paint34_linear_2079_14077"
          x1="70.3847"
          y1="93.1731"
          x2="80.0093"
          y2="81.9084"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#B89C8C" />
          <stop offset={1} stopColor="#715241" />
        </linearGradient>
        <linearGradient
          id="paint35_linear_2079_14077"
          x1="99.6023"
          y1="96.6873"
          x2="73.289"
          y2="84.0853"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#B89C8C" />
          <stop offset={1} stopColor="#715241" />
        </linearGradient>
        <radialGradient
          id="paint36_radial_2079_14077"
          cx={0}
          cy={0}
          r={1}
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(101.179 85.6742) rotate(176.008) scale(9.35684 8.94677)"
        >
          <stop stopColor="#2A3A3B" />
          <stop offset={1} stopColor="#1C2727" />
        </radialGradient>
        <linearGradient
          id="paint37_linear_2079_14077"
          x1="113.654"
          y1="81.7872"
          x2="127.18"
          y2="80.2132"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#B89C8C" />
          <stop offset={1} stopColor="#715241" />
        </linearGradient>
        <linearGradient
          id="paint38_linear_2079_14077"
          x1="148.652"
          y1="92.9677"
          x2="122.34"
          y2="80.3823"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#B89C8C" />
          <stop offset={1} stopColor="#715241" />
        </linearGradient>
        <radialGradient
          id="paint39_radial_2079_14077"
          cx={0}
          cy={0}
          r={1}
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(123.218 82.6746) rotate(-9.30408) scale(9.37572 8.96466)"
        >
          <stop stopColor="#2A3A3B" />
          <stop offset={1} stopColor="#1C2727" />
        </radialGradient>
        <linearGradient
          id="paint40_linear_2079_14077"
          x1="94.6565"
          y1="85.1743"
          x2="65.7561"
          y2="85.6785"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#5A2A1F" />
          <stop offset="0.29" stopColor="#492219" />
          <stop offset="0.66" stopColor="#3D1C14" />
          <stop offset={1} stopColor="#391B13" />
        </linearGradient>
        <linearGradient
          id="paint41_linear_2079_14077"
          x1="98.0279"
          y1="75.6098"
          x2="126.271"
          y2="69.4596"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#5A2A1F" />
          <stop offset="0.29" stopColor="#492219" />
          <stop offset="0.66" stopColor="#3D1C14" />
          <stop offset={1} stopColor="#391B13" />
        </linearGradient>
        <linearGradient
          id="paint42_linear_2079_14077"
          x1="97.0966"
          y1="111.434"
          x2="96.2704"
          y2="104.814"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F7D4AB" />
          <stop offset="0.52" stopColor="#F5D1A8" />
          <stop offset="0.85" stopColor="#F2C99F" />
          <stop offset={1} stopColor="#F0C499" />
        </linearGradient>
        <linearGradient
          id="paint43_linear_2079_14077"
          x1="96.4427"
          y1="104.522"
          x2="94.7809"
          y2="91.7328"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFD9B3" />
          <stop offset={1} stopColor="#F7D4AB" />
        </linearGradient>
        <linearGradient
          id="paint44_linear_2079_14077"
          x1="104.842"
          y1="102.643"
          x2="99.6049"
          y2="106.02"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#DBA379" />
          <stop offset="0.49" stopColor="#E3B086" />
          <stop offset={1} stopColor="#F0C499" />
        </linearGradient>
        <linearGradient
          id="paint45_linear_2079_14077"
          x1="87.713"
          y1="104.908"
          x2="93.9203"
          y2="106.639"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#DBA379" />
          <stop offset="0.49" stopColor="#E3B086" />
          <stop offset={1} stopColor="#F0C499" />
        </linearGradient>
        <linearGradient
          id="paint46_linear_2079_14077"
          x1="164.826"
          y1="16.8674"
          x2="148.517"
          y2="161.637"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#A3753D" />
          <stop offset={1} stopColor="#E0B163" />
        </linearGradient>
        <linearGradient
          id="paint47_linear_2079_14077"
          x1="125.17"
          y1="28.2789"
          x2="158.869"
          y2="205.283"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#A3753D" />
          <stop offset={1} stopColor="#E0B163" />
        </linearGradient>
        <linearGradient
          id="paint48_linear_2079_14077"
          x1="98.0343"
          y1="142.709"
          x2="100.298"
          y2="175.645"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#3A435F" />
          <stop offset={1} stopColor="#2F354E" />
        </linearGradient>
        <linearGradient
          id="paint49_linear_2079_14077"
          x1="82.2881"
          y1="136.355"
          x2="116.956"
          y2="174.083"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F0C499" />
          <stop offset="0.52" stopColor="#F1C69B" />
          <stop offset="0.85" stopColor="#F4CEA4" />
          <stop offset={1} stopColor="#F7D4AB" />
        </linearGradient>
        <linearGradient
          id="paint50_linear_2079_14077"
          x1="77.1836"
          y1="164.65"
          x2="107.928"
          y2="169.171"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F0C499" />
          <stop offset="0.52" stopColor="#F1C69B" />
          <stop offset="0.85" stopColor="#F4CEA4" />
          <stop offset={1} stopColor="#F7D4AB" />
        </linearGradient>
        <linearGradient
          id="paint51_linear_2079_14077"
          x1="101.044"
          y1="163.569"
          x2="144.641"
          y2="156.771"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F0C499" />
          <stop offset="0.52" stopColor="#F1C69B" />
          <stop offset="0.85" stopColor="#F4CEA4" />
          <stop offset={1} stopColor="#F7D4AB" />
        </linearGradient>
        <linearGradient
          id="paint52_linear_2079_14077"
          x1="98.5663"
          y1="165.781"
          x2="60.3048"
          y2="155.742"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F0C499" />
          <stop offset="0.52" stopColor="#F1C69B" />
          <stop offset="0.85" stopColor="#F4CEA4" />
          <stop offset={1} stopColor="#F7D4AB" />
        </linearGradient>
        <linearGradient
          id="paint53_linear_2079_14077"
          x1="94.2765"
          y1="125.076"
          x2="99.2855"
          y2="146.334"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#DBA379" />
          <stop offset="0.49" stopColor="#E3B086" />
          <stop offset={1} stopColor="#F0C499" />
        </linearGradient>
        <linearGradient
          id="paint54_linear_2079_14077"
          x1="46.4066"
          y1="258.32"
          x2="16.8184"
          y2="137.791"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F0C499" />
          <stop offset="0.52" stopColor="#F1C69B" />
          <stop offset="0.85" stopColor="#F4CEA4" />
          <stop offset={1} stopColor="#F7D4AB" />
        </linearGradient>
        <linearGradient
          id="paint55_linear_2079_14077"
          x1="100.478"
          y1="167.778"
          x2="99.613"
          y2="230.623"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F1F0F5" />
          <stop offset={1} stopColor="#BEC0D7" />
        </linearGradient>
        <linearGradient
          id="paint56_linear_2079_14077"
          x1="204.232"
          y1="778.536"
          x2="191.568"
          y2="712.648"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#DBA379" />
          <stop offset="0.49" stopColor="#E3B086" />
          <stop offset={1} stopColor="#F0C499" />
        </linearGradient>
        <linearGradient
          id="paint57_linear_2079_14077"
          x1="71.4805"
          y1="776.407"
          x2="63.9584"
          y2="715.042"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#DBA379" />
          <stop offset="0.49" stopColor="#E3B086" />
          <stop offset={1} stopColor="#F0C499" />
        </linearGradient>
        <linearGradient
          id="paint58_linear_2079_14077"
          x1="69.9175"
          y1="830.852"
          x2="70.2004"
          y2="807.164"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" />
          <stop offset="0.36" stopColor="#F8F8FA" />
          <stop offset={1} stopColor="#F1F0F5" />
        </linearGradient>
        <linearGradient
          id="paint59_linear_2079_14077"
          x1="69.9342"
          y1="822.618"
          x2="70.6831"
          y2="761.253"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" />
          <stop offset="0.36" stopColor="#F8F8FA" />
          <stop offset={1} stopColor="#F1F0F5" />
        </linearGradient>
        <linearGradient
          id="paint60_linear_2079_14077"
          x1="67.091"
          y1="728.117"
          x2="70.0033"
          y2="804.453"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#E1E1E5" />
          <stop offset={1} stopColor="#F1F0F5" />
        </linearGradient>
        <linearGradient
          id="paint61_linear_2079_14077"
          x1="91.5829"
          y1="763.581"
          x2="68.9277"
          y2="783.649"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F1F0F5" />
          <stop offset={1} stopColor="#E1E1E5" />
        </linearGradient>
        <linearGradient
          id="paint62_linear_2079_14077"
          x1="59.8913"
          y1="813.685"
          x2="61.7718"
          y2="773.396"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" />
          <stop offset="0.36" stopColor="#F8F8FA" />
          <stop offset={1} stopColor="#F1F0F5" />
        </linearGradient>
        <linearGradient
          id="paint63_linear_2079_14077"
          x1="100.279"
          y1="772.065"
          x2="84.4935"
          y2="786.064"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F1F0F5" />
          <stop offset={1} stopColor="#E1E1E5" />
        </linearGradient>
        <linearGradient
          id="paint64_linear_2079_14077"
          x1="78.414"
          y1="814.667"
          x2="80.2946"
          y2="774.128"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" />
          <stop offset="0.36" stopColor="#F8F8FA" />
          <stop offset={1} stopColor="#F1F0F5" />
        </linearGradient>
        <linearGradient
          id="paint65_linear_2079_14077"
          x1="68.0719"
          y1="763.282"
          x2="68.6378"
          y2="804.785"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#1C5389" />
          <stop offset="0.25" stopColor="#194F84" />
          <stop offset="0.54" stopColor="#124576" />
          <stop offset="0.85" stopColor="#07345E" />
          <stop offset={1} stopColor="#012A51" />
        </linearGradient>
        <linearGradient
          id="paint66_linear_2079_14077"
          x1="66.6752"
          y1="763.299"
          x2="67.2244"
          y2="804.802"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#1C5389" />
          <stop offset="0.25" stopColor="#194F84" />
          <stop offset="0.54" stopColor="#124576" />
          <stop offset="0.85" stopColor="#07345E" />
          <stop offset={1} stopColor="#012A51" />
        </linearGradient>
        <linearGradient
          id="paint67_linear_2079_14077"
          x1="68.604"
          y1="763.282"
          x2="69.1699"
          y2="804.785"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#1C5389" />
          <stop offset="0.25" stopColor="#194F84" />
          <stop offset="0.54" stopColor="#124576" />
          <stop offset="0.85" stopColor="#07345E" />
          <stop offset={1} stopColor="#012A51" />
        </linearGradient>
        <linearGradient
          id="paint68_linear_2079_14077"
          x1="68.0054"
          y1="763.282"
          x2="68.5713"
          y2="804.785"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#1C5389" />
          <stop offset="0.25" stopColor="#194F84" />
          <stop offset="0.54" stopColor="#124576" />
          <stop offset="0.85" stopColor="#07345E" />
          <stop offset={1} stopColor="#012A51" />
        </linearGradient>
        <linearGradient
          id="paint69_linear_2079_14077"
          x1="70.2169"
          y1="763.249"
          x2="70.7827"
          y2="804.752"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#1C5389" />
          <stop offset="0.25" stopColor="#194F84" />
          <stop offset="0.54" stopColor="#124576" />
          <stop offset="0.85" stopColor="#07345E" />
          <stop offset={1} stopColor="#012A51" />
        </linearGradient>
        <linearGradient
          id="paint70_linear_2079_14077"
          x1="68.6872"
          y1="763.282"
          x2="69.253"
          y2="804.785"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#1C5389" />
          <stop offset="0.25" stopColor="#194F84" />
          <stop offset="0.54" stopColor="#124576" />
          <stop offset="0.85" stopColor="#07345E" />
          <stop offset={1} stopColor="#012A51" />
        </linearGradient>
        <linearGradient
          id="paint71_linear_2079_14077"
          x1="79.7942"
          y1="776.257"
          x2="80.9757"
          y2="785.356"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" />
          <stop offset="0.36" stopColor="#F8F8FA" />
          <stop offset={1} stopColor="#F1F0F5" />
        </linearGradient>
        <linearGradient
          id="paint72_linear_2079_14077"
          x1="61.1217"
          y1="778.802"
          x2="62.27"
          y2="787.651"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" />
          <stop offset="0.36" stopColor="#F8F8FA" />
          <stop offset={1} stopColor="#F1F0F5" />
        </linearGradient>
        <linearGradient
          id="paint73_linear_2079_14077"
          x1="69.851"
          y1="803.671"
          x2="70.2338"
          y2="831.8"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#1C5389" />
          <stop offset="0.25" stopColor="#194F84" />
          <stop offset="0.54" stopColor="#124576" />
          <stop offset="0.85" stopColor="#07345E" />
          <stop offset={1} stopColor="#012A51" />
        </linearGradient>
        <linearGradient
          id="paint74_linear_2079_14077"
          x1="70.0506"
          y1="824.015"
          x2="70.0506"
          y2="815.215"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F1F0F5" />
          <stop offset={1} stopColor="#E1E1E5" />
        </linearGradient>
        <linearGradient
          id="paint75_linear_2079_14077"
          x1="68.0221"
          y1="323.727"
          x2="73.9964"
          y2="355.05"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#142D55" />
          <stop offset="0.13" stopColor="#17325A" />
          <stop offset={1} stopColor="#2D5481" />
        </linearGradient>
        <linearGradient
          id="paint76_linear_2079_14077"
          x1="113.365"
          y1="325.291"
          x2="105.859"
          y2="355.049"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#142D55" />
          <stop offset="0.13" stopColor="#17325A" />
          <stop offset={1} stopColor="#2D5481" />
        </linearGradient>
        <linearGradient
          id="paint77_linear_2079_14077"
          x1="85.1149"
          y1="328.069"
          x2="91.802"
          y2="334.603"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#142D55" />
          <stop offset="0.13" stopColor="#17325A" />
          <stop offset={1} stopColor="#2D5481" />
        </linearGradient>
        <linearGradient
          id="paint78_linear_2079_14077"
          x1="81.407"
          y1="319.036"
          x2="87.5636"
          y2="332.042"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#2D5481" />
          <stop offset="0.91" stopColor="#537297" />
          <stop offset={1} stopColor="#57769A" />
        </linearGradient>
        <linearGradient
          id="paint79_linear_2079_14077"
          x1="136.177"
          y1="356.963"
          x2="151.649"
          y2="336.409"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#142D55" />
          <stop offset="0.13" stopColor="#17325A" />
          <stop offset={1} stopColor="#2D5481" />
        </linearGradient>
        <linearGradient
          id="paint80_linear_2079_14077"
          x1="150.144"
          y1="369.14"
          x2="91.2004"
          y2="367.993"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#142D55" />
          <stop offset="0.13" stopColor="#17325A" />
          <stop offset={1} stopColor="#2D5481" />
        </linearGradient>
        <linearGradient
          id="paint81_linear_2079_14077"
          x1="39.6227"
          y1="351.524"
          x2="30.4372"
          y2="325.527"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#142D55" />
          <stop offset="0.13" stopColor="#17325A" />
          <stop offset={1} stopColor="#2D5481" />
        </linearGradient>
        <linearGradient
          id="paint82_linear_2079_14077"
          x1="134.099"
          y1="421.24"
          x2="135.014"
          y2="405.62"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#2D5481" />
          <stop offset="0.87" stopColor="#17325A" />
          <stop offset={1} stopColor="#142D55" />
        </linearGradient>
        <linearGradient
          id="paint83_linear_2079_14077"
          x1="82.072"
          y1="267.369"
          x2="81.6726"
          y2="545.883"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#142D55" />
          <stop offset="0.13" stopColor="#17325A" />
          <stop offset={1} stopColor="#2D5481" />
        </linearGradient>
        <linearGradient
          id="paint84_linear_2079_14077"
          x1="92.6969"
          y1="405.204"
          x2="80.9309"
          y2="636.409"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#142D55" />
          <stop offset="0.13" stopColor="#17325A" />
          <stop offset={1} stopColor="#2D5481" />
        </linearGradient>
        <linearGradient
          id="paint85_linear_2079_14077"
          x1="36.4136"
          y1="369.207"
          x2="110.272"
          y2="367.761"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#142D55" />
          <stop offset="0.13" stopColor="#17325A" />
          <stop offset={1} stopColor="#2D5481" />
        </linearGradient>
        <linearGradient
          id="paint86_linear_2079_14077"
          x1="93.2954"
          y1="342.724"
          x2="90.6327"
          y2="423.785"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#142D55" />
          <stop offset="0.13" stopColor="#17325A" />
          <stop offset={1} stopColor="#2D5481" />
        </linearGradient>
        <linearGradient
          id="paint87_linear_2079_14077"
          x1="69.5518"
          y1="332.777"
          x2="18.1403"
          y2="331.281"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#142D55" />
          <stop offset="0.13" stopColor="#17325A" />
          <stop offset={1} stopColor="#2D5481" />
        </linearGradient>
        <linearGradient
          id="paint88_linear_2079_14077"
          x1="122.327"
          y1="336.719"
          x2="120.113"
          y2="349.245"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#142D55" />
          <stop offset="0.13" stopColor="#17325A" />
          <stop offset={1} stopColor="#2D5481" />
        </linearGradient>
        <linearGradient
          id="paint89_linear_2079_14077"
          x1="125.768"
          y1="343.922"
          x2="159.285"
          y2="373.084"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#2D5481" />
          <stop offset="0.91" stopColor="#537297" />
          <stop offset={1} stopColor="#57769A" />
        </linearGradient>
        <linearGradient
          id="paint90_linear_2079_14077"
          x1="38.3424"
          y1="323.81"
          x2="35.7962"
          y2="353.52"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#2D5481" />
          <stop offset="0.91" stopColor="#537297" />
          <stop offset={1} stopColor="#57769A" />
        </linearGradient>
        <linearGradient
          id="paint91_linear_2079_14077"
          x1="86.6778"
          y1="311.151"
          x2="89.0243"
          y2="338.532"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#2D5481" />
          <stop offset="0.91" stopColor="#537297" />
          <stop offset={1} stopColor="#57769A" />
        </linearGradient>
        <linearGradient
          id="paint92_linear_2079_14077"
          x1="87.293"
          y1="319.253"
          x2="93.2841"
          y2="389.301"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#2D5481" />
          <stop offset="0.91" stopColor="#537297" />
          <stop offset={1} stopColor="#57769A" />
        </linearGradient>
        <linearGradient
          id="paint93_linear_2079_14077"
          x1="109.906"
          y1="321.398"
          x2="113.467"
          y2="379.57"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#142D55" />
          <stop offset="0.13" stopColor="#17325A" />
          <stop offset={1} stopColor="#2D5481" />
        </linearGradient>
        <linearGradient
          id="paint94_linear_2079_14077"
          x1="117.106"
          y1="320.883"
          x2="119.136"
          y2="353.903"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#142D55" />
          <stop offset="0.13" stopColor="#17325A" />
          <stop offset={1} stopColor="#2D5481" />
        </linearGradient>
        <linearGradient
          id="paint95_linear_2079_14077"
          x1="55.2523"
          y1="317.822"
          x2="57.4324"
          y2="353.187"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#142D55" />
          <stop offset="0.13" stopColor="#17325A" />
          <stop offset={1} stopColor="#2D5481" />
        </linearGradient>
        <linearGradient
          id="paint96_linear_2079_14077"
          x1="62.5351"
          y1="324.725"
          x2="66.0632"
          y2="382.298"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#142D55" />
          <stop offset="0.13" stopColor="#17325A" />
          <stop offset={1} stopColor="#2D5481" />
        </linearGradient>
        <linearGradient
          id="paint97_linear_2079_14077"
          x1="58.0125"
          y1="309.189"
          x2="60.825"
          y2="341.942"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#2D5481" />
          <stop offset="0.91" stopColor="#537297" />
          <stop offset={1} stopColor="#57769A" />
        </linearGradient>
        <linearGradient
          id="paint98_linear_2079_14077"
          x1="111.802"
          y1="309.371"
          x2="114.964"
          y2="346.383"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#2D5481" />
          <stop offset="0.91" stopColor="#537297" />
          <stop offset={1} stopColor="#57769A" />
        </linearGradient>
        <linearGradient
          id="paint99_linear_2079_14077"
          x1="78.3642"
          y1="366.096"
          x2="91.7824"
          y2="366.096"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#142D55" />
          <stop offset="0.13" stopColor="#17325A" />
          <stop offset={1} stopColor="#2D5481" />
        </linearGradient>
        <linearGradient
          id="paint100_linear_2079_14077"
          x1="145.405"
          y1="421.905"
          x2="146.321"
          y2="406.285"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#2D5481" />
          <stop offset="0.87" stopColor="#17325A" />
          <stop offset={1} stopColor="#142D55" />
        </linearGradient>
        <linearGradient
          id="paint101_linear_2079_14077"
          x1="47.3544"
          y1="436.095"
          x2="59.7361"
          y2="693.699"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#2D5481" />
          <stop offset="0.91" stopColor="#537297" />
          <stop offset={1} stopColor="#57769A" />
        </linearGradient>
        <linearGradient
          id="paint102_linear_2079_14077"
          x1="21.0002"
          y1="611.857"
          x2="92.0498"
          y2="601.186"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#2D5481" />
          <stop offset="0.87" stopColor="#17325A" />
          <stop offset={1} stopColor="#142D55" />
        </linearGradient>
        <linearGradient
          id="paint103_linear_2079_14077"
          x1="38.1761"
          y1="614.734"
          x2="78.5307"
          y2="611.676"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#2D5481" />
          <stop offset="0.87" stopColor="#17325A" />
          <stop offset={1} stopColor="#142D55" />
        </linearGradient>
        <linearGradient
          id="paint104_linear_2079_14077"
          x1="137.79"
          y1="433.084"
          x2="150.238"
          y2="692.251"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#2D5481" />
          <stop offset="0.91" stopColor="#537297" />
          <stop offset={1} stopColor="#57769A" />
        </linearGradient>
        <linearGradient
          id="paint105_linear_2079_14077"
          x1="132.835"
          y1="616.165"
          x2="171.681"
          y2="601.521"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#2D5481" />
          <stop offset="0.87" stopColor="#17325A" />
          <stop offset={1} stopColor="#142D55" />
        </linearGradient>
        <linearGradient
          id="paint106_linear_2079_14077"
          x1="135.562"
          y1="598.549"
          x2="174.387"
          y2="592.715"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#2D5481" />
          <stop offset="0.87" stopColor="#17325A" />
          <stop offset={1} stopColor="#142D55" />
        </linearGradient>
        <linearGradient
          id="paint107_linear_2079_14077"
          x1="133.866"
          y1="686.213"
          x2="192.411"
          y2="686.213"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#2D5481" />
          <stop offset="0.87" stopColor="#17325A" />
          <stop offset={1} stopColor="#142D55" />
        </linearGradient>
        <linearGradient
          id="paint108_linear_2079_14077"
          x1="76.1861"
          y1="646.923"
          x2="94.3591"
          y2="856.402"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#2D5481" />
          <stop offset="0.87" stopColor="#17325A" />
          <stop offset={1} stopColor="#142D55" />
        </linearGradient>
        <linearGradient
          id="paint109_linear_2079_14077"
          x1="58.345"
          y1="759.805"
          x2="76.0198"
          y2="758.808"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#2D5481" />
          <stop offset="0.87" stopColor="#17325A" />
          <stop offset={1} stopColor="#142D55" />
        </linearGradient>
        <linearGradient
          id="paint110_linear_2079_14077"
          x1="72.1457"
          y1="789.731"
          x2="69.8823"
          y2="741.308"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#2D5481" />
          <stop offset="0.91" stopColor="#537297" />
          <stop offset={1} stopColor="#57769A" />
        </linearGradient>
        <linearGradient
          id="paint111_linear_2079_14077"
          x1="221.292"
          y1="744.651"
          x2="193.208"
          y2="750.585"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#2D5481" />
          <stop offset="0.87" stopColor="#17325A" />
          <stop offset={1} stopColor="#142D55" />
        </linearGradient>
        <linearGradient
          id="paint112_linear_2079_14077"
          x1="201.273"
          y1="785.506"
          x2="198.993"
          y2="736.883"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#2D5481" />
          <stop offset="0.91" stopColor="#537297" />
          <stop offset={1} stopColor="#57769A" />
        </linearGradient>
        <linearGradient
          id="paint113_linear_2079_14077"
          x1="204.133"
          y1="831.251"
          x2="203.783"
          y2="801.974"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" />
          <stop offset="0.36" stopColor="#F8F8FA" />
          <stop offset={1} stopColor="#F1F0F5" />
        </linearGradient>
        <linearGradient
          id="paint114_linear_2079_14077"
          x1="204.864"
          y1="822.069"
          x2="203.966"
          y2="748.544"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" />
          <stop offset="0.36" stopColor="#F8F8FA" />
          <stop offset={1} stopColor="#F1F0F5" />
        </linearGradient>
        <linearGradient
          id="paint115_linear_2079_14077"
          x1="207.309"
          y1="710.234"
          x2="203.914"
          y2="799.179"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#E1E1E5" />
          <stop offset={1} stopColor="#F1F0F5" />
        </linearGradient>
        <linearGradient
          id="paint116_linear_2079_14077"
          x1="176.166"
          y1="751.904"
          x2="203.063"
          y2="775.729"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F1F0F5" />
          <stop offset={1} stopColor="#E1E1E5" />
        </linearGradient>
        <linearGradient
          id="paint117_linear_2079_14077"
          x1="213.976"
          y1="812.121"
          x2="211.679"
          y2="762.799"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" />
          <stop offset="0.36" stopColor="#F8F8FA" />
          <stop offset={1} stopColor="#F1F0F5" />
        </linearGradient>
        <linearGradient
          id="paint118_linear_2079_14077"
          x1="166.788"
          y1="760.754"
          x2="186.482"
          y2="778.211"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F1F0F5" />
          <stop offset={1} stopColor="#E1E1E5" />
        </linearGradient>
        <linearGradient
          id="paint119_linear_2079_14077"
          x1="195.221"
          y1="813.336"
          x2="192.907"
          y2="763.581"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" />
          <stop offset="0.36" stopColor="#F8F8FA" />
          <stop offset={1} stopColor="#F1F0F5" />
        </linearGradient>
        <linearGradient
          id="paint120_linear_2079_14077"
          x1="205.197"
          y1="750.506"
          x2="204.515"
          y2="801.242"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#1C5389" />
          <stop offset="0.25" stopColor="#194F84" />
          <stop offset="0.54" stopColor="#124576" />
          <stop offset="0.85" stopColor="#07345E" />
          <stop offset={1} stopColor="#012A51" />
        </linearGradient>
        <linearGradient
          id="paint121_linear_2079_14077"
          x1="206.71"
          y1="750.223"
          x2="206.011"
          y2="801.308"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#1C5389" />
          <stop offset="0.25" stopColor="#194F84" />
          <stop offset="0.54" stopColor="#124576" />
          <stop offset="0.85" stopColor="#07345E" />
          <stop offset={1} stopColor="#012A51" />
        </linearGradient>
        <linearGradient
          id="paint122_linear_2079_14077"
          x1="204.615"
          y1="750.44"
          x2="203.933"
          y2="801.209"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#1C5389" />
          <stop offset="0.25" stopColor="#194F84" />
          <stop offset="0.54" stopColor="#124576" />
          <stop offset="0.85" stopColor="#07345E" />
          <stop offset={1} stopColor="#012A51" />
        </linearGradient>
        <linearGradient
          id="paint123_linear_2079_14077"
          x1="205.263"
          y1="750.257"
          x2="204.564"
          y2="801.308"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#1C5389" />
          <stop offset="0.25" stopColor="#194F84" />
          <stop offset="0.54" stopColor="#124576" />
          <stop offset="0.85" stopColor="#07345E" />
          <stop offset={1} stopColor="#012A51" />
        </linearGradient>
        <linearGradient
          id="paint124_linear_2079_14077"
          x1="202.886"
          y1="750.39"
          x2="202.203"
          y2="801.175"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#1C5389" />
          <stop offset="0.25" stopColor="#194F84" />
          <stop offset="0.54" stopColor="#124576" />
          <stop offset="0.85" stopColor="#07345E" />
          <stop offset={1} stopColor="#012A51" />
        </linearGradient>
        <linearGradient
          id="paint125_linear_2079_14077"
          x1="204.532"
          y1="750.29"
          x2="203.833"
          y2="801.309"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#1C5389" />
          <stop offset="0.25" stopColor="#194F84" />
          <stop offset="0.54" stopColor="#124576" />
          <stop offset="0.85" stopColor="#07345E" />
          <stop offset={1} stopColor="#012A51" />
        </linearGradient>
        <linearGradient
          id="paint126_linear_2079_14077"
          x1="193.574"
          y1="766.443"
          x2="192.127"
          y2="777.638"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" />
          <stop offset="0.36" stopColor="#F8F8FA" />
          <stop offset={1} stopColor="#F1F0F5" />
        </linearGradient>
        <linearGradient
          id="paint127_linear_2079_14077"
          x1="212.546"
          y1="769.353"
          x2="211.165"
          y2="780.082"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" />
          <stop offset="0.36" stopColor="#F8F8FA" />
          <stop offset={1} stopColor="#F1F0F5" />
        </linearGradient>
        <linearGradient
          id="paint128_linear_2079_14077"
          x1="204.532"
          y1="779.517"
          x2="203.783"
          y2="833.963"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#1C5389" />
          <stop offset="0.25" stopColor="#194F84" />
          <stop offset="0.54" stopColor="#124576" />
          <stop offset="0.85" stopColor="#07345E" />
          <stop offset={1} stopColor="#012A51" />
        </linearGradient>
        <linearGradient
          id="paint129_linear_2079_14077"
          x1="204.482"
          y1="823.949"
          x2="204.482"
          y2="812.188"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F1F0F5" />
          <stop offset={1} stopColor="#E1E1E5" />
        </linearGradient>
        <linearGradient
          id="paint130_linear_2079_14077"
          x1="40.4707"
          y1="176.694"
          x2="83.7491"
          y2="262.896"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#2F354E" />
          <stop offset={1} stopColor="#3A435F" />
        </linearGradient>
        <linearGradient
          id="paint131_linear_2079_14077"
          x1="155.415"
          y1="176.694"
          x2="112.12"
          y2="262.896"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#2F354E" />
          <stop offset={1} stopColor="#3A435F" />
        </linearGradient>
        <linearGradient
          id="paint132_linear_2079_14077"
          x1="131.937"
          y1="250.319"
          x2="134.816"
          y2="271.511"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#2F354E" />
          <stop offset="0.11" stopColor="#2C3149" />
          <stop offset="0.46" stopColor="#262B40" />
          <stop offset={1} stopColor="#252A3E" />
        </linearGradient>
        <linearGradient
          id="paint133_linear_2079_14077"
          x1="137.507"
          y1="306.927"
          x2="142.1"
          y2="279.048"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#2F354E" />
          <stop offset="0.11" stopColor="#2C3149" />
          <stop offset="0.46" stopColor="#262B40" />
          <stop offset={1} stopColor="#252A3E" />
        </linearGradient>
        <linearGradient
          id="paint134_linear_2079_14077"
          x1="143.576"
          y1="208.183"
          x2="143.443"
          y2="221.674"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#3A435F" />
          <stop offset={1} stopColor="#2F354E" />
        </linearGradient>
        <linearGradient
          id="paint135_linear_2079_14077"
          x1="127.681"
          y1="223.071"
          x2="158.89"
          y2="223.071"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#252A3E" />
          <stop offset="0.54" stopColor="#262B40" />
          <stop offset="0.89" stopColor="#2C3149" />
          <stop offset={1} stopColor="#2F354E" />
        </linearGradient>
        <linearGradient
          id="paint136_linear_2079_14077"
          x1="144.308"
          y1="207.102"
          x2="143.093"
          y2="222.006"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#252A3E" />
          <stop offset="0.54" stopColor="#262B40" />
          <stop offset="0.89" stopColor="#2C3149" />
          <stop offset={1} stopColor="#2F354E" />
        </linearGradient>
        <linearGradient
          id="paint137_linear_2079_14077"
          x1="52.5088"
          y1="314.129"
          x2="58.3001"
          y2="279.397"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#2F354E" />
          <stop offset="0.11" stopColor="#2C3149" />
          <stop offset="0.46" stopColor="#262B40" />
          <stop offset={1} stopColor="#252A3E" />
        </linearGradient>
        <linearGradient
          id="paint138_linear_2079_14077"
          x1="58.877"
          y1="260.798"
          x2="60.1418"
          y2="270.097"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#2F354E" />
          <stop offset="0.11" stopColor="#2C3149" />
          <stop offset="0.46" stopColor="#262B40" />
          <stop offset={1} stopColor="#252A3E" />
        </linearGradient>
        <linearGradient
          id="paint139_linear_2079_14077"
          x1="147.966"
          y1="204.49"
          x2="214.797"
          y2="299.08"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#2F354E" />
          <stop offset="0.11" stopColor="#2C3149" />
          <stop offset="0.46" stopColor="#262B40" />
          <stop offset={1} stopColor="#252A3E" />
        </linearGradient>
        <linearGradient
          id="paint140_linear_2079_14077"
          x1="149.728"
          y1="268.218"
          x2="122.404"
          y2="177.998"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#2F354E" />
          <stop offset="0.11" stopColor="#2C3149" />
          <stop offset="0.46" stopColor="#262B40" />
          <stop offset={1} stopColor="#252A3E" />
        </linearGradient>
        <linearGradient
          id="paint141_linear_2079_14077"
          x1="54.4708"
          y1="154.786"
          x2="79.0508"
          y2="336.101"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#2F354E" />
          <stop offset="0.11" stopColor="#2C3149" />
          <stop offset="0.46" stopColor="#262B40" />
          <stop offset={1} stopColor="#252A3E" />
        </linearGradient>
        <linearGradient
          id="paint142_linear_2079_14077"
          x1="65.1455"
          y1="140.663"
          x2="66.2439"
          y2="357.945"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#3A435F" />
          <stop offset={1} stopColor="#2F354E" />
        </linearGradient>
        <linearGradient
          id="paint143_linear_2079_14077"
          x1="141.398"
          y1="154.786"
          x2="116.818"
          y2="336.101"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#2F354E" />
          <stop offset="0.11" stopColor="#2C3149" />
          <stop offset="0.46" stopColor="#262B40" />
          <stop offset={1} stopColor="#252A3E" />
        </linearGradient>
        <linearGradient
          id="paint144_linear_2079_14077"
          x1="130.723"
          y1="140.663"
          x2="129.625"
          y2="357.945"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#3A435F" />
          <stop offset={1} stopColor="#2F354E" />
        </linearGradient>
        <linearGradient
          id="paint145_linear_2079_14077"
          x1="55.9672"
          y1="320.766"
          x2="65.5962"
          y2="325.703"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#2F354E" />
          <stop offset="0.11" stopColor="#2C3149" />
          <stop offset="0.46" stopColor="#262B40" />
          <stop offset={1} stopColor="#252A3E" />
        </linearGradient>
        <linearGradient
          id="paint146_linear_2079_14077"
          x1="64.2809"
          y1="325.956"
          x2="52.4373"
          y2="314.85"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#2F354E" />
          <stop offset="0.11" stopColor="#2C3149" />
          <stop offset="0.46" stopColor="#262B40" />
          <stop offset={1} stopColor="#252A3E" />
        </linearGradient>
        <linearGradient
          id="paint147_linear_2079_14077"
          x1="135.878"
          y1="235.198"
          x2="119.004"
          y2="179.476"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#2F354E" />
          <stop offset="0.11" stopColor="#2C3149" />
          <stop offset="0.46" stopColor="#262B40" />
          <stop offset={1} stopColor="#252A3E" />
        </linearGradient>
        <linearGradient
          id="paint148_linear_2079_14077"
          x1="174.303"
          y1="296.763"
          x2="170.626"
          y2="237.061"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#2F354E" />
          <stop offset="0.11" stopColor="#2C3149" />
          <stop offset="0.46" stopColor="#262B40" />
          <stop offset={1} stopColor="#252A3E" />
        </linearGradient>
        <linearGradient
          id="paint149_linear_2079_14077"
          x1="159.056"
          y1="164.384"
          x2="158.257"
          y2="258.536"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#2F354E" />
          <stop offset="0.11" stopColor="#2C3149" />
          <stop offset="0.46" stopColor="#262B40" />
          <stop offset={1} stopColor="#252A3E" />
        </linearGradient>
        <linearGradient
          id="paint150_linear_2079_14077"
          x1="128.429"
          y1="273.308"
          x2="127.597"
          y2="256.507"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFD9B3" />
          <stop offset="0.68" stopColor="#FCD7B0" />
          <stop offset={1} stopColor="#F7D4AB" />
        </linearGradient>
        <linearGradient
          id="paint151_linear_2079_14077"
          x1="165.624"
          y1="260.1"
          x2="115.663"
          y2="292.434"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F0C499" />
          <stop offset="0.52" stopColor="#F1C69B" />
          <stop offset="0.85" stopColor="#F4CEA4" />
          <stop offset={1} stopColor="#F7D4AB" />
        </linearGradient>
        <linearGradient
          id="paint152_linear_2079_14077"
          x1="130.474"
          y1="293.985"
          x2="127.961"
          y2="271.112"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#DBA379" />
          <stop offset="0.49" stopColor="#E3B086" />
          <stop offset={1} stopColor="#F0C499" />
        </linearGradient>
        <linearGradient
          id="paint153_linear_2079_14077"
          x1="51.5943"
          y1="308.29"
          x2="45.3369"
          y2="205.405"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#2F354E" />
          <stop offset="0.11" stopColor="#2C3149" />
          <stop offset="0.46" stopColor="#262B40" />
          <stop offset={1} stopColor="#252A3E" />
        </linearGradient>
        <linearGradient
          id="paint154_linear_2079_14077"
          x1="161.434"
          y1="161.805"
          x2="207.457"
          y2="249.886"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#3A435F" />
          <stop offset={1} stopColor="#2F354E" />
        </linearGradient>
        <linearGradient
          id="paint155_linear_2079_14077"
          x1="22.9123"
          y1="164.018"
          x2="16.6383"
          y2="212.091"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#3A435F" />
          <stop offset={1} stopColor="#2F354E" />
        </linearGradient>
        <linearGradient
          id="paint156_linear_2079_14077"
          x1="148.465"
          y1="286.765"
          x2="144.77"
          y2="253.263"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#DBA379" />
          <stop offset="0.49" stopColor="#E3B086" />
          <stop offset={1} stopColor="#F0C499" />
        </linearGradient>
        <linearGradient
          id="paint157_linear_2079_14077"
          x1="148.847"
          y1="193.511"
          x2="140.647"
          y2="200.062"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#2F354E" />
          <stop offset="0.11" stopColor="#2C3149" />
          <stop offset="0.46" stopColor="#262B40" />
          <stop offset={1} stopColor="#252A3E" />
        </linearGradient>
        <linearGradient
          id="paint158_linear_2079_14077"
          x1="5.1544"
          y1="281.459"
          x2="56.384"
          y2="289.27"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#2F354E" />
          <stop offset="0.11" stopColor="#2C3149" />
          <stop offset="0.46" stopColor="#262B40" />
          <stop offset={1} stopColor="#252A3E" />
        </linearGradient>
        <linearGradient
          id="paint159_linear_2079_14077"
          x1="34.5847"
          y1="231.754"
          x2="57.1831"
          y2="311.263"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#2F354E" />
          <stop offset="0.11" stopColor="#2C3149" />
          <stop offset="0.46" stopColor="#262B40" />
          <stop offset={1} stopColor="#252A3E" />
        </linearGradient>
        <linearGradient
          id="paint160_linear_2079_14077"
          x1="87.4094"
          y1="254.145"
          x2="66.5454"
          y2="287.705"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#2F354E" />
          <stop offset={1} stopColor="#3A435F" />
        </linearGradient>
        <linearGradient
          id="paint161_linear_2079_14077"
          x1="188.761"
          y1="256.398"
          x2="113.425"
          y2="146.316"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F0C499" />
          <stop offset="0.52" stopColor="#F1C69B" />
          <stop offset="0.85" stopColor="#F4CEA4" />
          <stop offset={1} stopColor="#F7D4AB" />
        </linearGradient>
        <linearGradient
          id="paint162_linear_2079_14077"
          x1="145.905"
          y1="217.234"
          x2="114.551"
          y2="123.637"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F0C499" />
          <stop offset="0.52" stopColor="#F1C69B" />
          <stop offset="0.85" stopColor="#F4CEA4" />
          <stop offset={1} stopColor="#F7D4AB" />
        </linearGradient>
        <linearGradient
          id="paint163_linear_2079_14077"
          x1="114.558"
          y1="194.855"
          x2="119.525"
          y2="193.678"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFD9B3" />
          <stop offset={1} stopColor="#F7D4AB" />
        </linearGradient>
        <linearGradient
          id="paint164_linear_2079_14077"
          x1="116.524"
          y1="169.839"
          x2="106.623"
          y2="169.452"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F0C499" />
          <stop offset="0.52" stopColor="#F1C69B" />
          <stop offset="0.85" stopColor="#F4CEA4" />
          <stop offset={1} stopColor="#F7D4AB" />
        </linearGradient>
        <linearGradient
          id="paint165_linear_2079_14077"
          x1="58.0227"
          y1="189.632"
          x2="126.261"
          y2="182.375"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F0C499" />
          <stop offset="0.52" stopColor="#F1C69B" />
          <stop offset="0.85" stopColor="#F4CEA4" />
          <stop offset={1} stopColor="#F7D4AB" />
        </linearGradient>
        <linearGradient
          id="paint166_linear_2079_14077"
          x1="88.9833"
          y1="164.456"
          x2="131.297"
          y2="174.976"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F0C499" />
          <stop offset="0.52" stopColor="#F1C69B" />
          <stop offset="0.85" stopColor="#F4CEA4" />
          <stop offset={1} stopColor="#F7D4AB" />
        </linearGradient>
        <linearGradient
          id="paint167_linear_2079_14077"
          x1="72.1239"
          y1="184.167"
          x2="117.402"
          y2="186.519"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F0C499" />
          <stop offset="0.52" stopColor="#F1C69B" />
          <stop offset="0.85" stopColor="#F4CEA4" />
          <stop offset={1} stopColor="#F7D4AB" />
        </linearGradient>
        <linearGradient
          id="paint168_linear_2079_14077"
          x1="145.792"
          y1="193.685"
          x2="133.874"
          y2="166.802"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFD9B3" />
          <stop offset="0.68" stopColor="#FCD7B0" />
          <stop offset={1} stopColor="#F7D4AB" />
        </linearGradient>
        <linearGradient
          id="paint169_linear_2079_14077"
          x1="108.517"
          y1="186.885"
          x2="111.025"
          y2="186.291"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFD9B3" />
          <stop offset={1} stopColor="#F7D4AB" />
        </linearGradient>
        <linearGradient
          id="paint170_linear_2079_14077"
          x1="86.7952"
          y1="178.584"
          x2="130.852"
          y2="182.917"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F0C499" />
          <stop offset="0.52" stopColor="#F1C69B" />
          <stop offset="0.85" stopColor="#F4CEA4" />
          <stop offset={1} stopColor="#F7D4AB" />
        </linearGradient>
        <linearGradient
          id="paint171_linear_2079_14077"
          x1="148.932"
          y1="253.939"
          x2="124.279"
          y2="194.86"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFD9B3" />
          <stop offset="0.68" stopColor="#FCD7B0" />
          <stop offset={1} stopColor="#F7D4AB" />
        </linearGradient>
        <linearGradient
          id="paint172_linear_2079_14077"
          x1="125.011"
          y1="189.64"
          x2="114.885"
          y2="189.239"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F0C499" />
          <stop offset="0.52" stopColor="#F1C69B" />
          <stop offset="0.85" stopColor="#F4CEA4" />
          <stop offset={1} stopColor="#F7D4AB" />
        </linearGradient>
        <linearGradient
          id="paint173_linear_2079_14077"
          x1="130.486"
          y1="179.143"
          x2="124.375"
          y2="180.831"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFD9B3" />
          <stop offset="0.68" stopColor="#FCD7B0" />
          <stop offset={1} stopColor="#F7D4AB" />
        </linearGradient>
        <linearGradient
          id="paint174_linear_2079_14077"
          x1="135.289"
          y1="174.999"
          x2="127.076"
          y2="173.726"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFD9B3" />
          <stop offset="0.68" stopColor="#FCD7B0" />
          <stop offset={1} stopColor="#F7D4AB" />
        </linearGradient>
        <linearGradient
          id="paint175_linear_2079_14077"
          x1="136.604"
          y1="169.91"
          x2="129.24"
          y2="168.496"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFD9B3" />
          <stop offset="0.68" stopColor="#FCD7B0" />
          <stop offset={1} stopColor="#F7D4AB" />
        </linearGradient>
        <linearGradient
          id="paint176_linear_2079_14077"
          x1="136.296"
          y1="160.036"
          x2="119.331"
          y2="134.797"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFD9B3" />
          <stop offset="0.68" stopColor="#FCD7B0" />
          <stop offset={1} stopColor="#F7D4AB" />
        </linearGradient>
        <linearGradient
          id="paint177_linear_2079_14077"
          x1="133.417"
          y1="208.499"
          x2="138.608"
          y2="198.82"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#DBA379" />
          <stop offset="0.49" stopColor="#E3B086" />
          <stop offset={1} stopColor="#F0C499" />
        </linearGradient>
        <linearGradient
          id="paint178_linear_2079_14077"
          x1="144.333"
          y1="225.607"
          x2="193.887"
          y2="320.46"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#2F354E" />
          <stop offset={1} stopColor="#3A435F" />
        </linearGradient>
        <linearGradient
          id="paint179_linear_2079_14077"
          x1="164.543"
          y1="241.852"
          x2="144.874"
          y2="176.882"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#2F354E" />
          <stop offset="0.11" stopColor="#2C3149" />
          <stop offset="0.46" stopColor="#262B40" />
          <stop offset={1} stopColor="#252A3E" />
        </linearGradient>
        <linearGradient
          id="paint180_linear_2079_14077"
          x1="86.3994"
          y1="125.717"
          x2="80.2326"
          y2="121.787"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F7D4AB" />
          <stop offset="0.52" stopColor="#F5D1A8" />
          <stop offset="0.85" stopColor="#F2C99F" />
          <stop offset={1} stopColor="#F0C499" />
        </linearGradient>
        <linearGradient
          id="paint181_linear_2079_14077"
          x1="111.109"
          y1="108.618"
          x2="119.056"
          y2="102.487"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F7D4AB" />
          <stop offset="0.52" stopColor="#F5D1A8" />
          <stop offset="0.85" stopColor="#F2C99F" />
          <stop offset={1} stopColor="#F0C499" />
        </linearGradient>
        <linearGradient
          id="paint182_linear_2079_14077"
          x1="121.786"
          y1="105.725"
          x2="97.1651"
          y2="109.777"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#E8D8D7" />
          <stop offset="0.14" stopColor="#EFE4E4" />
          <stop offset="0.39" stopColor="#FAF8F7" />
          <stop offset="0.57" stopColor="white" />
          <stop offset={1} stopColor="#E8D2D1" />
        </linearGradient>
        <linearGradient
          id="paint183_linear_2079_14077"
          x1="801.203"
          y1="21.2513"
          x2="839.568"
          y2="16.4953"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#E8D8D7" />
          <stop offset="0.04" stopColor="#EBDDDC" />
          <stop offset="0.21" stopColor="#F6F0EF" />
          <stop offset="0.38" stopColor="#FCFBFB" />
          <stop offset="0.57" stopColor="white" />
          <stop offset="0.69" stopColor="#FBF7F7" />
          <stop offset="0.87" stopColor="#F1E3E3" />
          <stop offset={1} stopColor="#E8D2D1" />
        </linearGradient>
        <linearGradient
          id="paint184_linear_2079_14077"
          x1="85.3172"
          y1="126.312"
          x2="112.738"
          y2="120.065"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F96E7E" />
          <stop offset="0.03" stopColor="#F9707F" />
          <stop offset="0.25" stopColor="#FD7985" />
          <stop offset="0.53" stopColor="#FF7D88" />
          <stop offset="0.76" stopColor="#FD7985" />
          <stop offset={1} stopColor="#F86D7D" />
        </linearGradient>
        <linearGradient
          id="paint185_linear_2079_14077"
          x1="79.3176"
          y1="111.108"
          x2="109.066"
          y2="107.846"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#E8D8D7" />
          <stop offset="0.05" stopColor="#EADCDB" />
          <stop offset="0.31" stopColor="#F6EFEE" />
          <stop offset="0.48" stopColor="#FBF6F6" />
          <stop offset={1} stopColor="#E8D2D1" />
        </linearGradient>
        <linearGradient
          id="paint186_linear_2079_14077"
          x1="92.6779"
          y1="116.267"
          x2="114.267"
          y2="113.571"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F96E7E" />
          <stop offset="0.03" stopColor="#F9707F" />
          <stop offset="0.25" stopColor="#FD7985" />
          <stop offset="0.53" stopColor="#FF7D88" />
          <stop offset="0.76" stopColor="#FD7985" />
          <stop offset={1} stopColor="#F86D7D" />
        </linearGradient>
        <linearGradient
          id="paint187_linear_2079_14077"
          x1="94.0187"
          y1="125.416"
          x2="94.7556"
          y2="131.157"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFD9B3" />
          <stop offset="0.68" stopColor="#FCD7B0" />
          <stop offset={1} stopColor="#F7D4AB" />
        </linearGradient>
        <linearGradient
          id="paint188_linear_2079_14077"
          x1="100.996"
          y1="141.226"
          x2="107.525"
          y2="170.113"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#DBA379" />
          <stop offset="0.49" stopColor="#E3B086" />
          <stop offset={1} stopColor="#F0C499" />
        </linearGradient>
        <linearGradient
          id="paint189_linear_2079_14077"
          x1="107.404"
          y1="102.226"
          x2="127.395"
          y2="103.63"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFD9B3" />
          <stop offset="0.68" stopColor="#FCD7B0" />
          <stop offset={1} stopColor="#F7D4AB" />
        </linearGradient>
        <linearGradient
          id="paint190_linear_2079_14077"
          x1="73.3314"
          y1="29.939"
          x2="35.9567"
          y2="161.766"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#A3753D" />
          <stop offset={1} stopColor="#E0B163" />
        </linearGradient>
        <clipPath id="clip0_2079_14077">
          <rect width={225} height={832} fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
