const pressList = [
  {
    title: "Business Insider",
    image:
      "https://s3.amazonaws.com/coverdash/images/business-insider-logo.svg",
  },
  {
    title: "TechCrunch",
    image: "https://s3.amazonaws.com/coverdash/images/techcrunch-logo.png",
  },
  {
    title: "Axios",
    image: "https://s3.amazonaws.com/coverdash/images/axios-logo.svg",
  },
  {
    title: "Reuters",
    image: "https://s3.amazonaws.com/coverdash/images/reuters-logo.svg",
  },
  {
    title: "CNBC",
    image: "https://s3.amazonaws.com/coverdash/images/cnbc-logo.png",
  },
]
export default function PressLogos() {
  return (
    <div className="lg:mt-10 mb-4 py-10 sm:py-10">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <h2 className="text-center text-lg font-semibold leading-8 text-gray-900">
          In the Press
        </h2>
        <div className="mx-auto mt-10 grid max-w-lg grid-cols-4 items-center gap-x-8 gap-y-10 sm:max-w-xl sm:grid-cols-6 sm:gap-x-10 lg:mx-0 lg:max-w-none lg:grid-cols-5">
          {pressList.map((pressItem) => (
            <img
              key={pressItem.title}
              className="col-span-2 max-h-10 w-full object-contain lg:col-span-1"
              src={pressItem.image}
              alt={pressItem.title}
              width={158}
              height={48}
            />
          ))}
        </div>
      </div>
    </div>
  )
}
