/* eslint-disable @next/next/no-html-link-for-pages */
import React from 'react';

import { ArrowRightIcon } from '@heroicons/react/outline';

import { FadeIn } from './';

interface Props {
  title: string;
  description?: string;
  image: React.ReactNode;
}

export function PageHeader({ title, description, image }: Props) {
  return (
    <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 mt-20 md:mt-36 mb-12">
      <div className="text-center mb-8 sm:mb-16">
        <FadeIn>
          <h1 className="text-4xl tracking-tight font-extrabold text-alt sm:text-5xl md:text-6xl">
            {title}
          </h1>
        </FadeIn>
        {description && (
          <FadeIn delay={0.25}>
            <p className="mt-3 text-base text-gray-500 sm:mt-5 sm:text-lg max-w-xl mx-auto md:mt-5 md:text-xl">
              {description}
            </p>
          </FadeIn>
        )}
        <FadeIn delay={0.35}>
          <div className="mt-5 sm:mt-8 flex justify-center">
            <div className="rounded-md shadow">
              <a href="/quote" className="btn-primary">
                <span>Get a quote</span>{' '}
                <ArrowRightIcon className="h-4 w-4 inline" />
              </a>
            </div>
          </div>
        </FadeIn>
      </div>
      {image}
    </div>
  );
}
