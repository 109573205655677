import { FadeIn } from '.';

interface Item {
  title: string;
  description?: string;
  icon: (props: React.ComponentProps<'svg'>) => JSX.Element;
}

interface Props {
  title: string;
  items: Item[];
}

export function CoverageSection({ title, items }: Props) {
  return (
    <div className="bg-gray-50">
      <div className="max-w-7xl mx-auto py-8 lg:py-20 px-4 sm:px-6 lg:pg-8">
        <FadeIn>
          <h2 className="text-5xl text-alt font-extrabold my-7 text-center">
            What does <span className="text-primary">{title}</span> cover?
          </h2>
        </FadeIn>
        {items && (
          <div className="mt-12 text-center md:text-left">
            <dl className="space-y-10 md:space-y-0 md:grid md:grid-cols-3 md:gap-8">
              {items.map(item => {
                return (
                  <div key={item.title}>
                    <div>
                      <div className="flex items-center justify-center h-12 w-12 rounded-md bg-primary text-white mx-auto md:mx-0">
                        <item.icon className="h-6 w-6" aria-hidden="true" />
                      </div>
                      <p className="mt-5 text-lg leading-6 font-medium text-alt">
                        {item.title}
                      </p>
                    </div>
                    {item.description && (
                      <div
                        className="mt-2 text-base text-gray-500"
                        dangerouslySetInnerHTML={{
                          __html: item.description
                        }}
                      ></div>
                    )}
                  </div>
                );
              })}
            </dl>
          </div>
        )}
      </div>
    </div>
  );
}
